<template>

  <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">

    <ModuleHeader title="Prompt generator" :subtitle="this.$store.state.Customer.name" :showBackButton="true" />

    <div v-if="cusId">
      <Form ref="form" class="form-horizontal form-bordered">
        <BaseField v-model="state.subjectId" @change="changeSubject" name="subjectId" class="mb-2" placeholder="Select subject" as="select" addClearOption :data="state.subjects" />
        <BaseField v-model="state.topicId" @change="changeTopic" name="topicId" class="mb-2" placeholder="Topics" as="select" addClearOption :data="topics" />

        <vue-tags-input
          class="mb-4"      
          v-model="tag"
          :tags="state.tags"
          @tags-changed="handleTagsChanged"
          :disabled="!state.subjectId && !state.topicId"
          style="width:100% !important; margin-bottom:20px;" 
        />
      </Form>

      <BaseGrid v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows" @row-dblclick="onGridRowDblclick" :enableVScroll="true" :striped="false" class="table-no-more" :style="'height:'+(fullHeight-325-tagsHeight)+'px !important;'">
        <BaseGridCell @cell-click="onGridCellClick" :col="columns[0]" :row="row" style="cursor:pointer;" />
      </BaseGrid>

      <button @click="onConfirm" type="button" class="btn btn-secondary" :disabled="prompt=='' || !state.allPrompts">Confirm</button>
    </div>

    <div v-else class="text-center" style="margin-top:96px;">
      <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
      <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;">
        Select a client in order to access Prompt generator functionality.
      </p>
    </div>

  </div>

</template>

<script>
import _app from '@/App/App'
import { ref } from 'vue';
import { Form } from "vee-validate";
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseGridCell from '@/App/components/Grid/BaseGridCell'
import { VueTagsInput } from '@vojtechlanka/vue-tags-input'; //https://github.com/VojtechLanka/vue-tags-input#readme

export default {
  name: 'PromptGenerator',
  props: {
    
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    ModuleHeader,
    Form,
    BaseField,
    BaseGrid,
    BaseGridCell,
    VueTagsInput,
  }, 
  data() {
    return {
      cusId: null,
      user: this.$store.state.auth.user,

      fullWidth: null,
      fullHeight: null,
      //mainWidth: null,
      tagsHeight: 50,
      state: _app.$data.getState('AssistantTopicsFilter', { 
        starterPrompts: null,
        subjectId: null,
        topicId: null,
        tags: [],
        subjects: null,
        allTopics: null,
        allPrompts: null
      }),
      topics: [],
      prompt: "",
      tag: '',
      gridData: {
        selectedRow: null,
        columns: [
          {
            title: 'Prompt',
            dataIndex: 'prompt',
          },
        ],
        rows: [],
      },
    };
  },
  mounted() {
    //this.cusId = window.history.state.customerId;
    this.cusId = this.$store.state.Customer.id;

    if(this.cusId)
      this.getPrompts();
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.cusId = this.$store.state.Customer.id;

      if(this.cusId) {
        if(!this.state.starterPrompts)
          this.getPrompts();
        else {
          this.fullWidth = ref(window.innerWidth);
          this.fullHeight = ref(window.innerHeight);
          this.measureTags();
        }
      }
    },
  },
  methods: {
    getPrompts() {
      if(!this.state.starterPrompts) {
        let _this = this;

        const progress = _this.$progress.start();
        let loader = _this.$loading.show();

        this.$app.$api.get('Reporting/GetAiPrompts').then(
          result => {
            try {
              progress.finish();
              loader.hide();
              _this.state.starterPrompts = JSON.parse(JSON.stringify(result.data));
              _this.populatePromptsArray();
            }
            catch(err) {
              progress.finish();
              loader.hide();
              console.log(err);
            }
          },
          error => {
            progress.finish();
            loader.hide();
            _this.$app.handleError(error);          
          }
        );
      }
      else
        this.populatePromptsArray();

      this.fullWidth = ref(window.innerWidth);
      this.fullHeight = ref(window.innerHeight);
      this.measureTags();

      /*if(this.$refs.bodyEl)
        this.mainWidth = this.$refs.bodyEl.clientWidth;*/
    },
    populatePromptsArray() {
      if(!this.state.subjects) {
        this.state.subjects = Array.from(new Set(this.state.starterPrompts.map(item => item.subject_id)))
                          .map(id => {
                            const subject = this.state.starterPrompts.find(item => item.subject_id === id);
                            return { id: subject.subject_id, name: subject.subject_name };
                          });
        
        this.state.allTopics = Array.from(new Set(this.state.starterPrompts.map(item => item.topic_id)))
                          .map(id => {
                            const topic = this.state.starterPrompts.find(item => item.topic_id === id);
                            return { id: topic.topic_id, subjectId: topic.subject_id, name: topic.topic_name };
                          });

        this.state.allPrompts = Array.from(new Set(this.state.starterPrompts.map(item => item.id)))
                          .map(id => {
                            const prompt = this.state.starterPrompts.find(item => item.id === id);
                            return { id: prompt.id, topicId: prompt.topic_id, prompt: prompt.prompt };
                          });
      }

      if(this.state.subjectId) {
        const tempTopic = this.state.topicId;
        const tempTags = this.state.tags;

        //setTimeout(() => {
          this.changeSubject();
            if(tempTopic) {
              this.state.topicId = tempTopic;

              //setTimeout(() => {
                this.changeTopic();
                if(tempTags) {

                  setTimeout(() => {
                    this.handleTagsChanged(tempTags);
                  }, 100);

                }
              //}, 100);

            }
        //}, 100);
      }
    },
    changeSubject() {
      this.state.topicId = null;
      this.prompt = "";
      this.gridData.rows = [];
      this.tag = '';
      this.state.tags = [];

      if(this.state.subjectId) {
        this.topics = this.state.allTopics.filter(obj => {
                        return parseInt(obj.subjectId)==parseInt(this.state.subjectId);
                      });
        
        if(this.topics.length==1) {
          this.state.topicId = this.topics[0].id;
          this.changeTopic();
        }
      }
      else
        this.topics = [];
    },
    changeTopic() {
      this.prompt = "";
      this.tag = '';
      this.state.tags = [];

      if(this.state.topicId) {
        this.gridData.rows = this.state.allPrompts.filter(obj => {
                                return obj.topicId==this.state.topicId;
                              });
        if(this.gridData.rows.length>0)
          this.focusTags();
      }
      else {
        this.gridData.rows = [];
      }
    },
    onConfirm() {
      let newPrompt = encodeURIComponent((this.prompt || ''));
      this.$router.push({ name: 'assistant', query: { prompt: newPrompt } });
    },
    onGridCellClick(row, col, event) {
      const cell = event.target,
            selectedColor = '#A0DCF6';
      
      if (cell.tagName === 'DIV' || cell.tagName === 'TD') {
        let parentRow = cell.closest('tr');
        
        if(this.gridData.selectedRow) { //one row is already selected

          if(this.gridData.selectedRow==parentRow) { //user deselected the row
            parentRow.style.backgroundColor = '#ffffff';
            this.gridData.selectedRow = null;
            this.prompt = "";
          }
          else { //user selected new row
            this.gridData.selectedRow.style.backgroundColor = '#ffffff';
            parentRow.style.backgroundColor = selectedColor;
            this.gridData.selectedRow = parentRow;
            this.prompt = row.prompt;
          }
        }
        else {
          parentRow.style.backgroundColor = selectedColor;
          this.gridData.selectedRow = parentRow;
          this.prompt = row.prompt;
        }
      }
    },
    onGridRowDblclick(row) {
      this.prompt = row.prompt;
      setTimeout(() => {
        this.onConfirm();
      }, 50);
    },
    handleTagsChanged(newTags) {
      this.state.tags = newTags;
      this.tagsChanged(newTags);
    },
    tagsChanged (tags) {
      function filterByKeyword(prompts, keyword) {
        const regex = new RegExp(keyword, 'i');
        return prompts.filter(prompt => regex.test(prompt.prompt));
      }

      if(this.gridData.selectedRow)
        this.removeSelection();

      if(tags.length>0) {
        let searchPrompts = this.state.allPrompts.filter(obj => {
                                return obj.topicId==this.state.topicId;
                              });
        for(let i=0; i<tags.length; i++) {
          let tag = tags[i].text;
          searchPrompts = filterByKeyword(searchPrompts, tag);
        }
        this.gridData.rows = searchPrompts;
      }
      else {
        this.gridData.rows = this.state.allPrompts.filter(obj => {
                                return obj.topicId==this.state.topicId;
                              });
      }

      this.measureTags();
    },
    measureTags() {
      setTimeout(() => {
        const element = document.querySelector('.ti-input');
        if (element)
          this.tagsHeight = element.getBoundingClientRect().height;
          this.focusTags();
      }, 100);
    },
    focusTags() {
      const input = document.querySelectorAll('input')[0];
      if(input && this.gridData.rows.length>0)
        input.focus();
    },
    removeSelection() {
      this.prompt = "";
      this.gridData.selectedRow.style.backgroundColor = '#ffffff';
      this.gridData.selectedRow = null;
    }
  }
}
</script>

<style scoped>
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
</style>

<style>
/*
.vfm__content {
  position: absolute !important;
  left: 40px !important;
  top: 70px !important;
}
@media (max-width: 575px) {
	.vfm__content {
    left: 30px !important;
  }
}
.modal-content {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
*/
.ti-tag[data-v-63864685],
.ti-selected-item[data-v-63864685]{
  background-color:#A0DCF6 !important;
  color:#212529 !important;
  font-size: 1em !important;
  border-radius: 6px !important;
}
.ti-tag.ti-tag.ti-deletion-mark[data-v-63864685]{
  background-color:#e2849b !important;
}
.ti-new-tag-input-wrapper {
  font-size: 1em !important;
}
</style>