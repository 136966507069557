import { createRouter, createWebHashHistory } from 'vue-router';

import _app from '@/App/App'

import LoginPage from "../Pages/LoginPage.vue";

import DashboardView from "../Modules/Overview/DashboardView.vue";

import NotificationsView from "../Modules/Overview/NotificationsView.vue";

import AssistantView from "../Modules/Assistant/AssistantView.vue";
import PromptGenerator from "../Modules/Assistant/PromptGenerator.vue";

//import SentimentsView from "../Modules/Sentiments/SentimentsView.vue";

import SettingsView from "../Modules/Settings/SettingsView.vue";
import UsageView from "../Modules/Settings/UsageView.vue";
import GoalsView from "../Modules/Settings/GoalsView.vue";
import CompetitorsView from "../Modules/Settings/CompetitorsView.vue";
import MarketingCalendarView from "../Modules/Settings/MarketingCalendarView.vue";

//import EmailsView from "../Modules/Emails/EmailsView.vue";

import AboutView from "../Modules/Info/AboutView.vue";
import SourcesView from "../Modules/Info/SourcesView.vue";

import UserList from "../Modules/User/UserList.vue";
import UserDetails from "../Modules/User/UserDetails.vue";

import RoleList from "../Modules/Role/RoleList.vue";
//import RoleDetails from "../Modules/Role/RoleDetails.vue";

import AuditLog from "../Modules/Audit/AuditLog.vue";

import PageNotFound from '../Pages/PageNotFound.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: PageNotFound
  },
  {
    path: '/overview',
    name: 'overview',
    component: NotificationsView
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: DashboardView
  },
  {
    path: '/assistant/:threadId?',
    name: 'assistant',
    component: AssistantView,
    props: true,
  },
  {
    path: '/prompts',
    name: 'prompts',
    component: PromptGenerator
  },
  /*{
    path: "/sentiments",
    name: 'sentiments',
    //meta: { cssClass: 'bg-white'},
    component: SentimentsView
  },*/
  /*{
    path: "/emails",
    name: 'emails',
    component: EmailsView
  },*/
  {
    path: "/settings",
    name: 'settings',
    component: SettingsView
  },
  {
    path: "/about",
    name: 'about',
    component: AboutView
  },
  {
    path: "/sources",
    name: 'sources',
    component: SourcesView
  },
  {
    path: "/usage",
    //path: '/usage/:customerId',
    name: 'usage',
    component: UsageView,
    props: true
  },
  {
    path: "/goals",
    name: 'goals',
    component: GoalsView,
    props: true
  },
  {
    path: "/competitors",
    name: 'competitors',
    component: CompetitorsView,
    props: true
  },
  {
    path: "/calendar",
    name: 'calendar',
    component: MarketingCalendarView,
    props: true
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import('../Modules/UserMgmt/ProfileView.vue')
  },
  {
    path: "/users",
    name: 'users',
    component: UserList
  },
  {
    path: "/users/add-user",
    name: "add-user",
    component: UserDetails
  },
  {
    path: "/users/user-details",
    name: "user-details",
    component: UserDetails
  },  
  {
    path: "/roles",
    name: 'roles',
    component: RoleList
  },
  {
    path: "/audit-log",
    name: 'audit-log',
    component: AuditLog
  },  
  {
    path: "/login",
    name: 'login',
    component: LoginPage
  },
  {
    path: "/la-dev",
    name: "la-dev",
    component: () => import('../Modules/Playground/la-dev.vue')
  },
  {
    path: "/la-assistant",
    name: "la-assistant",
    component: () => import('../Modules/Playground/la-assistant.vue')
  },
  /*{
    path: "/registration",
    name: 'registration',
    component: () => import('../Pages/RegistrationPage.vue')
  },*/
  {
    path: "/reset-password",
    name: 'reset-password',
    component: () => import('../Pages/ResetPasswordPage.vue')
  },  
  {
    path: '/:pathMatch(.*)',
    component: PageNotFound
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/registration','/reset-password'];
  const authRequired = !publicPages.includes(to.path);

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !_app.user) {
    next('/login');
  // } else if(!_app.user?.isAdmin() && to.path == '/la-test') {
  //   next('/');
  } else if (to.path == '/') {
    next('/overview');
  } else {
    next();
  }    
});

export default router;
