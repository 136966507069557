<template>

  <div ref="bodyEl" class="lab22-mv-padding">
    
    <div class="not-found-content" style="margin: 40px 0 0 40px;">
      <!-- <div class="illustration-wrapper">
        <app-aspect-ratio [ratio]="{w: 1, h: 1}">
          <app-image-shell [src]="'./assets/sample-images/page-not-found.svg'" [alt]="'not found page'"></app-image-shell>
        </app-aspect-ratio>
      </div> -->
      <!--<div class="not-found-title" style="padding-bottom:20px;">404</div>-->
      <div class="not-found-title" style="padding-bottom:40px;">Ups, page not found</div>
      <button class="btn btn-primary btn-block" @click="this.$router.back()">Go Back</button>
    </div>

  </div>

</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>

<style scoped>
.not-found-title, not-found-text {
  font-size: 32px !important;
  color: #2e3d53 !important;
  font-weight: 500 !important;
}
</style>