import axios from 'axios';

export const AUTH_STORE_KEY = 'user';

const API_URL = process.env.VUE_APP_API_BASE_URL + 'App/';

class AuthService {
  
  UserRoleFn = {
    AdminLab22: 1,
    AdminTsf: 2,
    AdminBrandfarm: 3,
  }

  login(user) {
    return axios.post(API_URL + 'Login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        this.updateAuthData(response.data);
        return response.data;
      });
  }

  logout() {    
    //axios.get(API_URL + 'Logout');
    localStorage.removeItem(AUTH_STORE_KEY);
  }

  updateAuthData(data) {

    if (!data.accessToken) {
      const authData = JSON.parse(localStorage.getItem(AUTH_STORE_KEY));
      if (authData?.accessToken)
        data.accessToken = authData?.accessToken;
    }

    if (data.accessToken) {
      localStorage.setItem(AUTH_STORE_KEY, JSON.stringify(data));
    }
  }

  getUser() {

    const user = JSON.parse(localStorage.getItem(AUTH_STORE_KEY));

    if (user) {
      user.isAdmin = () => {  return user.roleFunctions.indexOf(this.UserRoleFn.AdminLab22) !== -1 || user.roleFunctions.indexOf(this.UserRoleFn.AdminTsf) !== -1 || user.roleFunctions.indexOf(this.UserRoleFn.AdminBrandfarm) !== -1 ; };
      user.hasReceiveEmailNotificationsFunction = () => {  return user.roleFunctions.indexOf(this.UserRoleFn.ReceiveEmailNotifications) !== -1; };
      user.isCustomerUser = () => { return !!user.customerId;};
    }

    return user;
  }

  getToken() {
    return JSON.parse(localStorage.getItem(AUTH_STORE_KEY))?.accessToken;
  }
}

export default new AuthService();