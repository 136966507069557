<template>

  <base-modal :name="modalName" title="Assistant topics" v-bind="$attrs" :width="width" @close="close" style="min-height:500px !important;">
    <template #body>
      <BaseField v-model="state.subjectId" @change="changeSubject" name="subjectId" placeholder="Subject" as="select" addClearOption :data="state.subjects" style="width:100%; margin-bottom:10px;" />
      <BaseField v-model="state.topicId" @change="changeTopic" name="topicId" placeholder="Topic" as="select" addClearOption :data="topics" style="width:100%; margin-bottom:10px;" />

      <vue-tags-input
        v-model="tag"
        :tags="state.tags"
        @tags-changed="handleTagsChanged"
        :disabled="!state.subjectId && !state.topicId"
        style="width:100% !important; margin-bottom:20px;" 
      />

      <BaseGrid v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows" @row-dblclick="onGridRowDblclick" :enableVScroll="true" :striped="false" class="table-no-more" :style="'height:'+(height-210-tagsHeight)+'px !important;'">
        <BaseGridCell @cell-click="onGridCellClick" :col="columns[0]" :row="row" style="cursor:pointer;" />
      </BaseGrid>
    </template>
    <template #footer>
      <button @click="onConfirm" type="button" class="btn btn-secondary" :disabled="prompt=='' || !state.allPrompts">Confirm</button>
    </template>
  </base-modal>

</template>

<script>
import _app from '@/App/App'
import BaseModal from '@/App/components/Modal/BaseModal'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseGridCell from '@/App/components/Grid/BaseGridCell'
import { VueTagsInput } from '@vojtechlanka/vue-tags-input'; //https://github.com/VojtechLanka/vue-tags-input#readme

export default {
  name: 'AssistantTopicsModal',
  props: {
    starterPrompts: Object,
    popWidth: Number,
    popHeight: Number,
    progress: Object
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    BaseField,
    BaseGrid,
    BaseGridCell,
    VueTagsInput,
  }, 
  data() {
    return {
      modalName: 'AssistantTopicsModal',
      width: this.popWidth,
      height: this.popHeight,
      tagsHeight: 50,
      state: _app.$data.getState('AssistantTopicsModalFilter', { 
        subjectId: null,
        topicId: null,
        tags: [],
        subjects: null,
        allTopics: null,
        allPrompts: null
      }),
      topics: [],
      prompt: "",
      tag: '',
      gridData: {
        selectedRow: null,
        columns: [
          {
            title: 'Prompt',
            dataIndex: 'prompt',
          },
        ],
        rows: [],
      },
    };
  },
  mounted() {
    if(!this.state.subjects) {
      this.state.subjects = Array.from(new Set(this.starterPrompts.map(item => item.subject_id)))
                        .map(id => {
                          const subject = this.starterPrompts.find(item => item.subject_id === id);
                          return { id: subject.subject_id, name: subject.subject_name };
                        });
      
      this.state.allTopics = Array.from(new Set(this.starterPrompts.map(item => item.topic_id)))
                        .map(id => {
                          const topic = this.starterPrompts.find(item => item.topic_id === id);
                          return { id: topic.topic_id, subjectId: topic.subject_id, name: topic.topic_name };
                        });

      this.state.allPrompts = Array.from(new Set(this.starterPrompts.map(item => item.id)))
                        .map(id => {
                          const prompt = this.starterPrompts.find(item => item.id === id);
                          return { id: prompt.id, topicId: prompt.topic_id, prompt: prompt.prompt };
                        });
    }

    if(this.progress)
      this.progress.finish();

    if(this.state.subjectId) {
      const tempTopic = this.state.topicId;
      const tempTags = this.state.tags;

      setTimeout(() => {
        this.changeSubject();
          if(tempTopic) {
            this.state.topicId = tempTopic;

            setTimeout(() => {
              this.changeTopic();
              if(tempTags) {

                setTimeout(() => {
                  this.handleTagsChanged(tempTags);
                }, 100);

              }
            }, 100);

          }
      }, 100);

    }
  },
  methods: {
    changeSubject() {
      this.state.topicId = null;
      this.prompt = "";
      this.gridData.rows = [];
      this.tag = '';
      this.state.tags = [];

      if(this.state.subjectId) {
        this.topics = this.state.allTopics.filter(obj => {
                        return parseInt(obj.subjectId)==parseInt(this.state.subjectId);
                      });
        
        if(this.topics.length==1) {
          this.state.topicId = this.topics[0].id;
          this.changeTopic();
        }
      }
      else
        this.topics = [];
    },
    changeTopic() {
      this.prompt = "";
      this.tag = '';
      this.state.tags = [];

      if(this.state.topicId) {
        this.gridData.rows = this.state.allPrompts.filter(obj => {
                                return obj.topicId==this.state.topicId;
                              });
        if(this.gridData.rows.length>0)
          this.focusTags();
      }
      else {
        this.gridData.rows = [];
      }
    },
    close() {
      this.$vfm.hide(this.modalName);
    },
    onConfirm() {
      this.$emit('ok', this.prompt);
      this.close();
    },
    onGridCellClick(row, col, event) {
      const cell = event.target,
            selectedColor = '#A0DCF6';

      if (cell.tagName === 'TD') {
        let parentRow = cell.closest('tr');
        
        if(this.gridData.selectedRow) { //one row is already selected

          if(this.gridData.selectedRow==parentRow) { //user deselected the row
            parentRow.style.backgroundColor = '#ffffff';
            this.gridData.selectedRow = null;
            this.prompt = "";
          }
          else { //user selected new row
            this.gridData.selectedRow.style.backgroundColor = '#ffffff';
            parentRow.style.backgroundColor = selectedColor;
            this.gridData.selectedRow = parentRow;
            this.prompt = row.prompt;
          }
        }
        else {
          parentRow.style.backgroundColor = selectedColor;
          this.gridData.selectedRow = parentRow;
          this.prompt = row.prompt;
        }
      }
    },
    onGridRowDblclick(row) {
      this.prompt = row.prompt;
      this.onConfirm();
    },
    handleTagsChanged(newTags) {
      this.state.tags = newTags;
      this.tagsChanged(newTags);
    },
    tagsChanged (tags) {
      function filterByKeyword(prompts, keyword) {
        const regex = new RegExp(keyword, 'i');
        return prompts.filter(prompt => regex.test(prompt.prompt));
      }

      if(this.gridData.selectedRow)
        this.removeSelection();

      if(tags.length>0) {
        let searchPrompts = this.state.allPrompts.filter(obj => {
                                return obj.topicId==this.state.topicId;
                              });
        for(let i=0; i<tags.length; i++) {
          let tag = tags[i].text;
          searchPrompts = filterByKeyword(searchPrompts, tag);
        }
        this.gridData.rows = searchPrompts;
      }
      else {
        this.gridData.rows = this.state.allPrompts.filter(obj => {
                                return obj.topicId==this.state.topicId;
                              });
      }

      setTimeout(() => {
        const element = document.querySelector('.ti-input');
        if (element)
          this.tagsHeight = element.getBoundingClientRect().height;
          this.focusTags();
      }, 100);
    },
    focusTags() {
      const input = document.querySelectorAll('input')[0];
      if(input && this.gridData.rows.length>0)
        input.focus();
    },
    removeSelection() {
      this.prompt = "";
      this.gridData.selectedRow.style.backgroundColor = '#ffffff';
      this.gridData.selectedRow = null;
    }
  }
}
</script>

<style scoped>

</style>

<style>
/*
.vfm__content {
  position: absolute !important;
  left: 40px !important;
  top: 70px !important;
}
@media (max-width: 575px) {
	.vfm__content {
    left: 30px !important;
  }
}
.modal-content {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
*/
.ti-tag[data-v-63864685],
.ti-selected-item[data-v-63864685]{
  background-color:#A0DCF6 !important;
  color:#212529 !important;
  font-size: 1em !important;
  border-radius: 6px !important;
}
.ti-tag.ti-tag.ti-deletion-mark[data-v-63864685]{
  background-color:#e2849b !important;
}
.ti-new-tag-input-wrapper {
  font-size: 1em !important;
}
</style>