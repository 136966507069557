<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">
        
      <ModuleHeader title="Goals & KPIs" :subtitle="this.$store.state.Customer.name" :showBackButton="true" />

      <div class="row ps-4">
        <div class="col-12">
          
          <div v-if="cusId">
            <div v-if="isLabUser">
              You can setup goals only for special reports available in this Google sheet document: 
              <a :href="( (googleSheet) ? 'https://docs.google.com/spreadsheets/d/'+googleSheet : 'javascript:void(0);')" :target="( (googleSheet) ? '_blank' : '')"><strong>{{ googleSheet || '/' }}</strong></a>
              <br />
              The reports are named with a KPI keyword and have a date column and a trackable category/measurement column.
              <br /><br />
            </div>

            <p v-if="googleSheet" :class="( (isLabUser) ? 'mt-2' : '')">
              KPIs with a checked first column have been activated for tracking.
            </p>

            <BaseGrid v-if="googleSheet" v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" :hideHeaderCheckbox="true" class="table-no-more">
              <td>
                <input type="checkbox" :disabled="true" :checked="isKpiActivated(row)" class="form-check-input big-checkbox" />
              </td>
              <BaseGridCell :col="columns[1]" :row="row" :class="(isKpiActivated(row) ? '' : 'text-muted')" />
              <BaseGridCell :col="columns[2]" :row="row" :class="(isKpiActivated(row) ? '' : 'text-muted')" />
              <BaseGridCell :col="columns[3]" :row="row" :class="(isKpiActivated(row) ? '' : 'text-muted')" :cellAddDescr="(isKpiActivated(row) ? getKpiDescr(row.id) : '')" />
              <BaseGridCell :col="columns[4]" :row="row" />
            </BaseGrid>

            <h4 v-else class="text-primary">
              Google sheet is not available for setting up KPIs & Goals.<br />
              Please, contact system administrator or TSF staff to help you out.
            </h4>

          </div>

          <div v-else class="text-center" style="margin-top:96px;">
            <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
            <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;">
              Select a client in order to access Goals & KPIs functionality.
            </p>
          </div>

        </div>
      </div>

    </div>
  
</template>
  
<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseGridCell from '@/App/components/Grid/BaseGridCell'
import EditKpiModal from './EditKpiModal'

export default {
  name: 'GoalsView',
  /*props: {
    customerId: {
      type: String,
      required: true
    }
  },*/
  components: {
    ModuleHeader,
    BaseGrid,
    BaseGridCell,
  },
  data: function() {
    return {
      customerConfig: null,
      customerKpis: [],
      //cusId: this.customerId,
      cusId: null,
      googleSheet: null,
      isLabUser: false,
      user: this.$store.state.auth.user,

      gridData: {
        columns: [
          {
            title: '',
            type: 'checkbox',
            /*changeHandler: (e) => {              
              return false;
            },*/
          },          
          {
            title: 'Platform',
            dataIndex: 'platform_name',
          },
          {
            title: 'KPI name',
            dataIndex: 'kpi_name'
          },
          {
            title: 'KPI description',
            dataIndex: 'kpi_descr'
          },
          // actions
          {
            width:90,
            align:'right',
            actions: [
              {
                text: 'Setup',
                handler: this.editKpi
              },
              {
                text: 'Untrack',
                handler: this.removeKpi
              }
            ]
          }
        ],
        rows: []
      },
    }
  },
  mounted() {
    //this.cusId = window.history.state.customerId;
    this.cusId = this.$store.state.Customer.id;

    if(this.cusId)
      this.initData(this.cusId);

    if (this.user)
      this.isLabUser = ( this.$app.$data.Lab22Users.indexOf( this.getEmailPrefixIfDomainMatches(this.user.email) ) == -1 ) ? false : true;

    let _this = this,
          progress = _this.$progress.start();
        
      _this.$app.$api.get('Customer/GetKpiList').then(
        result => {
          progress.finish();
          let res = result.data;
          if(res) {
            _this.gridData.rows = res;
          }
          else
            _app.$helper.notifyInfo('Ups, list of available KPIs not available. Please, try again.');
        },
        error => {
          progress.finish();
          _this.$app.handleError(error);          
        }
      );
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.cusId = this.$store.state.Customer.id;
      this.initData(this.cusId);
    },
  },
  methods: {
    getEmailPrefixIfDomainMatches(email) {
      const [prefix, domain] = email.split('@');
      
      if (domain === 'lab22.com')
        return prefix;
      else
        return email;
    },
    initData(id) {
      this.customerConfig = null;
      this.googleSheet = null;
      this.customerKpis = [];

      if(id) {
        this.customerConfig = JSON.parse(
                                this.$app.$data.customers.filter(obj => {
                                  return obj.id==id;
                                })[0].config
                              ) || null;

        if (this.customerConfig.pmakpis !== undefined) { //this should not happen!
          
          if(this.customerConfig.pmakpis.sheetId !== undefined)
            this.googleSheet = this.customerConfig.pmakpis.sheetId;
          
          if(this.customerConfig.pmakpis.kpis !== undefined) {
            if(this.customerConfig.pmakpis.kpis.length>0)
              this.customerKpis = this.customerConfig.pmakpis.kpis;
            else
              this.customerKpis = [];
          }
          else
            this.customerKpis = [];
        }
      }
    },
    isKpiActivated(row) { //if kpi is activated in config
      let list = this.customerKpis,
          _return = false;

      for(let i=0; i<list.length; i++) {
        if(parseInt(row.id)==parseInt(list[i].kpiId)) {
          _return = true;
          break;
        }
      }

      return _return;
    },
    getCustomerConfig(id) {
      return JSON.parse(
                this.$app.$data.customers.filter(obj => {
                  return obj.id==id;
                })[0].config
              ) || null;
    },
    editKpi(row) {
      this.$vfm.show({
        component: EditKpiModal,
        bind: {
          'editRec': this.customerKpis.filter(obj => {
                        return obj.kpiId==row.id;
                      }),
          'platformName': row.platform_name,
          'kpiName': row.kpi_name,
          'isMetric': row.is_metric,
        },        
        on: {
          ok: (data) => {
            data.kpiId = row.id;
            this.setKpi(data);

            //setTimeout(() => {
            //  console.log(this.customerConfig.pmakpis.kpis);
              this.saveKpis();
            //}, 100);
          }
        }
      });
    },
    setKpi(data) {
      const kpiIndex = this.customerConfig.pmakpis.kpis.findIndex(kpi => kpi.kpiId === data.kpiId);
      if (kpiIndex !== -1)
        this.customerConfig.pmakpis.kpis[kpiIndex] = data;
      else
        this.customerConfig.pmakpis.kpis.push(data);
    },
    removeKpi(row) {
      const kpiIndex = this.customerConfig.pmakpis.kpis.findIndex(kpi => kpi.kpiId === row.id);
      if (kpiIndex !== -1) {
        _app.$modal.confirm('Untrack KPI', `Are you sure you want to untrack "${row.kpi_name}"?`, (confirmed) => {
        
          if (!confirmed) 
            return;
        
          this.customerConfig.pmakpis.kpis.splice(kpiIndex, 1);
          
          if(_app.$helper.isEmpty(this.customerConfig.pmakpis.kpis))
            this.customerConfig.pmakpis.kpis = [];

          this.saveKpis();
        });
      }
      else {
        _app.$helper.notifyInfo("This KPI is not yet activated for tracking");
        return false;
      }
    },
    saveKpis() {
      let _this = this;

      //if (this.customerConfig && this.customerConfig.pmakpis.kpis) {
        this.customerConfig.pmakpis.kpis = this.customerKpis;
        pushToDb(this.cusId, this.customerConfig, _this);
      //}
      //else
      //  _app.$helper.notifyError("'pmakpis.kpis' property is missing in customerConfig");

      function pushToDb(customerId, json, scope) {
        _app.$api.post('Customer/SetCustomerConfig?id='+customerId, json).then(result => {
          if( result.data && parseInt(result.data)>0 ) {
            _app.$helper.notifyInfo('KPIs & Goals successfully updated.');

            _app.$data.getCustomers(true).then(data => {
              scope.customers = data;
              scope.customerConfig = scope.getCustomerConfig(customerId);
              
              let cusName = scope.$app.$helper.getCustomerName(customerId,data);
              scope.$store.dispatch('setAppCustomer', {id:customerId, name:cusName, config:JSON.stringify(json) });

              scope.initData(customerId);
            });
          }
        }, (error) => {
          _app.handleError(error);
        });
      }
    },
    getKpiDescr(id) {
      let kpi = this.customerKpis.filter(obj => {
                    return parseInt(obj.kpiId)==parseInt(id);
                  });

      let kpiInstance = kpi[0];

      let descr = (kpiInstance) ? ('<br /><span class="text-success">User description: '+kpiInstance.kpiUserDescr)+'</span>' : '',
          periodTo = (kpiInstance) ? kpiInstance.periodTo : '';

      let dtPeriodTo = new Date(periodTo);
          dtPeriodTo.setHours(0, 0, 0, 0);

      const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

      if(dtPeriodTo < currentDate)
        descr += '<br /><span class="text-danger">Period expired on '+periodTo+'! Reset your goal because it is no longer valid.</span>';
      
      return descr;
    }
  },
};
</script>

<style scoped>
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
</style>