<template>
  <nav id="main-header-nav" class="navbar navbar-header navbar-expand-sm navbar-dark bg-dark fixed-top ps-3 pe-2">   

    <!--<img @click.shift.ctrl.prevent="onTitleClick" title="Logo" src="@/assets/img/Logo-big.png" class="hd-logo" style="width:148px !important; height:65px !important;" />-->
    <img src="@/assets/img/Logo-big.png" class="hd-logo" style="width:133px; height:58px;" />
    
    <!--<div v-show="this.$store.state.Customer.exists() && !isCustomerUser" @click="selectCustomer" class="py-1 px-2" :style="'left:'+leftOffsetClientName.toString()+'px; cursor:pointer; position:absolute; top:15px; color:#ffffff; background-color:#fea3b4; font-weight:500; border-radius:5px 5px 5px 5px;'">-->
    <div v-show="!isCustomerUser" @click="selectCustomer" class="py-1 px-2" :style="'left:'+leftOffsetClientName.toString()+'px; cursor:pointer; position:absolute; top:15px; color:#ffffff; background-color:#fea3b4; font-weight:500; border-radius:5px 5px 5px 5px;'">
      Client: {{ this.$store.state.Customer.name || 'Click to select...' }}
    </div>

    <!-- ACTIONS -->
    <!--
    <ul v-if="isLogedInUserView" class="notifications" style="margin-right:30px;">
      <li>
        <a href="#" class="dropdown-toggle notification-icon" data-bs-toggle="dropdown" aria-expanded="false" style="width:25px; height:25px;">
          <i class="fas fa-bell"></i>
          <span class="badge" :style="(notificationActions.length>0) ? 'background:#FF19FF;' : 'background:#00ccac;'">{{ (notificationActions.length>0) ? notificationActions.length.toString() : '0'}}</span>
        </a>

        <div class="dropdown-menu notification-menu" data-popper-placement="bottom-start" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(0px, 32px);">
          <div class="notification-title">
            <span class="float-end badge badge-default">{{ (notificationActions.length>0) ? notificationActions.length.toString() : '0'}}</span>
            Actions
          </div>

          <div class="content">
            <ul>
              <li>
                <a href="#" class="clearfix">
                  <div class="image">
                    <i class="fas fa-thumbs-down bg-danger text-light"></i>
                  </div>
                  <span class="title">Agent 1</span>
                  <span class="message">Just now</span>
                </a>
              </li>
              <li>
                <a href="#" class="clearfix">
                  <div class="image">
                    <i class="fas fa-lock bg-warning text-light"></i>
                  </div>
                  <span class="title">User Locked</span>
                  <span class="message">15 minutes ago</span>
                </a>
              </li>
              <li>
                <a href="#" class="clearfix">
                  <div class="image">
                    <i class="fas fa-signal bg-success text-light"></i>
                  </div>
                  <span class="title">Connection Restored</span>
                  <span class="message">10/10/2021</span>
                </a>
              </li>
            </ul>

            <hr>

            <div class="text-end">
              <a href="#" class="view-more">View All</a>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <span v-if="isLogedInUserView" class="separator"></span>
    -->

    <button id="headerMobileHamburger" @click="$emit('offcanvas')" class="btn btn-lg btn-default" style="background:none !important; border:none !important;">
      <i class="fa-solid fa-bars" style="font-size:20px;"></i>
    </button>

    <div v-if="isLogedInUserView" id="userbox" class="userbox ml-auto" style="position:absolute; right:0;">
      <a ref="dropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
        <!--
        <figure class="profile-picture">
          <img :src="this.userImg" class="rounded-circle" :data-lock-picture="this.userImg" :style="styleUserImage">
        </figure>
        -->
        <div class="profile-info" data-lock-name="{{ this.headName }}" data-lock-email="{{ this.headEmail }}">
          <span class="name" :style="styleUserName">{{ this.headName }}</span>
          <span class="role" :style="styleUserName">{{ this.headEmail }}</span>
        </div>

        <i class="fa custom-caret" :style="styleUserName"></i>
      </a>

      <div class="dropdown-menu" style="min-width:140px;">
        <ul class="list-unstyled mb-2">
          <li class="divider"></li>
          <li>
            <a role="menuitem" tabindex="-1" @click.prevent="changePassword" style="cursor:pointer !important;"><i class="fa-solid fa-hashtag"></i> Change password</a>
          </li>
          <li>
            <a role="menuitem" tabindex="-1" @click.prevent="logOut" style="cursor:pointer !important;"><i class="fa-solid fa-power-off"></i> Logout</a>
          </li>
          <li v-show="!isCustomerUser" class="divider"></li>
          <li v-show="!isCustomerUser">
            <a role="menuitem" tabindex="-1" @click.prevent="selectCustomer" style="cursor:pointer !important;"><i class="fa-solid fa-building"></i> Select client</a>
          </li>
          <!--
          <li class="divider"></li>
          <li>
            <a @click="$emit('offcanvas')" role="menuitem" tabindex="-1" style="cursor:pointer !important;"><i class="fa-solid fa-question"></i> Help</a>
          </li>
          -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ChangePasswordModal from '@/Modules/User/ChangePasswordModal'
import ChangeCustomerModal from '@/Modules/Settings/ChangeCustomerModal'
import { ref } from 'vue';

export default {
  name: 'AppHeader',
  components: {
    //
  },
  emits: ['offcanvas'],
  props: {
    isLogedInUserView: Boolean
  },
  data() {
    return {
      progressQueue: [],
      show: false,
      headName: null,
      headEmail: null,
      authUser: this.$app.user,
      imgUserWhiteSrc: require('../../assets/img/logged-user.jpg'),
      imgUserDarkSrc: require('../../assets/img/user-acc.svg'),
      isCustomerUser: null,
      leftOffsetClientName: 0,
      userImg: null,
      //isLabUser: false,
      userBoxOpen: null,
      styleUserImage: null,
      styleUserName: null,
      notificationActions: [],
    }
  },
  mounted () {
    window.addEventListener('resize', this.doLayout);

    this.isCustomerUser = this.checkUser();

    let _user = this.$store.state.auth.user; 
    if (_user) {
      this.headName = _user.username;
      if(this.$app.user.isCustomerUser())
        this.headEmail = this.$app.user.customerName;
      else
        this.headEmail = (_user.username!==_user.email) ? _user.email : '';
    
      //this.isLabUser = ( this.$app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;

      this.userBoxOpen = false;
      const dropdownElement = this.$refs.dropdown;
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.attributeName === "aria-expanded")
            this.userBoxOpen = !this.userBoxOpen; //this.changeFontClass(mutation.target.getAttribute("aria-expanded"));
        }
      });
      observer.observe(dropdownElement, { attributes: true });
    }

    setTimeout(() => {
      this.doLayout();
    }, 200);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.doLayout);
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user; 
    },
	},
  watch: {
    userBoxOpen(){ //when this.userBoxOpen changes
        this.changeFontClass(this.userBoxOpen);
    },
  },
  methods: {
    changeFontClass(openState) { 
      if(!openState) {
        if(this.$app.darkTheme) {
          this.userImg = this.imgUserDarkSrc;
          this.styleUserName = 'color:#fff !important;';
          this.styleUserImage = 'width:120% !important; height:120% !important;';
        } else {
          this.userImg = this.imgUserDarkSrc;
          this.styleUserName = 'color:#fff !important;';
          this.styleUserImage = 'width:120% !important; height:120% !important;';
        }
      }
      else {
        if(this.$app.darkTheme) {
          this.userImg = this.imgUserDarkSrc;
          this.styleUserName = 'color:#7d7d7d !important;';
          this.styleUserImage = 'filter:invert(70%) !important; opacity:0.8 !important; width:120% !important; height:120% !important;';
        } else {
          this.userImg = this.imgUserDarkSrc;
          this.styleUserName = 'color:#7d7d7d !important;';
          this.styleUserImage = 'filter:invert(70%) !important; width:120% !important; height:120% !important';
        }
      }
    },
    changePassword() {
      this.$vfm.show({
        component: ChangePasswordModal,
        bind: {
          'editRec': this.$store.state.auth.user
        },
        on: {
          ok: (data) => {
            this.$app.$helper.notify('Password was successfully changed.', 'info', 3000);
          }
        }
      });
    },
    checkUser() {
      if(this.$app.user)
        return this.$app.user.isCustomerUser();
      else
        return false;
    },
    doLayout() {
      const width = ref(window.innerWidth).value;
      this.leftOffsetClientName = ( width<=576 ) ? 150 : 250;
    },
    logOut() {
      this.$app.logOut();
    },
    /*onTitleClick() {
      
    },*/
    selectCustomer() {
      this.$vfm.show({
        component: ChangeCustomerModal,
        bind: {
          'editRec': this.$store.state.Customer
        },
        on: {
          ok: (data) => {
            if(data.id) {
              let cusConfig = this.$app.$helper.getCustomerConfig(data.id,this.$app.$data.customers);
              this.$store.dispatch('setAppCustomer', {id:data.id, name:data.name, config:cusConfig });
            }
            else
              this.$store.dispatch('setAppCustomer', {id:null, name:null, config:[] });
          }
        }
      });
    },
    startProgress() {
      this.progressQueue.push(this.$progress.start());
    },
    stopProgress() {
      const progress = this.progressQueue.pop();
      if (progress) 
        progress.finish();
    },
    /*userDetails() {
      this.$router.push({
        name: 'user-details',
        params: { 
          data: JSON.stringify(this.$store.state.auth.user)
        }
      });
    },*/
  }
}
</script>

<style scoped>
.navbar {
  height: 60px;
}

.navbar-brand {
  line-height:6px;
}

.brand-name {
  font-weight: bold;
  font-size: 15px;
  padding-left:3px;
}

/* makes sub-menu S open on hover */
.submenu-item:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu .form-check {
  padding-left: 32px;
  margin-top: 15px;
}

.dropdown-menu .form-check input {
  margin-top: 2px;
  margin-right:0px
}

.dropdown-menu .form-check .form-check-label {
  padding-left: 0px;
  font-size: 12px;
}

.dropdown-menu .form-check .form-check-label {
  color: #475467;
}

html.dark .dropdown-menu .form-check .form-check-label {
  color: #475467;
}
</style>