<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="350" @close="close">
    <template #body>  
      <FormField name="competitor" label="Competitor name" v-model="competitor" :topLabel="true" />
      <FormField name="url" label="Link" v-model="url" :topLabel="true" />
      <div class="row mt-3">
        <div class="col-12">
          <BaseCheckbox name="advancedAnalysis" :checked="advancedAnalysis" @checked="changeChecked" label="advanced analysis?" />
        </div>
      </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-secondary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import BaseCheckbox from '@/App/components/Form/BaseCheckbox'
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'AddScrapingModal',
  props: {
      editRec: Object,
    },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    BaseCheckbox,
    Form
  }, 
  data() {
    return {
      modalName: 'AddScrapingModal',
      schema: {
        competitor: 'required',
        url: 'required',
      },
      //
      competitor: null,
      url: null,
      advancedAnalysis: false,
    };
  },
  mounted() {
    if (this.editRec) {
      let rec = this.editRec;

      this.competitor = rec.competitor;
      this.url = rec.url;
      this.advancedAnalysis = rec.advancedAnalysis;
    }
  },
  methods: {
    getTitle() {
      return this.editRec ? 'Edit scraping link' : 'Add scraping link';
    },
    changeChecked(checked) {
      this.advancedAnalysis = checked;
    },
    onSubmit(values) {
      let //loader = this.$loading.show({ container: this.$refs.form.$el }),
          _data = {
            competitor: this.competitor,
            url: this.url,
            advancedAnalysis: this.advancedAnalysis
          };

      //loader.hide();

      this.$emit('ok', _data);
      this.$vfm.hide(this.modalName);
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>