<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding">
        
      <ModuleHeader title="40+ Data Sources available" style="font-weight:700 !important;" />

      <p class="p-text mt-4">
        Isn't it great when you can analyze all your data in one place? We certainly think so. 
      </p>

      <p class="p-text mt-4">
        We are proud to enable our clients to connect to most social and e-commerce platforms out there. Check out the most used data sources below.
      </p>

      <div class="row" style="max-width:1048px">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources1_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources1_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources1_3.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources2_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources2_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources2_3.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources3_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources3_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources3_3.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources4_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources4_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources4_3.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources5_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources5_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources5_3.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources6_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources6_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources6_3.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources7_1.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
          <img :src="require('../../assets/img/sources7_2.png')" class="img-fluid" style="width: 357px;" alt="Data sources">
        </div>
      </div>

      <!--
      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources1.png')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources2.png')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources3.png')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources4.png')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources5.png')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources6.png')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <img :src="require('../../assets/img/sources7.png')" />
        </div>
      </div>
      -->

    </div>
</template>
  
<script>
import ModuleHeader from '@/App/components/Common/ModuleHeader'

export default {
  name: 'SourcesView',
  components: {
    ModuleHeader,
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.p-text {
  color: #2e3d53;
  font-size: 16px;
}
</style>