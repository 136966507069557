<template>
  <div class="main-template-div lab22-mv-padding">

    <!--<ModuleHeader title="Users" iconClass="fa-solid fa-user-group">-->
    <ModuleHeader title="Users" iconClass="inf-module-header-users inf-module-header-img">
      <BaseButton @click="showAddUserModal()" text="Add user" />
    </ModuleHeader>

    <FilterBar v-slot="{state}" :filterState="getFilterState()" @reload="load(true, true)" showReload showReset :loading="loading">
      <!--<BaseField v-model="state.username" name="username" placeholder="Username" style="width:200px" />-->
      <BaseField v-model="state.username" name="username" placeholder="Email" style="width:200px" />
    </FilterBar>

    <BaseGrid :columns="gridData.columns" :rows="gridData.rows" @cell-click="onGridCellClick" :enableVScroll="true" class="table-no-more cursor-pointer" />
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseButton from '@/App/components/Common/BaseButton'
import AddUserModal from './AddUserModal'

export default {
  name: 'UserList',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    BaseGrid,
    BaseButton
  },
  data() {
    return {
      gridData: {
        columns: [
          {
            title: 'Email (username)',
            dataIndex: 'username'
          },
          {
            title: 'Full name',
            dataIndex: 'firstName',
            renderer: (value, rec) => {
              return (value || '') + ' ' + (rec.lastName || '');
            }
          },
          {
            title: 'Email (notifications)',
            dataIndex: 'email'
          }, 
          /*{
            title: 'Admin',
            dataIndex: 'isAdmin',
            renderer: (value) => {
              return value ? 'Yes' : '';
            },
            align: 'center'
          },*/ 
          {
            title: 'Role',
            dataIndex: 'roleId',
            renderer: (value) => {
              return this.roleLookup[value] || value;
            }
          },               
          // actions
          {
            width:90,
            align:'right',
            actions: [
              {
                text: 'Details',
                handler: this.showDetails
              }/*,          
              {
                text: 'Remove',
                handler: this.removeUser
              }*/
            ]
          }
        ],
        rows: []
      },
      data: [],
      roleLookup: {},
      loading: false
    };
  },
  mounted() {
    /*setTimeout(() => {
      const hostElement = document.querySelector('flowise-chatbot');
      if (hostElement && hostElement.shadowRoot) {
        const shadowRoot = hostElement.shadowRoot;
        const button = shadowRoot.querySelector('button[part="button"]');
        if (button)
          button.style.display = 'none';
      }
    }, 200);*/

    if (this.$app.$data.users?.length)
      this.load(false);
      
    this.load(true);  // this.$route.params.reload
  },
  methods: {
    getFilterState() {
      return this.$app.$data.getState('UserListFilter');
    },    
    load(reload) {  
      Promise.all([
        this.$app.$data.getRoles(reload),
        this.$app.$data.getUsers(reload)
      ])  
      .then(results => {
        this.roleLookup = this.$app.toLookup(results[0]);
        this.data = results[1];
        this.updateGridView();        
      });
    },
    showDetails(dto) {
      this.$router.push({
        name: 'user-details',
        params: { 
          data: JSON.stringify(dto)
        }
      });
    },
    showAddUserModal() {
      this.$vfm.show({
        component: AddUserModal,
        on: {
          ok: (data) => {
            this.$router.push({
              name: 'user-details',
              params: { 
                data: JSON.stringify(data)
              }
            });
          }
        }
      });
    },
    removeUser(rec) {
      _app.$modal.confirm('Remove user', `Are you sure you want to remove "${rec.username}" user?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1);

        this.loading = true;

        this.$app.$api.get('AppRole/DelAppUser', { params: { id: rec.id } }).then(
          () => {
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.load();
          }
        );  
      });
    },
    updateGridView() {

      const filter = this.getFilterState();

      this.gridData.rows = this.data.filter((rec) => { 

        if (filter.username && rec.username.toLowerCase().indexOf(filter.username.toLowerCase()) == -1) return false;

        return true;
      });
    },
    onGridCellClick(row, col) {
      this.showDetails(row, 'status');
    }
  }
}
</script>
