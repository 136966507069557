import { createApp, h } from 'vue/dist/vue.esm-bundler.js'

import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css';

import VueSidebarMenu from './App/components/SideBar' //import VueSidebarMenu from 'vue-sidebar-menu'

import VueApexCharts from "vue3-apexcharts"

import Toaster from "@meforma/vue-toaster";

import {Vue3ProgressPlugin} from '@marcoschulte/vue3-progress';
import "@marcoschulte/vue3-progress/dist/index.css";

import VeeValidatePlugin from "./plugins/vee-validation";

import Chat from 'vue3-beautiful-chat'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { vfmPlugin } from 'vue-final-modal'

import LAB22App from './App/App'

import './assets/css/theme.css'; // basic Porto Admin template skin
import './assets/css/custom-skin.css';  // use style switcher on Porto Admin page -> 
                                        // https://www.okler.net/previews/porto-admin/4.0.0/layouts-default.html -> 
                                        // edit colors and other properties and then click on "Get Skin CSS" option
import './assets/css/theme-overrides.css'; //manual overrides of the theme for particular UI branch

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(VueSidebarMenu)
  .use(VueApexCharts)
  .use(Toaster)
  .use(VeeValidatePlugin)
  .use(Chat)
  .use(VueLoading, {
    color: '#e07791',
    backgroundColor: '#ffffff',
    loader: 'dots'
  })
  .use(Vue3ProgressPlugin)
  .use(vfmPlugin)
  .use(LAB22App)
  .mount('#app');

  export default app;


  
