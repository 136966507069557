<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="350" @close="close">
    <template #body>  
      <FormField name="name" label="Role name" v-model="name" :topLabel="true" />
      <div class="mt-4">Functions:</div>
      <div>
        <MultiCheckbox v-model:value="functions" :options="options" />
      </div>
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-secondary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import MultiCheckbox from '@/App/components/Form/MultiCheckbox';
import { Form } from "vee-validate";
import { ref } from "vue";
import _app from '@/App/App'

let functions = ref([]);
let options = ref([]);

export default {
  name: 'AddRoleModal',
  props: {
    editRec: Object,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form,
    MultiCheckbox
  }, 
  data() {
    return {
      modalName: 'AddRoleModal',
      functionList: [],
      roleFunctions: [],
      schema: {
        name: 'required'
      },
      id: null,
      name: null,
      state: this.$app.$data.getState('AddRoleModal', { id: null }),
      functions,
      options,
    };
  },
  mounted() {

    if (this.editRec) {
      this.id = this.editRec.id;
      this.name = this.editRec.name;
    } 

    this.loadFunctions();
  },
  methods: {
    getTitle() {
      return this.editRec ? 'Edit role' : 'Add role';
    },
    loadFunctions() {
      this.functionList = [];
      this.roleFunctions = [];
      options.value = [];
      functions.value = [];

      let loader = this.$loading.show({ container: this.$refs.form.$el }),
          _roleId = this.id;

      _app.$api.get('AppRole/GetAppFunctionList', { }).then(result => {
        
        this.functionList = result.data || [];
        let _list = this.functionList;
        for(let i=0; i<_list.length; i++) {
          options.value.push( { name: _list[i].name, id: _list[i].id } );
        }

        if (this.editRec) { //edit mode
          _app.$api.get('AppRole/GetAppRole', { params: { id: _roleId, getFullData: true } }).then(result => {
            this.roleFunctions = result.data.roleFunctions || [];
            
            let _funcs = this.roleFunctions;
            for(let i=0; i<_funcs.length; i++) {
              functions.value[i] = _funcs[i].functionId;
            }
            loader.hide();
          }, (error) => {
            loader.hide();
            _app.handleError(error);
          });
        } else
          loader.hide();

      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
      
    },
    onSubmit(values) {  

      let loader = this.$loading.show({ container: this.$refs.form.$el }),
          _data = this.editRec || {};

      this.roleFunctions = [];
      for(let f=0; f<functions.value.length; f++) {
        this.roleFunctions[f] = {
          accountId: -1,
          created: new Date(),
          createdBy: -1,
          functionId: functions.value[f],
          functionName: '',
          roleId: -1,
          roleName: ''
        };
      }
      
      if (!this.editRec) {
        _data = {
            id: null, 
            accountId: -1, 
            name: values.name, 
            created: new Date(), 
            deleted: null, 
            functions: null, 
            activeUsers: null,
            roleFunctions: this.roleFunctions
          };
      } else {
        _data.name = values.name;
        _data.roleFunctions = this.roleFunctions
      }

      this.$app.$api.post('AppRole/SetAppRole', _data).then(result => {
        if( result.data && parseInt(result.data)>0 ) {
          loader.hide();
          this.$emit('ok', _data);
          this.$vfm.hide(this.modalName);
        } else {
          this.$app.notify('There was no error but Role Id was not returned. Please, try again.');
          loader.hide();
        }
      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>