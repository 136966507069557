<template>

  <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">

    <!--
    <div v-if="fullWidth > smallScreenWidth">
      <ModuleHeader title="Notifications" iconClass="inf-module-header-alerts inf-module-header-img">
        <div class="d-flex">
          <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true">
            <BaseField v-if="!this.$app.user.isCustomerUser()" @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="this.$app.$data.customers" :disabled="loading" style="width:250px; margin-right: 20px; margin-top: -3px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />
          </FilterBar>
        </div>
      </ModuleHeader>
    </div>
    <div v-else>
      <ModuleHeader title="Notifications" iconClass="inf-module-header-alerts inf-module-header-img" />
      
      <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true" :loading="loading" style="margin-top:-10px;">
        <BaseField v-if="!this.$app.user.isCustomerUser()" @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="this.$app.$data.customers" :disabled="loading" style="width:250px; margin-right: 20px; margin-top: -3px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />
      </FilterBar>
    </div>
    -->

    <ModuleHeader :title="'Hi, '+user.username" subtitle="Welcome back!" iconClass="inf-module-header-overview inf-module-header-img" />

    <div v-show="!state.customerId || (!isReportLoaded && birdComment)" class="text-center" style="margin-top:96px;">
      <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
      <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;" v-html="birdComment"></p>
    </div>

    <div v-show="isReportLoaded" class="mt-2">
      <div class="row">
        <div class="col-xl-7 col-12 fixed-left">
          <div class="p-3">
            <!--<h3 class="p-0 m-0">Notifications</h3>-->

            <h4 class="font-weight-bold text-muted">Goals & KPIs</h4>
            <div v-if="kpis" class="mt-4 mb-4">
              <KpisView :customerId="this.$store.state.Customer.id" :customerConfig="this.$store.state.Customer.config"  />
            </div>

            <!-- Data analysis notifications -->
            <!--
            <section v-for="not in notifications" :key="not.platform+'-'+not.code" class="card card-featured-left-bz card-featured-bz mb-4">
              <div class="card-body" style="box-shadow: 0px 6px 8px 2px rgba(0,0,0,0.1);">
                <div class="widget-summary widget-summary-sm">
                  <div class="widget-summary-col widget-summary-col-icon">
                    <div class="summary-icon" style="background-color:rgba(46,61,83,0.3);">
                      <i :class="'fa-brands fa-'+not.platform"></i>
                    </div>
                  </div>
                  <div class="widget-summary-col">
                    <div class="summary" style="color:#2e3d53;">
                      <h4 class="title" :style="'font-size:1rem !important; color:'+not.color">{{ not.header }}</h4>
                      <div>
                        <strong>{{not.descr.substring(0,50)}}</strong>{{not.descr.substring(50)}}
                      </div>
                    </div>
                  </div>
                  <div @click="getCardInfo('notification', not)" class="widget-summary-col" style="cursor:pointer;">
                    <i class="fa fa-ellipsis-vertical"></i>
                  </div>
                </div>
              </div>
            </section>
            -->

            <!-- Calendar notifications -->
            <h4 class="font-weight-bold text-muted">Upcoming events</h4>
            <section v-for="dt in this.calendar" :key="dt.id" class="card card-featured-left-bz card-featured-bz mb-4" style="max-width:840px;">
              <div class="card-body" style="box-shadow: 0px 6px 8px 2px rgba(0,0,0,0.1);">
                <div class="widget-summary widget-summary-sm">
                  <div class="widget-summary-col widget-summary-col-icon">
                    <div class="summary-icon" style="background-color:rgba(46,61,83,0.3);">
                      <i class="fa fa-calendar"></i>
                    </div>
                  </div>
                  <div class="widget-summary-col">
                    <div class="summary" style="color:#2e3d53;">
                      <h4 class="title" style="font-size:1rem !important;">{{ dt.date }}</h4>
                      <div>
                        <strong>{{ dt.name }}</strong><span v-if="dt.country_code"> in {{ dt.country_code }} </span> is comming up
                        <span v-if="dt.category!=='Other'" :style="'color:'+getCalendarColor(dt.category)">{{ dt.category }}</span>
                      </div>
                    </div>
                  </div>
                  <div @click="getCardInfo('calendar', dt)" class="widget-summary-col" style="cursor:pointer;">
                    <i class="fa fa-ellipsis-vertical" style="font-size:large;"></i>
                  </div>
                </div>
              </div>
            </section>
            
          </div>
        </div>
        <div class="d-none d-xl-block xl-5 fixed-right">
          <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
          <p class="bird-comment text-start" style="cursor:default; font-size:14px; width:325px; margin: 0 auto;" v-html="getBirdCommentReportInfo()"></p>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import KpisView from './KpisView'
//import FilterBar from '@/App/components/Common/FilterBar'
//import BaseField from '@/App/components/Form/BaseField'
import TooltipModal from './TooltipModal'
import { ref } from 'vue';

const getCurrentDate = () => new Date();

const adjustDateToCurrentYear = (dateStr) => {
    const date = new Date(dateStr);
    const currentYear = getCurrentDate().getFullYear();
    date.setFullYear(currentYear);
    return date;
};

const getFutureDates = (calendar, startDays, endDays) => {
  const currentDate = getCurrentDate();
  const startDate = new Date(currentDate);
  startDate.setDate(startDate.getDate() + startDays);
  const endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() + endDays);

  return calendar
      .map(event => {
          if (event.every_year) {
              const adjustedDate = adjustDateToCurrentYear(event.date);
              return { ...event, date: adjustedDate.toISOString().split('T')[0] };
          }
          return event;
      })
      .filter(event => {
          const eventDate = new Date(event.date);
          return eventDate >= startDate && eventDate <= endDate;
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
};

export default {
  name: 'NotificationsView',
  components: {
    ModuleHeader,
    KpisView,
    //FilterBar,
    //BaseField,
  },
  data: function() {
    return {
      //data vars
      calendar: null,
      kpis: false,
      //notifications: null,
      customerConfig: [],
      state: _app.$data.getState('NotificationsFilter', { 
        customerId: null,
      }),
      user: this.$store.state.auth.user,

      //gui vars
      birdComment: null,
      birdCommentReport: `Select a client and I'll fetch Flamingo insigths for you.`,
      isReportLoaded: false,
      fullHeight: 0,
      fullWidth: 0,
      loading: false,
      mediumScreenWidth: 991,
      mobileListWidth: 250,
      smallScreenWidth: 767,
      largeScreenWidth: 1199,
    }
  },
  mounted() {
    window.addEventListener('resize', this.doLayout);

    //setTimeout(() => {
      //setTimeout(() => {
        this.fullHeight = ref(window.innerHeight);
        this.fullWidth = ref(window.innerWidth); //this.$refs.bodyEl.clientWidth;

        //this.restyleListBoxes();

        if(this.user.customerId)
          this.state.customerId = this.user.customerId;
        else
          this.state.customerId = this.$store.state.Customer.id;

        if(this.state.customerId) {
          this.setCustomerConfig(this.state.customerId);
          if (this.customerConfig.pmakpis !== undefined) { //this should not happen!  
            if(this.customerConfig.pmakpis.kpis !== undefined) {
              if(this.customerConfig.pmakpis.kpis.length>0)
                this.kpis = true;
            }
          }
          this.setCalendar();
          this.checkDailyReport(this.state.customerId);
        }
        else
          this.birdComment = this.birdCommentReport;
      //}, 200);
    //}, 200);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.doLayout);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.state.customerId = this.$store.state.Customer.id;
      this.changeCustomer();
    }
  },
  methods: {
    getCalendarColor(category) {
      if(category=='Awareness')
        return '#B54708'; //orange
      else if(category=='Sports')
        return '#344054'; //blue
      else if(category=='Holiday')
        return '#C01048'; //red
      else if(category=='Other')
        return '#067647'; //green
    },
    getCardInfo(type, rec) {
      let label = new String(),
          descr = new String();

      if(type=='calendar') {
        label = rec.name;
        descr = rec.date;
        _app.$helper.notifyInfo(`We are working hard on extending Flamingo's assistant skills even further.`);
        //console.log(rec);
      }
      /*else if(type=='notification') {
        console.log(rec);
      }
      else if(type=='kpi') {
        console.log(rec);
      }*/

      /*this.$vfm.show({
        component: TooltipModal,
        bind: {
          kpiLabel: label,
          kpiDescr: descr,
        },
      });*/
    },
    getBirdCommentReportInfo() {
      const now = new Date();
      const seconds = now.getSeconds();
      let comment = '';

      if(seconds<10)
        comment = `The latest updates are ready for you on the left. I take my job seriously, you know. You're welcome for the early bird service!`;
      else if(seconds>=10 && seconds<20)
        comment = `All your important notifications are on the left. I'm always on top of things. That's why they call me the smartest bird around!`;
      else if(seconds>=20 && seconds<30)
        comment = `I've pulled together today's highlights for you on the left. My dedication knows no bounds. You're in good hands with me!`;
      else if(seconds>=30 && seconds<40)
        comment = `Your fresh notifications are on the left. I work hard so you don't have to. Admit it - I'm the most efficient assistant you know!`;
      else if(seconds>=40 && seconds<50)
        comment = `The latest intel is ready on the left. I pride myself on being early and accurate. I'm pretty impressive, don't you think?`;
      else
        comment = `I've fetched some important Notifications for you on the left. I wake up nice and early each day to provide this information. I'm a good little helper, right?`;

      return comment += '<br /><br />Click on any notification to get even more insights from /me.';
    },
    setCustomerConfig(customerId) {
      this.customerConfig = JSON.parse(
                              this.$app.$data.customers.filter(obj => {
                                return obj.id==customerId;
                              })[0].config
                            ) || [];
      //console.log(this.customerConfig);
    },
    /*restyleListBoxes() {
      //const elements = document.querySelectorAll('.custom-select');
      //elements.forEach(el => {
      //  el.style.setProperty('color', '#2E3D53', 'important');
      //  el.style.setProperty('font-size', '14px', 'important');
      //  el.style.setProperty('font-weight', 'bolder', 'important');
      //  el.style.setProperty('cursor', 'pointer', 'important');
      //});

      if(this.fullWidth<=this.mediumScreenWidth)
        this.calcCustomerListWidth(this.fullWidth);
    },*/
    /*calcCustomerListWidth(fullWidth) {
      let width = (fullWidth-40)/2;
      if(width>250)
        width = 250;
      this.mobileListWidth = width;
    },*/
    changeCustomer() {
      this.isReportLoaded = false;
      this.birdComment = null;
      this.kpis = false;

      let id = this.state.customerId;
      if(id) {
        this.setCustomerConfig(id);
        
        setTimeout(() => {
          this.setCalendar();

          if (this.customerConfig.pmakpis !== undefined) { //this should not happen!  
            if(this.customerConfig.pmakpis.kpis !== undefined) {
              if(this.customerConfig.pmakpis.kpis.length>0)
                this.kpis = true;
            }
          }

          this.checkDailyReport(id);
        }, 200);
      }
      else
        this.birdComment = this.birdCommentReport;
    },
    setCalendar() {
      if(this.customerConfig.marketingCalendar !== undefined)
        this.calendar = getFutureDates(this.customerConfig.marketingCalendar, 0, 30);
    },
    getCustomerName(customers,id) {
      if(this.state.customerId) {
        return customers.filter(obj => {
                    return obj.id==id;
                })[0].name;
      }
      else
        return null;
    },
    checkDailyReport(customerId) {
      /*this.notifications = await this.getNotifications(customerId);
      
      if( (this.notifications!==null && this.notifications.length>0) || (this.kpis!==null && this.kpis.length>0) || (this.calendar!==null && this.calendar.length>0) )
        this.isReportLoaded = true;*/

      if(this.calendar.length>0  || this.kpis) {
        this.isReportLoaded = true;
        this.birdComment = null;
      }
      else      
        this.birdComment = `Ups, Flamingo got lazy and did not prepare any data for <strong>${this.getCustomerName(this.$app.$data.customers, this.state.customerId)}</strong>. Please, contact system administrator or TSF staff to help you out.`;
    },
    /*async getNotifications(customerId) {
      '#B54708'; //orange
      '#344054'; //blue
      '#C01048'; //red
      '#067647'; //green
      return [
          {platform:'google',   code:'bounce_rate',name:'Bounce rate',  color:'#C01048', header:'Your Bounce rate is up by 40%! This is not good.',descr:'Think about your landing page and homepage, do they give the customers what they want? Are you advertising for the wrong keywords or use them in your SEO strategy? Let me know if you want to dig deeper and nail this problem!'},
          {platform:'mailchimp',code:'mail_list',  name:'Mailing lists',color:'#B54708', header:'Only 0.03% people unsubscribed...',               descr:'...from the mailing list during the past 6 months! You are doing well, keep up the good work. You have not sent a newsletter in more than 4 months. It is impossible not to have anything to share and to inspire customers to buy from you. Think about sales, calendar-holidays as opportunities for campaigns?'}
        ];
    },*/
    /*async getKpis(customerId) {
      return true;
    },*/
    doLayout() {
      let currentWidth = this.fullWidth,
          isOldMobile = (currentWidth<=this.mediumScreenWidth) ? true : false;

      this.fullWidth = ref(window.innerWidth);
      this.fullHeight = ref(window.innerHeight);

      /*if(this.fullWidth<=this.mediumScreenWidth)
          this.calcCustomerListWidth(this.fullWidth);*/

      let isNewMobile = (this.fullWidth<=this.mediumScreenWidth) ? true : false;

      if(isNewMobile!==isOldMobile) {
        //console.log('toggle desktop/mobile layout)
      }
    },
  }
};
</script>

<style scoped>
.fixed-left {
  /*max-width: 572px;*/
}
.fixed-right {
  width: 360px;
  text-align: right;
}
@media (max-width: 767.98px) {
  .fixed-right {
    width: 100%;
  }
}
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
/*.notification-card{
  background: white;
  border-radius: 5px;
  margin-left: 0px;
  margin-bottom: 20px;
  box-shadow: 0px 6px 8px 2px rgba(0,0,0,0.03);
}*/
.card-featured-bz {
  border-color: #2e3d53;
}
.card-featured-left-bz {
  border-left: 3px solid rgba(46,61,83,0.5);
}
</style>