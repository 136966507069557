import AppApi from '@/App/AppApi';
import AppHelper from '@/App/AppHelper';
import { reactive } from "vue";
class AppData {

  Lab22Users = [
    'leskovskov@gmail.com',
    'blazhek@gmail.com',
    'admin',
    'adminTsf',
  ];
  

  //#region state

  _state = reactive({});

  getState(key, initialState) {
    let state = _this._state[key];
    if (AppHelper.isEmpty(state)) {
      _this._state[key] = state = AppHelper.isDefined(initialState) ? initialState : {};
    }
    return state;
  }

  setState(key, value) {
    _this._state[key] = value;
  }

  hasState(key) {
    return !AppHelper.isEmpty(_this._state[key]);
  }


  // get xState() {
  //   var o = _this._state['X_STATE'];

  //   if (!o) 
  //     _this._state['X_STATE'] = o = {};
    
  //   return o;
  // }

  // set xState(value) {
  //   _this._state['X_STATE'] = value;
  // }

  // get viewHistory() {
  //   return JSON.parse(localStorage.getItem('history')) || [];
  // }

  // set viewHistory(value) {
  //   localStorage.setItem('history', JSON.stringify(value));
  // }

  //#endregion

  //#region stores

  _stores = {};

  getStore(storeKey, apiUrl, reload, postData) {
    return new Promise((resolve, reject) => {
      var data = !reload ? _this._stores[storeKey] : null;
      if (data) {
        resolve(data);
      } else {

        const successCallback = result => {    
          _this._stores[storeKey] = result.data;
          resolve(result.data);
        };

        const errorCallback = error => {
          reject(error);  // error?.response?.data
        };

        if (AppHelper.isDefined(postData)) {
          AppApi.post(apiUrl, postData).then(successCallback, errorCallback);
        } else {
          AppApi.get(apiUrl).then(successCallback, errorCallback);
        }
      }
    });
  }  

  storeLoaded(storeKey) {
    return !!_this._stores[storeKey]?.length;
  }

  clearStore(storeKey) {
    _this._stores[storeKey] = [];
  }

  getUsers(reload) {
    return _this.getStore('USER_LIST', 'AppUser/GetAppUserList', reload);
  } 

  getRoles(reload) {
    return _this.getStore('ROLE_LIST', 'AppRole/GetAppRoleList', reload);
  }

  getCustomers(reload) {
    return _this.getStore('CUSTOMER_LIST', 'Customer/GetCustomerList', reload);
  } 

  getAgents(reload) {
    return _this.getStore('AGENT_LIST', 'AiAgent/GetAiAgentList', reload);
  } 
  
  get users() {
    return _this._stores['USER_LIST'] || [];
  }

  get customers() {
    return _this._stores['CUSTOMER_LIST'] || [];
  }

  get agents() {
    return _this._stores['AGENT_LIST'] || [];
  }

  async preloadStores() {
    await Promise.all([
      _this.getUsers(true),
      _this.getCustomers(true),
      _this.getAgents(true)
    ]);
  }

  //#endregion
}

const _this = new AppData();

export default _this;