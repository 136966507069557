<template>
  <input
    @input="(event) => $emit('checked', event.target.checked)"
    type="checkbox"
    :checked="checked"
    :id="fieldId || this.$id('chk')"
    class="form-check-input"
    v-bind="$attrs"
  />
  <label :for="fieldId || this.$id('chk')" class="form-check-label" :title="titleLabel">
    &nbsp;&nbsp;{{ label }}
  </label>
  <br />
</template>

<script>
export default {
  name: 'BaseCheckbox',
  inheritAttrs: false,
  props: {
    label: String,
    titleLabel: String,
    fieldId: {
      type: String
    },
    checked: {
      type: Boolean,
    },
  },
  // model: {
  //   prop: 'checked',
  //   event: 'checked'
  // },
  // emits: ['checked']
};
</script>

<style>
</style>
