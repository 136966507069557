<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Select client" v-bind="$attrs" :width="350" @close="cancel">
    <template #body>
      <p>When you select/change a client all app's functionalities and data then refer to selected client.</p> 
      <FormField v-model="id" name="customerId" label="Client" :topLabel="true" as="select" addClearOption :data="this.$app.$data.customers" />
    </template>
    <template #footer>
      <button @click="cancel" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-secondary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'ChangeCustomerModal',
  props: {
    editRec: Object,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  }, 
  data() {
    return {
      modalName: 'ChangeCustomerModal',
      schema: {
        
      },
      id: null,
      name: null,
      oldId: null,
      oldName: null,
      data: {id:null, name: null},
    };
  },
  mounted() {
    if (this.editRec) {
      this.oldId = this.editRec.id; //currently active customer id
      this.oldName = this.editRec.name; //currently active customer name

      this.id = this.editRec.id;
    }
  },
  methods: {
    setCustomer(id, name) {
      this.data.id = id;
      this.data.name = name;
    },
    onSubmit(values) {
      if(_app.$helper.isEmpty(values.customerId)) {
        this.id = null;
        this.name = null;
      }
      else
        this.name = _app.$helper.getCustomerName(values.customerId,this.$app.$data.customers);

      this.setCustomer(this.id, this.name)

      setTimeout(() => {
        this.$emit('ok', this.data);
        this.$vfm.hide(this.modalName);
      }, 100);
    },
    cancel() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>