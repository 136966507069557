<template>

  <Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
    <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="350" @close="close">
      <template #body>  
        <p class="text-primary"><strong>{{ platformName + ' - ' + kpiName }}</strong></p>
        <FormField name="descr" label="Description" v-model="descr" :topLabel="true" />
        <FormField name="kpiFunction" label="Agg. function" v-model="kpiFunction" :topLabel="true" as="select" :data="functions" />
        <FormField name="kpiValue" label="Value" v-model="kpiValue" type="number" :topLabel="true" />
        <div class="row mt-3">
          <div class="col-6">
            <FormField name="periodFrom" label="Period from" v-model="periodFrom" :topLabel="true" type="date" style="width:120px;" />
          </div>
          <div class="col-6">
            <FormField name="periodTo" label="Period to" v-model="periodTo" :topLabel="true" type="date" style="width:120px;" />
          </div>
        </div>
      </template>
      <template #footer>
        <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-secondary">Ok</button>
      </template>
    </base-modal>
  </Form>
  
  </template>
  
  <script>
  import BaseModal from '@/App/components/Modal/BaseModal';
  import FormField from '@/App/components/Form/FormField';
  //import BaseCheckbox from '@/App/components/Form/BaseCheckbox'
  import { Form } from "vee-validate";
  import _app from '@/App/App'
  
  export default {
    name: 'EditKpiModal',
    props: {
      editRec: Object,
      platformName: String,
      kpiName: String,
      isMetric: Boolean,
    },
    inheritAttrs: false,
    emits: ['ok'],
    components: {
      BaseModal,
      FormField,
      Form,
      //BaseCheckbox,
    }, 
    data() {
      return {
        modalName: 'EditKpiModal',
        schema: {
          descr: 'required',
          kpiFunction: 'required',
          periodFrom: 'required',
          periodTo: 'required'
        },

        //rec
        descr: null,
        kpiFunction: null,
        kpiValue: null,
        periodFrom: null,
        periodTo: null,

        functions: [],
      };
    },
    mounted() {
  
      if(this.isMetric)
        this.functions.push({id:1, name:'Sum (SUM)', code:'SUM'});
      if(this.isMetric)
        this.functions.push({id:2, name:'Average (AVG)', code:'AVG'});
      if(this.isMetric)
        this.functions.push({id:3, name:'Count (COUNT)', code:'CNT'});
      this.functions.push({id:4, name:'Count distinct (COUNT DISTINCT)', code:'CNTDST'});
      /*if(this.isMetric)
        this.functions.push({id:5, name:'Minimum (MIN)', code:'MIN'});
      if(this.isMetric)
        this.functions.push({id:6, name:'Maximum (MAX)', code:'MAX'});*/

      if (this.editRec && this.editRec.length>0) {
        let rec = this.editRec[0];
        
        this.descr = rec.kpiUserDescr;
        this.periodFrom = rec.periodFrom;
        this.periodTo = rec.periodTo;
        this.kpiFunction = this.functions.filter(obj => {
                          return obj.code.toLowerCase()==rec.kpiFunction.toLowerCase();
                        })[0].id;
        this.kpiValue = rec.kpiValue;
      }
    },
    methods: {
      getTitle() {
        return this.editRec ? 'Edit KPI & Goal' : 'Add KPI & Goal';
      },
      onSubmit(values) {  
        let _data = {
          kpiUserDescr: this.descr,
          kpiFunction: this.functions.find(f => f.id === this.kpiFunction)?.code,
          kpiValue: this.kpiValue,
          periodFrom: this.periodFrom,
          periodTo: this.periodTo
        };
        
        this.$emit('ok', _data);
        this.$vfm.hide(this.modalName);
      },
      close() {
        this.$vfm.hide(this.modalName);
      }
    }
  }
  </script>
  
  <style scoped>
  </style>