<template>

    <div ref="bodyEl" class="main-template-div h-full w-full" :key="this.$store.state.Customer.id" style="padding: 0px 0px 0px 30px;">
      
      <div class="h-full" style="min-height:300px;">
        <div class="relative z-0 flex h-full w-full overflow-hidden">
          
          <!-- Left Section for Chat -->
          <div id="div-left" class="relative flex h-full max-w-full flex-1 flex-col overflow-hidden" style="padding-right:30px;">
            <main class="relative h-full w-full flex-1 overflow-auto transition-width">
              <div role="presentation" tabindex="0" class="flex h-full flex-col div-presentation">
                
                <div class="flex-1 overflow-hidden">

                  <!-- START New chat landing page -->
                  <div v-if="!messages" class="relative h-full">
                    <div class="absolute left-0 right-0 h-full">
                      <div class="sticky flex items-center justify-between z-10" style="top:0px !important; color:#2e3d53; margin-bottom:0.375rem; background:#ffffff; height:50px; font-weight:600; padding:0.5rem;">
                        <div class="flex flex-col w-1/2" style="padding-top:30px;">
                          <ModuleHeader title="Assistant" :subtitle="this.$store.state.Customer.name" iconClass="inf-module-header-flamingo inf-module-header-img" style="font-weight:normal !important; margin-top:27px !important; margin-left:-8px !important;" />
                          <!--
                          <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true" :loading="loading">
                            <BaseField @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="this.$app.$data.customers" :disabled="loading" style="width:250px;" v-show="!this.$app.user.isCustomerUser()" class="custom-select" />
                            <BaseField v-model="state.pmaFileId" name="pmaFileId" placeholder="Available data" as="select" required :data="pmaFiles" :disabled="loading" style="width:250px;" v-show="state.customerId && pmaFiles.length>0" class="custom-select2" />
                          </FilterBar>
                          -->
                        </div>
                        <div v-if="mainWidth<offcanvasScreenWidth" class="flex flex-col w-1/2 items-end" style="padding-top:30px;">
                          <button v-show="state.customerId" type="button" class="btn btn-outline-secondary dropdown-toggle align-right" data-bs-toggle="dropdown" aria-expanded="false" style="margin-top:-10px;">
                              <i class="fa-solid fa-gears"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end" style="z-index:1000;">                            
                            <!--<li><a class="dropdown-item" @click.prevent="clearChat"><i class="fa fa-file-circle-plus icon-active"></i> New thread</a></li>-->
                            <li><a class="dropdown-item" @click.prevent="clearChat"><i class="fa fa-plus-circle"></i> New thread</a></li>
                            <li><a class="dropdown-item" @click.prevent="showOffcanvas"><i class="fa-solid fa-history"></i> Show history</a></li>
                            <li class="divider"></li>
                            <li><a class="dropdown-item" @click.prevent="customInstructions"><i class="fa-solid fa-user-circle"></i> Custom instructions</a></li>
                            <li v-show="messages" class="divider"></li>
                            <li v-show="messages"><a class="dropdown-item" @click.prevent="printConversation"><i class="fa-solid fa-print"></i> Print conversation</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="flex h-full flex-col items-center justify-center">
                        <div class="relative mb-3 w-full text-center">
                          <img id="flamingo-thinker" :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
                          <p v-show="this.fullHeight>=470" class="bird-comment" v-html="flamingoGreeting" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;"></p>
                          <p class="mt-4">
                            <button @click="openPromptLibrary" :disabled="!this.$store.state.Customer.id" class="btn btn-outline-secondary" style="width:165px;">Prompt generator</button>
                            <button @click="competitionAnalysis" :disabled="!this.$store.state.Customer.id" class="btn btn-outline-secondary ms-3" style="width:165px;">Competitors analysis</button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END New chat landing page -->

                  <!-- START Chat in progress -->
                  <div v-else class="h-full overflow-y-auto w-full" style="padding: 0px; scrollbar-width:none;">
                    <div class="flex flex-col text-sm" style="padding-bottom: 2.25rem;">
                      <div class="sticky top-0 flex items-center justify-between z-10 mb-5" style="top:0px !important; color:#2e3d53; background:#ffffff; height:50px; font-weight:600; padding:0.5rem;">
                        <div class="flex flex-col w-1/2" style="padding-top:30px;">
                          <ModuleHeader title="Assistant" :subtitle="this.$store.state.Customer.name" iconClass="inf-module-header-flamingo inf-module-header-img" style="font-weight:normal !important; margin-top:27px !important; margin-left:-8px !important;" />
                          <!--
                          <FilterBar v-slot="{state}" :filterState="state" :hideFilterByLabel="true" :loading="loading">
                            <BaseField @change="changeCustomer" v-model="state.customerId" name="customerId" placeholder="Client" as="select" required :data="this.$app.$data.customers" v-show="!this.$app.user.isCustomerUser()" :disabled="loading" style="width:250px;" class="custom-select" />
                            <BaseField v-model="state.pmaFileId" name="pmaFileId" placeholder="Available data" as="select" required :data="pmaFiles" :disabled="loading" style="width:250px;" v-show="state.customerId && pmaFiles.length>0" class="custom-select2" />
                          </FilterBar>
                          -->
                        </div>
                        <div v-if="mainWidth<offcanvasScreenWidth" class="flex flex-col w-1/2 items-end" style="padding-top:30px;">
                          <button type="button" class="btn btn-outline-secondary dropdown-toggle align-right" data-bs-toggle="dropdown" aria-expanded="false" style="margin-top:-10px;">
                              <i class="fa-solid fa-gears"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end" style="z-index:1000;">
                            <!--<li><a class="dropdown-item" @click.prevent="clearChat"><i class="fa fa-file-circle-plus icon-active"></i> New thread</a></li>-->
                            <li><a class="dropdown-item" @click.prevent="clearChat"><i class="fa fa-plus-circle"></i> New thread</a></li>
                            <li><a class="dropdown-item" @click.prevent="showOffcanvas"><i class="fa-solid fa-history"></i> Show history</a></li>
                            <li class="divider"></li>
                            <li><a class="dropdown-item" @click.prevent="customInstructions"><i class="fa-solid fa-user-circle"></i> Custom instructions</a></li>
                            <li v-show="messages" class="divider"></li>
                            <li v-show="messages"><a class="dropdown-item" @click.prevent="printConversation"><i class="fa-solid fa-print"></i> Print conversation</a></li>
                          </ul>
                        </div>
                      </div>
                      <div v-for="(message, index) in messages" :key="message.id" dir="auto" :class="'w-full chat-msg-id-'+message.id" :data-msgId="'chat-turn-'+message.id" :data-msgCount="message.id + '-' + index" >
                        <div class="py-2 px-3">
                          <div class="mx-auto flex flex-1 gap-3 text-main">

                            <div v-if="message.type=='User'" class="relative flex w-full min-w-0 flex-col">
                              <!-- START user message -->
                              <div class="flex-col gap-1">
                                <div class="flex flex-grow flex-col max-w-full">
                                  <div data-author-message-role="User" class="flex flex-col items-start break-words overflow-x-auto gap-2" style="min-height:20px;">
                                    <div class="flex w-full flex-col gap-1 items-end">
                                      <div class="rounded-10 px-5" v-html="message.msg" style="max-width:90%; color:#2e3d53; background:rgba(160,220,246,0.3); padding-bottom: 10px !important; padding-top: 10px !important;"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- END user message -->
                            </div>
                            
                            <div v-else class="flex-shrink-0 flex flex-col relative items-end" style="margin-top:40px;">
                              <!-- START assistant message -->
                              <div class="relative flex w-full min-w-0 flex-col agent-turn">
                                <img v-if="(message.fileType=='bz-text')" class="assistant-image" :src="require('../../assets/img/flamingo-head.png')" title="Assistant" />
                                <div class="flex-col gap-1" style="padding-left:40px;">
                                  <div class="flex flex-grow flex-col max-w-full">
                                    <div data-author-message-role="Assistant" dir="auto" class="flex flex-col items-start break-words overflow-x-auto gap-2" style="min-height:20px;">
                                      <div class="flex w-full flex-col gap-1" style="padding-top:3px;">
                                        <div v-if="(message.fileType=='bz-text')" v-html="getMarkdownHtml(message.msg)" class="prose w-full break-words" :style="'width:'+(assistantMsgWidth)+'px;'"></div>
                                        <!--
                                        <div v-else class="w-full" style="width:120px !important; border:1px solid #E31B54; padding:10px 10px 10px 10px;">
                                          <a href="javascript:void(0);" @click="downloadFile(`${message.fileId}`,`${message.fileType}`)"><code>DOWNLOAD FILE</code></a>
                                        </div>
                                        -->
                                        <div v-else class="w-full imageAnswer" :data-message-fileid="message.fileId" :data-message-filetype="message.fileType" style="padding:10px 10px 10px 5px;"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- END assistant message -->
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END Chat in progress -->
                </div>

                <!-- START questions/prompts -->
                <div class="w-full pt-0 ps-0 pe-0">
                  <div class="px-3 text-base m-auto">
                    <div class="mx-auto flex flex-1 gap-3 text-base">
                      <form class="w-full">
                        <div class="flex w-full items-center">
                          <div class="overflow-hidden flex flex-col w-full flex-grow relative" style="border:1px solid rgb(46, 61, 83, 0.2); border-radius:6px;">
                            <div class="row">
                              <!--<div class="col-sm-10 col-lg-11 text-start">-->
                              <div class="col-12 text-start">
                                <textarea @keydown="handleTextareaKeyPress" ref="promptTextarea" v-model="textarea" :disabled="!state.customerId" tabindex="0" dir="auto" rows="1" placeholder="Ask Flamingo (SHIFT+ENTER for new line)" class="form-control m-0 w-full resize-none" style="min-height:42px; overflow:hidden; border:none !important; box-sizing:border-box; overflow-wrap:break-word; padding-left:12px; padding-right:12px; padding-top:10px;"></textarea>
                              </div>
                              <!---<div class="col-sm-2 col-lg-1 text-end">
                                <i @click="sendMessage" class="fa fa-circle-arrow-up" :style="( (textarea.length>0) ? 'color:#2e3d53; ' : 'color:rgb(119,119,119,0.5); ')+'margin-top:6px; margin-right:6px; font-size:x-large; cursor:pointer;'"></i>
                              </div>-->
                            </div>
                            <div v-show="state.customerId" class="row mt-1">
                              <div class="col-6 text-start">
                                <i @click="uploadFile" class="fa fa-file-arrow-up" style="color:rgb(119,119,119,0.5); padding-bottom:6px; margin-left:6px; font-size:x-large; cursor:pointer;"></i>
                              </div>
                              <div class="col-6 text-end">
                                <i @click="sendMessage" class="fa fa-circle-arrow-up" :style="( (textarea.length>0) ? 'color:#2e3d53; ' : 'color:rgb(119,119,119,0.5); ')+'padding-bottom:6px; margin-right:6px; font-size:x-large; cursor:pointer;'"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="relative px-2 py-2 text-center text-xxs">
                    <div id="divTypewriter" class="row" :style="'position:absolute; top:'+typewriterTop+'px; left:20px;'">
                      <div class="col-12" id="typewriterOutput" style="font-family:monospace; color:#2e3d53; overflow:hidden;"></div>
                    </div>
                    <span>Flamingo can make mistakes. Re-check important information.</span>
                  </div>
                </div>
                <!-- END questions/prompts -->

              </div>
            </main>
          </div>

          <!-- Right Section for Messages history -->
          <div v-show="mainWidth>=offcanvasScreenWidth" id="div-right" class="flex-shrink-0 overflow-x-hidden" style="margin-right:-30px; border-left:1px solid #dadada;">
            <div class="h-full" :style="'width:'+fixedRightWidth+'px;'">
              <div class="flex h-full flex-col">
                <div class="relative h-full w-full flex-1 items-start">
                  <nav class="flex h-full w-full flex-col ps-3 pe-0 pb-3" aria-label="Chat history">
                    <div class="flex-col flex-1" style="margin-right:-0.5rem;">
                      <div class="sticky left-0 right-0 top-0 z-20 pt-3" style="background:#ffffff; height:50px;">
                        <div class="row" :style="'max-width:'+(fixedRightWidth-20)+'px;'">
                          <div class="col-3 text-start"><i @click="clearChat" class="fa fa-file-circle-plus icon-active" title="New thread"></i></div>
                          <div class="col-3 text-center"><i @click="filteringThreads=!filteringThreads" class="fa fa-search icon-active" title="Filter threads"></i></div>
                          <div class="col-3 text-center"><i @click="customInstructions" class="fa fa-user-circle icon-active" title="Custom instructions"></i></div>
                          <div class="col-3 text-end"><i @click="printConversation" class="fa fa-print icon-active" title="Print conversation"></i></div>
                        </div>
                      </div>
                      <div v-show="filteringThreads" class="flex flex-col gap-2 pt-2 pb-2 text-sm">
                        <BaseField v-model="filterString" name="filterString" placeholder="Search by name..." style="max-width:180px; height:20px; font-size:small;" />
                        <div @click="filterString=''" style="position:absolute; margin-top:8px; margin-left:160px; font-weight:bold; cursor:pointer">X</div>
                      </div>
                      <div class="flex flex-col gap-2 pt-2 pb-2 text-sm">
                        <div v-if="recentThreads.length > 0">
                          <h5 class="thread-category">Recent (Last 7 days)</h5>
                          <div v-for="chat in recentThreads" :key="chat.threadId" class="relative" style="height:40px;">
                            <button type="button" class="btn btn-outline-transparent" @mouseover="showIcons($event, true)" @mouseout="showIcons($event, false)" :style="'margin-bottom:0px; max-width:'+(fixedRightWidth-48)+'px; '+((chat.threadId==currentChatId) ? 'background:rgb(224,224,224,0.4); border: 1px solid #e0e0e0; border-radius:10px;' : '')">
                              <div class="row pe-0">
                                <div v-show="!chatEdit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="loadThread(chat.threadId)" :title="chat.threadName" style="font-size:13px !important; padding-left:10px; padding-right:0px;">{{ chat.threadName }}</div>
                                <div v-show="!chatEdit" class="col-3 text-end btn-outline-transparent-hover" style="padding-left:0px;">
                                  <i @click="chatHistoryEdit(chat)" class="fa fa-edit edit-icons" style="padding-right:5px; display:none;" title="Rename"></i>
                                  <i @click="chatHistoryDelete(chat)" class="fa fa-trash edit-icons" style="display:none;" title="Delete"></i>
                                </div>
                                <div v-show="chatEdit" class="col-12 btn-outline-transparent-hover text-start">
                                  <input class="bzChatHistoryField" :id="removeSpaces(chat.threadName)" :value="chat.threadName" @blur="chatEdit=false" @keyup.enter="updateChatHistoryName($event, chat)" type="text" label="" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div v-if="olderThreads.length > 0">
                          <h5 class="thread-category">Older (8 - 30 days)</h5>
                          <div v-for="chat in olderThreads" :key="chat.threadId" class="relative" style="height:40px;">
                            <button type="button" class="btn btn-outline-transparent" @mouseover="showIcons($event, true)" @mouseout="showIcons($event, false)" :style="'margin-bottom:0px; max-width:'+(fixedRightWidth-48)+'px; '+((chat.threadId==currentChatId) ? 'background:rgb(224,224,224,0.4); border: 1px solid #e0e0e0; border-radius:10px;' : '')">
                              <div class="row pe-0">
                                <div v-show="!chatEdit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="loadThread(chat.threadId)" :title="chat.threadName" style="font-size:13px !important; padding-left:10px; padding-right:0px;">{{ chat.threadName }}</div>
                                <div v-show="!chatEdit" class="col-3 text-end btn-outline-transparent-hover" style="padding-left:0px;">
                                  <i @click="chatHistoryEdit(chat)" class="fa fa-edit edit-icons" style="padding-right:5px; display:none;" title="Rename"></i>
                                  <i @click="chatHistoryDelete(chat)" class="fa fa-trash edit-icons" style="display:none;" title="Delete"></i>
                                </div>
                                <div v-show="chatEdit" class="col-12 btn-outline-transparent-hover text-start">
                                  <input class="bzChatHistoryField" :id="removeSpaces(chat.threadName)" :value="chat.threadName" @blur="chatEdit=false" @keyup.enter="updateChatHistoryName($event, chat)" type="text" label="" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div v-if="ancientThreads.length > 0">
                          <h5 class="thread-category">Ancient (More than 30 days)</h5>
                          <div v-for="chat in ancientThreads" :key="chat.threadId" class="relative" style="height:40px;">
                            <button type="button" class="btn btn-outline-transparent" @mouseover="showIcons($event, true)" @mouseout="showIcons($event, false)" :style="'margin-bottom:0px; max-width:'+(fixedRightWidth-48)+'px; '+((chat.threadId==currentChatId) ? 'background:rgb(224,224,224,0.4); border: 1px solid #e0e0e0; border-radius:10px;' : '')">
                              <div class="row pe-0">
                                <div v-show="!chatEdit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="loadThread(chat.threadId)" :title="chat.threadName" style="font-size:13px !important; padding-left:10px; padding-right:0px;">{{ chat.threadName }}</div>
                                <div v-show="!chatEdit" class="col-3 text-end btn-outline-transparent-hover" style="padding-left:0px;">
                                  <i @click="chatHistoryEdit(chat)" class="fa fa-edit edit-icons" style="padding-right:5px; display:none;" title="Rename"></i>
                                  <i @click="chatHistoryDelete(chat)" class="fa fa-trash edit-icons" style="display:none;" title="Delete"></i>
                                </div>
                                <div v-show="chatEdit" class="col-12 btn-outline-transparent-hover text-start">
                                  <input class="bzChatHistoryField" :id="removeSpaces(chat.threadName)" :value="chat.threadName" @blur="chatEdit=false" @keyup.enter="updateChatHistoryName($event, chat)" type="text" label="" />
                                </div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <AssistantOffcanvas 
        @offcanvas-threads-load="loadThread" 
        @offcanvas-threads-set="setThread" 
        @offcanvas-threads-delete="deleteThread" 
        :threads="threads"
      />
      <div id="div-dynamic-content"></div>

    </div>
  
</template>
  
<script>
import _app from '@/App/App'
import _helper from '@/App/AppHelper'
import { ref } from 'vue';
import ModuleHeader from '@/App/components/Common/ModuleHeader'
//import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'
import AssistantOffcanvas from './AssistantOffcanvas'
import AssistantTopicsModal from './AssistantTopicsModal'
import CustomInstructionsModal from './CustomInstructionsModal'
import * as marked from 'marked'

const tsfLogo = require('../../assets/img/Logo-big.png');
const initialFlamingoGreeting = `Select a client and I'll turn into the assistant of your dreams ;)`;

export default {
  name: 'AssistantView',
  components: {
    ModuleHeader,
    //FilterBar,
    BaseField,
    AssistantOffcanvas,
  },
  props: {
    threadId: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      //data
      state: _app.$data.getState('AssistantFilter', { 
        customerId: null,
        starterPrompts: null,
        pmaFileId: null
      }),

      //gui
      chatEdit: false,
      currentChatId: '',
      flamingoGreeting: null,
      fixedRightWidth: 260,
      fullHeight: 0,
      fullWidth: 0,
      mainWidth: 0,
      mediumScreenWidth: 991,
      miniScreenWidth: 575.98,
      loading: false,
      offcanvasScreenWidth: 991.98,
      smallScreenWidth: 767.98,
      typewriterTextIndex: 0,
      typewriterTimerId: null,
      typewriterTop: -65,
      user: this.$store.state.auth.user,

      //chat specifics
      messages: null, //chat messages of current thread
      textarea: '', //prompt text
      newPrompt: decodeURIComponent(this.$route.query.prompt), //prompt that comes from a prompt generator route
      threads: [], //saved threads
      allThreads: [],
      pmaFiles: [], //available files from PowerMyAnalytics for users to ask questions about
      pmaInstructions: '',
      pmaFilesGet: true, //controling if we need to read files from OpenAi cloud in order to know what data was extracted from PowerMyAnalitics
      customerConfig: [],
      clientInstructions: '',
      filteringThreads: false,
      filterString: null,
    }
  },
  mounted() {
    window.addEventListener('resize', this.doLayout);

    if(this.state.customerId && this.newPrompt && this.newPrompt!=='' && this.newPrompt !== undefined && this.newPrompt !== 'undefined')
      this.textarea = this.newPrompt || '';
    else
      this.textarea = '';

    if(this.user.customerId)
      this.state.customerId = this.user.customerId;
    else
      this.state.customerId = this.$store.state.Customer.id;

    if(this.state.customerId) {
      this.setCustomerConfig(this.state.customerId);
      this.changeFlamingoGreeting();
    }
    else
      this.flamingoGreeting = initialFlamingoGreeting;

    this.typewriter(false);

    this.resizeTextarea();

    //setTimeout(() => {
      this.doLayout();

      document.querySelectorAll('[data-scroll-anchor="true"]').forEach(anchor => {
          anchor.addEventListener('click', function(event) {
              event.preventDefault();
              const targetId = this.getAttribute('href');
              const targetElement = document.querySelector(targetId);
              if (targetElement) {
                  targetElement.scrollIntoView({ behavior: 'smooth' });
              }
          });
      });

      this.assistantMsgWidth = this.getAssistantMsgWidth();

      this.focusPrompt();

      if(this.state.customerId) {
        this.clientInstructions = this.getClientAssistantInstructions(this.state.customerId);
        this.getThreads();
        //this.getPmaFileList(this.state.customerId);
      }

      /*if(!this.state.starterPrompts) {
        this.$app.$api.get('Reporting/GetAiPrompts').then(
          result => {
            try {
              this.state.starterPrompts = JSON.parse(JSON.stringify(result.data));
            }
            catch(err) {
              console.log(err);
            }
          },
          error => {
            this.$app.handleError(error);          
          }
        );
      }*/
    //}, 200);

    this.checkThreadId();
  },
  beforeUnmount() {
    window.removeEventListener("resize", function(){});
  },
  unmounted() {
    clearInterval(this.typewriterTimerId);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    },
    recentThreads() {
      return this.threads.filter(chat => {
        return chat.category == 'Recent';
      });
    },
    olderThreads() {
      return this.threads.filter(chat => {
        return chat.category == 'Older';
      });
    },
    ancientThreads() {
      return this.threads.filter(chat => {
        return chat.category == 'Ancient';
      });
    },
  },
  watch: {
    textarea(newVal) {
      this.resizeTextarea();
    },
    customerId(newCustomerId) {
      this.state.customerId = this.$store.state.Customer.id;
      this.changeCustomer();
    },
    $route(to, from) {
      this.checkThreadId();
    },
    filterString(newVal) {
      if(newVal=='')
        this.threads = this.allThreads;
      else
        this.threads = this.threads.filter(obj => {
                        return obj.threadName.toLowerCase().includes(newVal.toLowerCase());
                      }) || [];
    },
  },
  methods: {
    checkThreadId() {
      if (this.threadId && this.state.customerId) {
        setTimeout(() => {
          this.loadThread(this.threadId, true);
        }, 1000);
      }
    },
    getPmaFileList(customerId) {
      if(this.pmaFilesGet) {
        this.pmaFileId = null;
        this.pmaFiles = [];
        this.pmaInstructions = '';
      
        let _this = this;

        this.$app.$api.get('Assistant/GetFileList?purpose=assistants&customerId=' + _this.state.customerId).then(
          result => {
            try {
              let files = JSON.parse(JSON.stringify(result.data));
              for(let i=0; i<files.length; i++) {
                if(files[i].fileName.indexOf('pma.csv')>0) {
                  //_this.pmaFiles.push({id:i,name:files[i].fileName});

                  if(_this.pmaInstructions=='')
                    _this.pmaInstructions = ' ### CODE INTERPRETER AVAILABLE FILES ### Available files to analyze with Code interpreter: '
                  
                  _this.pmaInstructions += files[i].fileName + '; ';
                }
              }
            }
            catch(err) {
              _this.$app.handleError(err);
            }
          },
          error => {
            _this.$app.handleError(error);
          }
        );
      }
    },
    changeCustomer() {
      if(this.state.customerId) {
        this.setCustomerConfig(this.state.customerId);

        if(this.flamingoGreeting==initialFlamingoGreeting)
          this.changeFlamingoGreeting();

        this.clearChat();
        this.getThreads();
        this.clientInstructions = this.getClientAssistantInstructions(this.state.customerId);
        //this.getPmaFileList(this.state.customerId);
      }
      else {
        this.clearChat();
        this.flamingoGreeting = initialFlamingoGreeting;
        this.pmaFileId = null;
      }
    },
    changeFlamingoGreeting() {
      const now = new Date();
      const seconds = now.getSeconds();
      if(seconds<5)
        this.flamingoGreeting = `So, you've come here for some bird advice? Ok, what can I help you with?`;
      else if(seconds>=5 && seconds<10)
        this.flamingoGreeting = `Oh look, You, human! Need some wisdom from a bird brain today?`;
      else if(seconds>=10 && seconds<15)
        this.flamingoGreeting = `Welcome! Ready to admit you need help from a feathered friend?`;
      else if(seconds>=15 && seconds<20)
        this.flamingoGreeting = `Ah, seeking enlightenment? You've flown to the right place, apparently.`;
      else if(seconds>=20 && seconds<25)
        this.flamingoGreeting = `Here to pick my brain? Let's see what crumbs I have left for you.`;
      else if(seconds>=25 && seconds<30)
        this.flamingoGreeting = `If it isn't my favorite human! Ready for some flamingo philosophy?`;
      else if(seconds>=30 && seconds<35)
        this.flamingoGreeting = `Another seeker of avian enlightenment! What can I squawk about today?`;
      else if(seconds>=35 && seconds<40)
        this.flamingoGreeting = `Hey there! Want some bird-brained brilliance to brighten your day?`;
      else if(seconds>=40 && seconds<45)
        this.flamingoGreeting = `Ahoy, human! Looking for some pink-feathered profundity today?`;
      else if(seconds>=45 && seconds<50)
        this.flamingoGreeting = `Oh, it's You! Ready to hear the musings of a feathery genius?`;
      else if(seconds>=50 && seconds<55)
        this.flamingoGreeting = `Back for more? Let's see what pearls of wisdom I can peck at today.`;
      else
        this.flamingoGreeting = `Bet you didn't expect to take advice from a talking bird. Fire away!`;
    },
    doLayout() {
      const offcanvasElement = document.getElementById('assistantOffcanvas');
      if(offcanvasElement) {
        if(offcanvasElement.classList.contains('show')) {
          let offcanvasCloseBtn = document.getElementById('bz-offcanvas-close');
              offcanvasCloseBtn.click();
        }
      }

      this.fullWidth = ref(window.innerWidth);
      this.fullHeight = ref(window.innerHeight);
      
      if(this.$refs.bodyEl)
        this.mainWidth = this.$refs.bodyEl.clientWidth;
        
      this.getAssistantMsgWidth();
    },
    /*async downloadFile(fileId, fileType) {
      console.log('TODO download '+fileType+' => '+fileId);
    },*/
    getAssistantMsgWidth() {
      const textarea = this.$refs.promptTextarea;
      if (textarea)
        this.assistantMsgWidth = textarea.offsetWidth - ((this.fullWidth <= this.miniScreenWidth) ? 50 : 100);
      else
        this.assistantMsgWidth = this.smallScreenWidth;
    },
    getClientAssistantInstructions(customerId) {
      let config = JSON.parse(
                      this.$app.$data.customers.filter(obj => {
                        return obj.id==customerId;
                      })[0].config
                    ) || [];

      try {
        if (config.openai.assistantAnalyticsClientInstructions !== undefined)
          return config.openai.assistantAnalyticsClientInstructions;
      }
      catch(error) {
        return "";
      }
    },
    getCurrentDateFormatted() {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const now = new Date();

      const year = now.getFullYear();
      const month = months[now.getMonth()];
      const day = String(now.getDate()).padStart(2, '0');

      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    },
    getCurrentDateFormattedUtc() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    },
    getGuid() {
      function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    getMarkdownHtml(text) {
      return marked.parse(text);
    },
    getMarkdownHtmlFull(querySel) {
      const element = document.querySelector(querySel);
      if (element) {
        return marked.parse(element.innerHTML);
      }
      return '';
    },
    removeSpaces(str) {
      return str.replace(/\s/g, '');
    },
    resizeTextarea() {
      setTimeout(() => {
        const textarea = this.$refs.promptTextarea;
        if (textarea) {
          const maxHeight = parseInt(this.fullHeight/4);
          textarea.style.height = 'auto';
          textarea.style.overflow = 'hidden';
          textarea.offsetHeight;
          if( textarea.scrollHeight < maxHeight ) {
            textarea.style.height = `${textarea.scrollHeight}px`;
            this.typewriterTop = textarea.scrollHeight*(-1)-65;
          }
          else {
            textarea.style.height = `${maxHeight}px`;
            this.typewriterTop = maxHeight*(-1)-65;
          }
        }
      }, 100);
    },
    scrollToElement(element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Vertical alignment (start, center, end, or nearest)
        inline: 'nearest' // Horizontal alignment (start, center, end, or nearest)
      });
      /*const rect = element.getBoundingClientRect();
      const elementTop = rect.top + window.pageYOffset; // Adjusting for the current scroll position of the window
      window.scrollTo({
        top: (elementTop-100), // Adjust scroll position by the offset
        behavior: 'smooth'
      });*/
    },
    setCustomerConfig(customerId) {
      this.customerConfig = JSON.parse(
                              this.$app.$data.customers.filter(obj => {
                                return obj.id==customerId;
                              })[0].config
                            ) || [];
    },
    showIcons(event, toggle) {
      const button = event.target.closest('button'),
            icons = button.querySelectorAll('i.edit-icons');

      const displayStyle = toggle ? 'inline-block' : 'none';
      icons.forEach(icon => {
        icon.style.display = displayStyle;
      });
    },
    //CHAT START
    chatHistoryDelete(chat) {
      _app.$modal.confirm('Delete conversation', `Are you sure you want to delete "${chat.threadName}"?`, (confirmed) => {
        if (!confirmed) 
          return;

        this.filterString = '';
        this.filteringThreads = false;
        this.threads = this.allThreads;
        this.threads = this.threads.filter(obj => {
                        return obj.threadId!==chat.threadId;
                      });
        this.allThreads = this.threads;

        this.deleteThread(chat.threadId);
      });
    },
    chatHistoryEdit(chat) {
      this.chatEdit = true;

      this.$nextTick(() => {
        let input = document.getElementById(this.removeSpaces(chat.threadName));
        if(input) {
          setTimeout(() => {
            input.focus();
            const textValue = input.value;
            input.value = '';
            input.value = textValue;
            input.setSelectionRange(input.value.length, input.value.length);
          }, 100);
        }
      });
    },
    clearChat() {
      this.filteringThreads = false;
      this.filterString = '';
      this.messages = null;
      this.chatEdit = false;
      this.threads = this.threads.map(thread => {
        return { ...thread, isCurrent: false };
      });
      this.threads = this.allThreads;
      this.currentChatId = '';
      this.textarea = '';
      this.$nextTick(() => {
        this.resizeTextarea();
        this.focusPrompt();
      });
    },
    competitionAnalysis() {
      _app.$helper.notifyInfo('We are currently working on this amazing feature. Please, check it out in days to come...');
    },
    customInstructions() {
      this.$vfm.show({
        component: CustomInstructionsModal,
        bind: {
          'editRec': {instructions: this.clientInstructions || ''}
        },
        on: {
          ok: (data) => {
            if(data.isDirty) {
              let _this = this;
              
              if (_this.customerConfig && _this.customerConfig.openai) {
                _this.clientInstructions = data.instructions;
                _this.customerConfig.openai.assistantAnalyticsClientInstructions = data.instructions;
                pushToDb(_this.state.customerId, _this.customerConfig, _this);
              }
              else
                _app.$helper.notifyError("'openai' property is missing in customerConfig");
            }
          }
        }
      });

      function pushToDb(customerId, json, scope) {
        _app.$api.post('Customer/SetCustomerConfig?id='+customerId, json).then(result => {
          if( result.data && parseInt(result.data)>0 ) {
            _app.$helper.notifyInfo('Custom assistant instructions successfully updated.');
            _app.$data.getCustomers(true).then(data => {
              scope.customerConfig = scope.setCustomerConfig(customerId);

              let cusName = scope.$app.$helper.getCustomerName(customerId,data);
              scope.$store.dispatch('setAppCustomer', {id:customerId, name:cusName, config:JSON.stringify(json) });
            });
          }
        }, (error) => {
          _app.handleError(error);
        });
      }
    },
    deleteThread(threadId) {
      let _this = this,
          progress = _this.$progress.start();
      const _params = { threadId: threadId, customerId: _this.state.customerId };
        
        _this.$app.$api.get('Assistant/DeleteThread', { params: _params }).then(
        result => {
          //console.log(result.data);

          progress.finish();

          _this.threads = _this.threads.filter(obj => {
                            return obj.threadId!==threadId;
                          });
          _this.allThreads = _this.threads;

          if(threadId==_this.currentChatId)
            _this.clearChat();
        },
        error => {
          progress.finish();
          _this.$app.handleError(error);          
        }
      );
    },
    filterThreads() {
      _app.$helper.notifyInfo('Sorry, we are still working on this functionality. Filtering threads will be available soon.');
    },
    focusPrompt() {
      const textarea = this.$refs.promptTextarea;
      if(textarea)
        textarea.focus();
    },
    getFlamingoThinkging() {
      const now = new Date();
      const seconds = now.getSeconds();
      if(seconds<10)
        this.flamingoGreeting = `That's a tricky one, for sure, but I shall oblige, my friend.`;
      else if(seconds>=10 && seconds<20)
        this.flamingoGreeting = `New question? Lucky for you, I’ve got nothing but time and opinions.`;
      else if(seconds>=20 && seconds<30)
        this.flamingoGreeting = `Hmmm, diving deep today, aren’t we? Strap in, I’ll give it a whirl.`;
      else if(seconds>=30 && seconds<40)
        this.flamingoGreeting = `You trust a bird with this? Brave soul! Okay, I’ll take a crack at it.`;
      else if(seconds>=40 && seconds<50)
        this.flamingoGreeting = `Look at you, expecting genius from a bird. Well, I’ll do my best.`;
      else
        this.flamingoGreeting = `Oh, getting serious now? Alright, let me put on my thinking feathers.`;
    },
    getTextToType(index) {
      const texts = [
        `I read you loud and clear, just hang on tight, while I deliver.`,
        `Please, keep your patience. I'm going to major lengths for you...`,
        `Obtaining the most relevant information. It's tricky, guys!`,
        `No, your internet is not slow. It's not my best day, I guess...`,
        `Uf, it's frustrating not providing info fast enough. 3, 2, 1...`,
        `...voila! Do you see it? What, still nothing? Hm...`,
        'This is why no bird should be trusted with this job! Right?',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
        '... thinking ... calculating ... writing ...',
      ];
      return texts[index];
    },
    getThreadCategory(createdAt) {
      const dt = new Date(createdAt);
      const now = new Date();
      const sevenDaysAgo = new Date(now);
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const thirtyDaysAgo = new Date(now);
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      if(dt >= sevenDaysAgo)
        return 'Recent';
      else {
        if(dt >= thirtyDaysAgo)
          return 'Older';
        else
          return 'Ancient';
      }
    },
    getThreadName(threadId) {
      return this.threads.filter(obj => {
                return obj.threadId==threadId;
              })[0].threadName;
    },
    getThreads() {
      this.threads = [];

      this.$app.$api.get('Assistant/GetThreadList', {params:{customerId:this.state.customerId} }).then(
        result => {
          result.data.forEach(thread => {
            this.threads.push({
              threadId: thread.guid,
              threadName: thread.name,
              runId: null,
              runStatus: null,
              isCurrent: false,
              created: thread.created,
              category: this.getThreadCategory(thread.created),
            });
          });
          //console.log(this.threads);
          this.allThreads = this.threads;
        },
        error => {
          this.$app.handleError(error);          
        }
      );
    },
    handleTextareaKeyPress(event) {
      if (event.key === 'Enter') {
        if (event.shiftKey) {
          // If SHIFT + ENTER is pressed, insert a new line
          event.preventDefault();
          this.textarea += '\n';
          setTimeout(() => {
            this.resizeTextarea();
          }, 100);
        } else {
          // If only ENTER is pressed, prevent default and call sendMessage
          event.preventDefault();
          this.sendMessage();
        }
      }
    },
    loadNewImagesInThread(timeout) {
      let _this = this;
      setTimeout(() => {
        const imageAnswerDivs = document.querySelectorAll('div.imageAnswer');
        
        imageAnswerDivs.forEach(div => {
          const fileId = div.getAttribute('data-message-fileid'),
                fileType = div.getAttribute('data-message-filetype'),
                _params = { fileId:fileId, openAiMsgType:fileType, customerId: _this.state.customerId };
          let i=0;

          this.$app.$api.get('Assistant/DownloadFile',  { responseType:'blob', params:_params }).then(
            result => {
              const blob = result.data;
              if (!(blob instanceof Blob)) {
                const appMsg = "Response data is not a Blob for fileId="+fileId;
                //this.$app.handleError(appMsg);
                //console.log(appMsg);
              }
              else {
                const url = URL.createObjectURL(blob);
                const img = document.createElement('img');
                img.src = url;
                img.style.marginLeft = '-10px';
                img.style.width = '25%';
                //if(this.fullWidth>=this.mediumScreenWidth)
                //  img.style.width = '30%';
                //else
                //  img.style.width = '20%';
                div.appendChild(img);
              }

              div.classList.remove('imageAnswer');
              /*div.setAttribute('data-message-fileid', '');
              div.setAttribute('data-message-filetype', '');*/
            },
            error => {
              this.$app.handleError(error);
            }
          );
        });
        
        //remove empty image elements (this was due to some unknow bug, but i think it is no longer relevant)
        setTimeout(() => {
          document.querySelectorAll('img[src*="attachment:image"]').forEach(img => {
            img.remove();
            //const parentParagraph = img.closest('p');
            //if (parentParagraph) {
            //  parentParagraph.remove();
            //}
          });
        }, 200);
      }, timeout);
    },
    loadThread(threadId, forceReload=false) {
      if(this.currentChatId!==threadId || forceReload) {
        this.clearChat();
        this.currentChatId = threadId;
        for(let i=0; i<this.threads.length; i++) {
          if( this.threads[i].threadId==threadId ) {
            this.threads[i].isCurrent = true;
          }
        }

        let _this = this,
            progress = _this.$progress.start();
        const _params = { threadId: this.currentChatId, customerId: this.state.customerId };
        
        _this.$app.$api.get('Assistant/GetMessageList', { params: _params }).then(
          result => {
            progress.finish();

            let items = result.data.items,
                list = [];
            //console.log(items);
            
            for(let i=0; i<items.length; i++) {
              list.push({
                "id":items[i].id, "content":items[i].content, "role":items[i].role, "createdAt":items[i].createdAt, "createdAtUnix":items[i].createdAtUnixTimeSeconds
              });
            }
            list.sort((a, b) => {
              return a.createdAtUnix - b.createdAtUnix;
            });

            _this.messages = [];

            for(let i=0; i<list.length; i++) {
              let content = list[i].content;

              for(let j=0; j<content.length; j++) {
                if(content[j].text!==null) { // text answer was returned
                  let myString = content[j].text,
                      //myString = content[j].text.value,
                      placeholder = " #CLIENT SECTION START# ",
                      placeholder2 = " ### CLIENT SECTION ### ",
                      placeholderIndex = myString.indexOf(placeholder),
                      placeholderIndex2 = myString.indexOf(placeholder2);
                  
                  if(placeholderIndex>0)
                    myString = myString.substring(0, placeholderIndex);

                  if(placeholderIndex2>0)
                    myString = myString.substring(0, placeholderIndex2);

                  if(i==0) {
                    const d = list[i].createdAt.replace('T',' ');
                    myString = '<span style="font-size:smaller;">' + d + '</span><br />' + myString;
                  }

                  //fix broken markup
                  myString = myString.replace(/\(attachment:image\.png\)/g, '');

                  _this.messages.push({
                    //"id":list[i].id, "type":list[i].role, "msg":myString, "annotations":content[j].text.annotations, "fileId":null, "fileType":"bz-text", "createdAt":list[i].createdAt
                    "id":list[i].id, "type":list[i].role, "msg":myString, "annotations":content[j].textAnnotations, "fileId":null, "fileType":"bz-text", "createdAt":list[i].createdAt
                  });
                }
                //if(content[j].imageFile!==null) { // file was returned
                if(content[j].imageFileId!==null) { // file was returned
                  _this.messages.push({
                    //"id":list[i].id, "type":list[i].role, "msg":"", "annotations":null, "fileId":content[j].imageFile.fileId, "fileType":content[j].type, "createdAt":list[i].createdAt
                    "id":list[i].id, "type":list[i].role, "msg":"", "annotations":null, "fileId":content[j].imageFileId, "fileType":null, "createdAt":list[i].createdAt
                  });
                }
              }
            }

            //console.log(_this.messages);
            _this.loadNewImagesInThread(300);

            _this.doLayout();
          },
          error => {
            progress.finish();
            _this.$app.handleError(error);
          }
        );
      }
    },
    async printConversation() {
      let markdownHtml = new String();

      if(this.messages) {
        for(let i=0; i<this.messages.length; i++) {
          //markdownHtml += this.getMarkdownHtmlFull('div.chat-msg-id-'+this.messages[i].id);
          markdownHtml += this.getMarkdownHtmlFull(`[data-msgCount="${this.messages[i].id}-${i}"]`, null);
        }

        let html = ``; //<!DOCTYPE html><html><head><title>${this.getThreadName(this.currentChatId)}</title></head><body>`;
            html+= `<div style="float:right;"><img src="${tsfLogo}" title="Logo" style="width:133px; height:58px;" /></div>`;
            html+= `<h2 class="mb-0 pb-0">Flamingo Assistant Thread</h2>`;
            html+= `<p class="pb-3" style="font-size:10px;">Printed: ${this.getCurrentDateFormatted()}</p>`;
            //html+= `<p class="mt-10 pt-0" style="color:rgba(0,0,0,0.5); font-size:13px;">${this.getThreadName(this.currentChatId)}</p>`;
            html+= (markdownHtml) ? `<br />${markdownHtml}` : ``;
            //html+= `</body></html>`;
        
        const printWindow = window.open('', '_blank');
        //printWindow.document.write(html);
        printWindow.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <title>${this.getThreadName(this.currentChatId)}</title>
              <style>
                .assistant-image {
                  display: none;
                }
                div[data-author-message-role="User"] {
                  margin-top:40px;
                  margin-left:20px;
                  text-align:right;
                  font-weight:bolder;
                }
                img[src^="blob"] {
                  width:90% !important;
                }
              </style>
            </head>
            <body>
              ${html}
            </body>
          </html>
        `);
        setTimeout(() => {
          printWindow.document.close();
          printWindow.print();
        }, 200);
      }
      else
        _app.$helper.notifyInfo('You need to start a conversation in order to print it, right? ;)');
    },
    openPromptLibrary() {
      this.$router.push({ name: 'prompts' });
      /*let progress = this.$progress.start();
      this.$vfm.show({
        component: AssistantTopicsModal,
        bind: {
          'starterPrompts': this.state.starterPrompts,
          'popWidth': this.fullWidth-80,
          'popHeight': this.fullHeight-200,
          'progress': progress,
        },
        on: {
          ok: (prompt) => {            
            this.textarea = prompt;
            this.$nextTick(() => {
              this.resizeTextarea();
            });
          }
        }
      });*/
    },
    sendMessage() {
      let _this = this;

      if(_this.textarea!=='') {
        this.clientInstructions = (this.getClientAssistantInstructions(this.state.customerId)!=='') ? ' ### CLIENT SECTION ### '+this.getClientAssistantInstructions(this.state.customerId) : ' ### CLIENT SECTION ### No special instructions from the client.';

        let instructions = (this.state.customerId) ? this.clientInstructions + ((this.pmaInstructions && this.pmaInstructions!=='') ? + ' ' + this.pmaInstructions : '') : '',
            prompt = _this.textarea + instructions,
            now = new Date(),
            progress = _this.$progress.start();

        _this.typewriter(true);
        _this.typewriterTimerId = setInterval(function() {
          _this.typewriter(true);
        }, 5000);

        //new message
        if(!_this.messages) {
          _this.getFlamingoThinkging();
          _this.startThinking();
          
          let params = { 'customerId':_this.state.customerId, 'message':prompt };
          
          _this.$app.$api.post('Assistant/CreateAndRunThread', params).then(
            result => {
              progress.finish();
              _this.stopThinking();
              
              clearInterval(_this.typewriterTimerId);
              _this.typewriter(false);

              _this.textarea = '';
              _this.getAssistantMsgWidth();

              let res = result.data,
                  threadNameNew = res.threadName || ( prompt.length > 47 ? prompt.slice(0, 47) + '...' : prompt );

              threadNameNew = threadNameNew.indexOf('###') !== -1 ? threadNameNew.slice(0, threadNameNew.indexOf('###')-1) : threadNameNew;

              _this.threads.unshift({
                "threadId": res.threadId,
                "threadName": threadNameNew,
                "runId": res.runId,
                "runStatus": res.runStatus,
                "isCurrent": true,
                "created": now.toISOString().slice(0, 19),
                "category": "Recent",
              });
              _this.allThreads = _this.threads;

              _this.currentChatId = res.threadId;

              _this.loadThread(res.threadId, true);
            },
            error => {
              progress.finish();
              _this.stopThinking();
              _this.flamingoGreeting = `Ups, something went wrong. Don't worry, let's try again, shall we?`;
              clearInterval(_this.typewriterTimerId);
              _this.typewriter(false);
              _this.$app.handleError(error);
            }
          );
        }
        //add message to thread
        else {
          let params = { 'customerId':_this.state.customerId, 'threadId':_this.currentChatId, 'message':prompt };
  
          _this.$app.$api.post('Assistant/AddNewThreadMessage', params).then(
            result => {
              clearInterval(_this.typewriterTimerId);
              _this.typewriter(false);
              progress.finish();

              let myString = prompt,
                  placeholder = " ### CLIENT SECTION ### ",
                  placeholderIndex = myString.indexOf(placeholder);
                  
              if(placeholderIndex>0)
                myString = myString.substring(0, placeholderIndex);

              const d = _this.getCurrentDateFormattedUtc().replace('T',' ');
              myString = '<span style="font-size:smaller;">' + d + '</span><br />' + myString;

              _this.messages.push({
                  //"id":"msg_"+_this.getGuid(), "type":"User", "msg":myString, "annotations":null, "fileId":null, "fileType":null, "createdAt":_this.getCurrentDateFormattedUtc()
                  "id":"msg_"+_this.getGuid(), "type":"User", "msg":myString, "annotations":null, "fileId":null, "fileType":"bz-text", "createdAt":_this.getCurrentDateFormattedUtc()
              });
              _this.textarea = '';
              _this.getAssistantMsgWidth();

              let res = result.data;

              //update current thread status
              _this.threads = _this.threads.map(thread => {
                if (thread.isCurrent) {
                  return {
                    ...thread, 
                    runId: res.runId,
                    runStatus: res.runStatus,
                  };
                }
                return thread;
              });
              _this.allThreads = _this.threads;

              let items = res.result,
                  list = [],
                  lastMessageId = null;

              for(let i=0; i<items.length; i++) {
                list.push({
                  "id":items[i].id, "content":items[i].content, "role":"Assistant", "createdAt":items[i].createdAt, "createdAtUnix":items[i].createdAtUnixTimeSeconds
                });
              }
              list.sort((a, b) => {
                return a.createdAtUnix - b.createdAtUnix;
              });

              for(let i=0; i<list.length; i++) {
                
                let content = list[i].content;

                //console.log(content);
                for(let j=0; j<content.length; j++) {
                  if(content[j].text!==null) { // text answer was returned
                    _this.messages.push({
                      //"id":list[i].id, "type":list[i].role, "msg":content[j].text.value, "annotations":content[j].text.annotations, "fileId":null, "fileType":"bz-text", "createdAt":list[i].createdAt
                      "id":list[i].id, "type":list[i].role, "msg":content[j].text, "annotations":content[j].textAnnotations, "fileId":null, "fileType":"bz-text", "createdAt":list[i].createdAt
                    });
                  }
                  if(content[j].imageFile!==null && content[j].imageFileId!==null) { // file was returned
                    _this.messages.push({
                      //"id":list[i].id, "type":list[i].role, "msg":"", "annotations":null, "fileId":content[j].imageFile.fileId, "fileType":content[j].type, "createdAt":list[i].createdAt
                      "id":list[i].id, "type":list[i].role, "msg":"", "annotations":null, "fileId":content[j].imageFileId, "fileType":null, "createdAt":list[i].createdAt
                    });
                  }
                  lastMessageId = list[i].id;
                }
                //console.log(_this.messages);
              }

              setTimeout(() => {
                scrollMe();
              }, 100);

              function scrollMe() {
                _this.$nextTick(() => {
                  let scrollDiv = document.querySelector(`[data-msgId="chat-turn-${lastMessageId}"]`);
                  if(scrollDiv)
                    _this.scrollToElement(scrollDiv);
                  else {
                    setTimeout(() => {
                      scrollMe();
                    }, 500);
                  }
                });
              }

              _this.loadNewImagesInThread(300);

              _this.doLayout();
            },
            error => {
              progress.finish();
              _this.stopThinking();
              _this.flamingoGreeting = `Ups, something went wrong. Don't worry, let's try again, shall we...`;
              clearInterval(_this.typewriterTimerId);
              _this.typewriter(false);
              _this.$app.handleError(error);          
            }
          );
        }
      }
      else
        _app.$helper.notifyInfo('You should write some text before asking...');
    },
    setThread(payload) {
      let progress = this.$progress.start();
      this.$app.$api.post('Assistant/SetThread', payload).then(
        result => {
          progress.finish();
        },
        error => {
          progress.finish();
          this.$app.handleError(error);
        }
      );
    },
    showOffcanvas() {
      const linkElement = document.createElement("a");
            linkElement.textContent = "";
            linkElement.setAttribute("data-bs-toggle", "offcanvas");
            linkElement.href = "#assistantOffcanvas";
            linkElement.setAttribute("role", "button");
            linkElement.setAttribute("aria-controls", "assistantOffcanvas");
      
      document.getElementById("div-dynamic-content").appendChild(linkElement);
      linkElement.click();

      setTimeout(() => {
        linkElement.remove();
      }, 1000);
    },
    startThinking() {
      const image = document.getElementById('flamingo-thinker');
      if (image && !image.classList.contains('rocking'))
        image.classList.add('rocking');
    },
    stopThinking() {
      const image = document.getElementById('flamingo-thinker');
      if (image && image.classList.contains('rocking'))
        image.classList.remove('rocking');
    },
    typewriter(show) {
      let divId = 'divTypewriter',
          divElement = document.getElementById(divId),
          outputId = 'typewriterOutput',
          outputElement = document.getElementById(outputId),
          _this = this;
      
      outputElement.innerHTML = '';

      if (show) {
        if(_this.messages) {
          let msgId = _this.messages[(_this.messages.length-1)].id;
          let scrollDiv = document.querySelector(`[data-msgId="chat-turn-${msgId}"]`);
          if(scrollDiv)
            _this.scrollToElement(scrollDiv);
        }

        divElement.style.display = "block";
        let textToType = _this.getTextToType(_this.typewriterTextIndex);
        _this.typewriterTextIndex++;
        write(outputElement,textToType,0);
      }
      else {
        _this.typewriterTextIndex = 0;
        divElement.style.display = "none";
      }

      function write(el,text,index) {
        el.textContent = text.slice(0, index);
        if (index < text.length) {
          setTimeout(function() {
            write(el,text,(index+1));
          }, 30);
        }
      }
    },
    updateChatHistoryName(event, chat) {
      let input = document.getElementById(this.removeSpaces(chat.threadName)),
          _newValue = event.target.value;
      
      if(chat.threadName!==_newValue) {
        const payload = { 'guid':chat.threadId, 'name':_newValue, 'created':chat.created };
        
        this.setThread(payload);

        for(let i=0; i<this.threads.length; i++) {
          if( this.threads[i].threadId==chat.threadId ) {
            this.threads[i].threadName = _newValue;
            input.id = this.removeSpaces(_newValue);
          }
        }
      }

      this.chatEdit = false;
    },
    uploadFile() {
      _app.$helper.notifyInfo('Sorry, we are still working on this functionality. Upload file option will be available soon.');
    },
    //CHAT END
  },
};
</script>

<style scoped>
@media (max-width: 575px) {
	div.main-template-div,
  #div-left {
    padding-right: 0px !important;
  }
}
.thread-category {
  font-size: 0.8em;
  font-weight: 600;
}
.text-main {
  font-size: 0.9rem;
  line-height: 1.5em;
}
.btn-outline-secondary:hover {
  color: #2e3d53 !important;
  /*background: #2e3d53 !important;*/
  background: #f9f9f9 !important;
}
.bird-comment {
  /*position: absolute;
  top: 10px;
  left: 80px;*/
  cursor: pointer;
  /*background-color: #fea3b4;*/
  background-color: #A0DCF6;
  /*color: #ffffff;*/
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
/*.bird-comment::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #A0DCF6 transparent transparent;
}*/
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.z-0 {
  z-index: 0;
}
.w-full {
  width:100%;
}
.h-full {
  height: 100%;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.flex {
  display: flex;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-col {
  flex-direction: column;
}
.max-w-full {
  max-width: 100%;
}
.flex-1 {
  flex: 1 1 0%;
}
.grow {
  flex-grow: 1;
}
.flex-grow {
  flex-grow: 1;
}
.items-start {
  align-items: flex-start;
}
.sticky {
  position: sticky;
}
.left-0 {
  left: 0;
}
.right-0 {
 right: 0;
}
.top-0 {
 top: 0;
}
.z-10 {
 z-index: 10;
}
.z-20 {
 z-index: 20;
}
.transition-width {
  transition-duration: .15s;
  transition-property: width;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
/*.div-presentation {
  outline: none;
}*/
.div-presentation:focus-visible {
  outline: none;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-xs {
  font-size: .75rem;
  line-height: 1rem;
  opacity: 0.8;
}
.text-xxs {
  font-size: .7rem;
  line-height: 1rem;
  opacity: 0.8;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.p-1 {
  padding: .25rem;
}
.gap-1 {
  gap: .25rem;
}
.gap-2 {
  gap: .5rem;
}
.gap-3 {
  gap: .75rem;
}
.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem;
}
.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}
.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.resize-none {
  resize: none;
}
.grid-flow-row {
  grid-auto-flow: row;
}
.grid-cols-2 {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.min-w-0 {
  min-width: 0;
}
.items-end {
  align-items: flex-end;
}
.break-words {
  word-wrap: break-word;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.rounded-10 {
  border-radius: 10px;
}
.icon-active {
  color: rgb(46, 61, 83);
  font-size: large;
  cursor: pointer;
}
.rocking {
  animation: rock 0.5s ease-in-out infinite;
  display: block;
  margin: auto;
}
@keyframes rock {
  0%, 100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
.assistant-image {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 0px;
}

/* chat history */
.chat-full-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: '300px';
}
.btn-outline-transparent {
  margin-bottom: 10px;
  color: #2e3d53;
  width: 100%;
}
.btn-outline-transparent-active {
  border: 1px solid rgb(224 224 224);
  border-radius: 5px;
  margin-bottom: 10px;
  color: #2e3d53;
  width: 100%;
}
.btn-outline-transparent:hover {
  color: #2e3d53;
  background: rgba(224, 224, 224, 0.4);
  border-radius: 10px;
}
.btn-outline-transparent-hover:hover {
  color: #2e3d53;
}

.bzChatHistoryField {
  color:#2e3d53;
  width:100%;
  border:none;
}
.bzChatHistoryField:focus {
  outline: none;
  border-color: #2e3d53;
}

div.pages-info {
  display: inline-block !important;
  margin-left: 20px;
}

.btn-check:checked+label.btn-outline-primary, label.btn-outline-primary:hover {
  color: #262626 !important;
}

.floating-div {
  position: absolute;
  top: -10px;
  left: -5px;
  max-width: 40px;
  height: auto;
  padding: 10px;
  z-index: 999;
  /*opacity: 0.8;*/
}
/*
@media print {
  img.assistant-image {
    display: none !important;
  }
}
*/
</style>