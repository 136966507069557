<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">
        
      <ModuleHeader title="Marketing calendar" :subtitle="this.$store.state.Customer.name" :showBackButton="true" />

      <div class="row ps-4">
        <div class="col-lg-12">
            
          <div v-if="cusId">
              <!--
              <p class="p-text">
                Save important dates so that <code>Flamingo</code> can notify you about upcoming events. 
                The notifications will be automatically displayed in our <code>Dashboard section</code>.
              </p>
              -->
              <ModuleHeader title=" ">
                <button @click="addDate" class="btn btn-outline-secondary">Add date</button>
                <button @click="saveCalendar" class="btn btn-primary ms-2">Save changes</button>
              </ModuleHeader>
              <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" class="table-no-more" :style="(gridData.rows.length>0) ? 'min-height:300px' : ''" />
            </div>

            <div v-else class="text-center" style="margin-top:96px;">
              <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
              <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;">
                Select a client in order to access Marketing calendar functionality.
              </p>
            </div>
          </div>
      </div>

    </div>
  
</template>
  
<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import AddDateModal from './AddDateModal'

export default {
  name: 'MarketingCalendarView',
  /*props: {
    customerId: {
      type: String,
      required: true
    }
  },*/
  components: {
    ModuleHeader,
    BaseGrid,
  },
  data: function() {
    return {
      customerConfig: null,
      //cusId: this.customerId,
      cusId: null,
      user: this.$store.state.auth.user,

      gridData: {
        columns: [
          /*{
            title: '#',
            dataIndex: 'id'
          },*/
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Date',
            dataIndex: 'date',
            align: 'center'
          },
          {
            title: 'Category',
            dataIndex: 'category'
          },
          {
            title: 'Country',
            dataIndex: 'country_code'
          },
          // actions
          {
            width:140,
            align:'right',
            actions: [
              {
                text: 'Edit',
                handler: this.editDate
              },          
              {
                text: 'Remove',
                handler: this.removeDate
              }
            ]
          }
        ],
        rows: []
      },
    }
  },
  mounted() {
    //this.cusId = window.history.state.customerId;
    this.cusId = this.$store.state.Customer.id;

    if(this.cusId)
      this.initData(this.cusId);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.cusId = this.$store.state.Customer.id;
      this.initData(this.cusId);
    },
    customerConfig: {
      deep: true,
      handler(newConfig) {
        //console.log(newConfig);
      }
    }
  },
  methods: {
    addDate() {
      this.$vfm.show({
        component: AddDateModal,
        on: {
          ok: (data) => {
            this.updateCalendarGrid(data, true);
          }
        }
      });
    },
    editDate(rec) {
      this.$vfm.show({
        component: AddDateModal,
        bind: {
          'editRec': rec
        },        
        on: {
          ok: (data) => {
            this.updateCalendarGrid(data, false);
          }
        }
      });
    },
    initData(id) {
      if(id) {
        this.customerConfig = this.getCustomerConfig(id);

        if (this.customerConfig.marketingCalendar !== undefined) { //this should not happen!
          this.gridData.rows = this.customerConfig.marketingCalendar;
        }
      }
      else {
        this.customerConfig = null;
        this.gridData.rows = [];
      }
    },
    getCustomerConfig(id) {
      return JSON.parse(
                this.$app.$data.customers.filter(obj => {
                  return obj.id==id;
                })[0].config
              ) || null;
    },
    removeDate(rec) {
      _app.$modal.confirm('Remove date', `Are you sure you want to remove "${rec.name}" from your calendar?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1);
      });
    },
    saveCalendar() {
      let _this = this;

      if (this.customerConfig && this.customerConfig.marketingCalendar) {
        this.customerConfig.marketingCalendar = this.gridData.rows;
        pushToDb(this.cusId, this.customerConfig, _this);
      }
      else
        _app.$helper.notifyError("'marketingCalendar' property is missing in customerConfig");

      function pushToDb(customerId, json, scope) {
        _app.$api.post('Customer/SetCustomerConfig?id='+customerId, json).then(result => {
          if( result.data && parseInt(result.data)>0 ) {
            _app.$helper.notifyInfo('Marketing calendar successfully updated.');

            _app.$data.getCustomers(true).then(data => {
              scope.customers = data;
              scope.customerConfig = scope.getCustomerConfig(customerId);

              let cusName = scope.$app.$helper.getCustomerName(customerId,data);
              scope.$store.dispatch('setAppCustomer', {id:customerId, name:cusName, config:JSON.stringify(json) });
            });
          }
        }, (error) => {
          _app.handleError(error);
        });
      }
    },
    updateCalendarGrid(rec, isNew) {
      if(isNew) {
        rec.id = this.gridData.rows.reduce((max, item) => (item.id > max ? item.id : max), 0) + 1;
        this.gridData.rows.unshift({id:rec.id,name:rec.name,date:rec.date,category:rec.category,country_code:rec.country_code,every_year:true});
      }
      else {
        const index = this.gridData.rows.findIndex(item => item.id === rec.id);
        if (index !== -1)
          this.gridData.rows[index] = rec;
      }
    },
  },
};
</script>

<style scoped>
/*.p-text {
  color: #2e3d53;
  font-size: 13px;
}*/
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
</style>