import _app from '@/App/App'
import dayjs from 'dayjs';
//import Vue from 'vue'
import { createStore } from 'vuex'
import { auth } from "./auth.module";

const avatar = require('../assets/img/flamingo.svg');

const _helper = {

  formatUtcDate: function (date) {
    let format = 'DD.MM.YY HH:mm:ss',
        d = dayjs(date);
        d = d.add(d.utcOffset(), "minute");
    return d.format(format);  
  },

  /*getNewJsonState: function(tabs) {
    let dt = new Date(),
        dtStr = _app.$helper.formatDateToDb(dt);
        
    return JSON.parse(`
        {
          "ts": "${dtStr}",
          "tabs": ${JSON.stringify(tabs)}
        }
      `);
  },*/

};

const BASE_COLORS = ['#a8385d','#7ca5e6','#a27ea8','#a9e5f5','#adcced','#a85965','#8695bf','#7ed3ed','#50abcc','#ad6886'];
const BASE_TEXT_COLOR = '#7d7d7d';

const store = createStore({
    state: {
      apexCharts: {
        baseColors: BASE_COLORS,
        pieChartOptions: {
          chart: {
            animations: {
                enabled: false,
            },
            toolbar: {
                show: false
            },
            /*dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5
            },*/
          },
          title: {
            position: 'top',
          },
          legend: {
            position: 'right',
            offsetY: 20,
            labels: {
                colors: [BASE_TEXT_COLOR],
                useSeriesColors: false
            }
          },
          colors: BASE_COLORS,
          /*stroke: {
              colors: [BASE_TEXT_COLOR]
          },*/
          /*fill: {
              opacity: 0.8
          },*/
          noData: { text: 'No data' },
        },
        lineChartOptions: {
          chart: {
            animations: {
              enabled: false,
            },
            toolbar: {
              show: false
            },
            /*events: {
              mounted: (chart) => {
                chart.windowResizeHandler();
              }
            },*/
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight',
            width: 2,
          },
          /*grid: {
            row: {
              colors: BASE_COLORS,
              opacity: 0.5
            },
          },*/
          title: {
            position: 'top',
          },
          colors: BASE_COLORS,
          noData: { text: 'No data' },
        },
        polarAreaChartOptions: {
          chart: {
            animations: {
                enabled: false,
            },
            toolbar: {
                show: false
            },
          },
          title: {
            position: 'top',
          },
          legend: {
            position: 'right',
            offsetY: 20,
            labels: {
                colors: [BASE_TEXT_COLOR],
                useSeriesColors: false
            }
          },
          colors: BASE_COLORS,
          noData: { text: 'No data' },
        },
        barChartOptions: {
          chart: {
              animations: {
                  enabled: false,
              },
              toolbar: {
                  show: false
              },
              //height: 350,
          },
          title: {
            position: 'top'
          },
          colors: BASE_COLORS,
          /*xaxis: {
              labels: {
                  style: {
                      colors: [BASE_TEXT_COLOR]
                  }
              },
              axisBorder: {
                  color: BASE_TEXT_COLOR
              },
              axisTicks: {
                  color: BASE_TEXT_COLOR
              }
          },
          yaxis: {
              labels: {
                  style: {
                      colors: [BASE_TEXT_COLOR]
                  }
              },
              axisBorder: {
                  color: BASE_TEXT_COLOR
              },
              axisTicks: {
                  color: BASE_TEXT_COLOR
              }
          },*/
          legend: {
              show: false,
              /*position: 'top',
              horizontalAlign: 'left',
              offsetY: 0,
              offsetX: 60,
              showForSingleSeries: true,
              showForNullSeries: true,
              showForZeroSeries: true,
              labels: {
                  colors: [BASE_TEXT_COLOR]
              }*/
          },
          plotOptions: {
            bar: {
              horizontal: false,
              distributed: true,
              dataLabels: {
                position: 'top',
              },
            }
          },
          grid: {
              borderColor: BASE_TEXT_COLOR
          },
          noData: { text: 'No data' }
        }
      },
      beautifulChatInitConfig: {
        icons: [],
        participants: [
          {
            id: 'user1',
            name: 'user', //this.$store.state.auth.user.username,
            imageUrl: '', //'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
          },
          {
            id: 'user2',
            name: 'Flamingo',
            //imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
            imageUrl: require('../assets/img/flamingo.svg')
          }
        ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
        titleImageUrl: '', //'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
        //titleImageUrl: require('../../assets/img/flamingo.svg'),
        messageList: [
            /*{ type: 'text', author: `me`, data: { text: `Say yes!` } },
            { type: 'text', author: `user2`, data: { text: `No.` } }*/
        ], // the list of the messages to show, can be paginated and adjusted dynamically
        newMessagesCount: 0,
        isChatOpen: false, // to determine whether the chat window should be open or closed
        showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
        colors: {
          header: {
            //bg: '#E2849B', /*pink*/
            bg: '#A0DCF6', /*light blue*/
            //bg: '#2e3d53', /*dark blue*/
            //bg: 'rgb(46, 61, 83, 0.8)', /*dark blue with opacity*/
            //text: '#ffffff', /*white*/
            text: '#2e3d53', /*dark blue*/
          },
          launcher: {
            //bg: '#E2849B', /*pink*/
            bg: '#64a8ca', /*blue*/
          },
          messageList: {
            bg: '#ffffff'
          },
          sentMessage: {
            //bg: '#fea3b4',
            //text: '#ffffff'
            //bg: '#96e5ac',
            bg: 'rgb(150, 229, 172, 0.5)',
            text: '#303235',
          },
          receivedMessage: {
            bg: 'aliceblue',
            text: '#303235'
          },
          userInput: {
            bg: '#ffffff',
            text: '#303235'
          }
        }, // specifies the color scheme for the component
        alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
        messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      },
      Customer: { //global customer (for admin/agency users so they can switch between different customers
        id: null, 
        name: null,
        config: null,
        exists: function() {
          return (this.name) ? true : false;
        }
      },
      flowiseInitConfig: {
        //chatflowid: "00314b32-a6e0-4157-92f7-3a88ff874bfd",
        chatflowid: "",
        apiHost: "http://localhost:3000",
        chatflowConfig: {
          // topK: 2
        },
        theme: {
          button: {
            backgroundColor: "#E2849B",
            right: 20,
            bottom: 20,
            size: "large",
            //iconColor: "white",
            //customIconSrc: avatar,
          },
          chatWindow: {
            showTitle: true,
            title: 'FLAMINGO ASSISTANT',
            titleAvatarSrc: avatar,
            welcomeMessage: "Hi, I am Flamingo, how can I help?",
            backgroundColor: "#ffffff",
            height: 500,
            width: 400,
            fontSize: 14,
            //poweredByTextColor: "#303235",
            botMessage: {
              backgroundColor: "#f7f8ff",
              textColor: "#303235",
              showAvatar: true,
              avatarSrc: avatar,
            },
            userMessage: {
              backgroundColor: '#E2849B',
              textColor: '#ffffff',
              showAvatar: false,
              //avatarSrc: 'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
            },
            textInput: {
              placeholder: "Type your question",
              backgroundColor: "#ffffff",
              textColor: "#303235",
              sendButtonColor: "#E2849B",
              fontSize: 16,
            }
          }
        }
      },
      platforms: [
        {
          code:'GA', name:'Google Analytics GA4', img: require('@/assets/img/google-analytics.png'), 
          reports: [
            {id:'avgTimeByPage', name:'Avg. time on page', title:'Average Time on Page', descr:'The average duration (in seconds) on pages of users` sessions.', category:'pagePath', type:'polarArea', summary:'avg', summaryText:'sec. on page', limit:10},
            {id:'pageViewsByCountry', name:'Page views by country', title:'Audience by Location', descr:'The number of app screens or web pages your users viewed grouped by user country. Repeated views of a single page or screen are counted (screen_view + page_view events).', category:'country', type:'bar', summary:'sum', summaryText:'page views', limit:10},
            {id:'pageViewsByDeviceCategory', name:'Page views by device', title:'Devices', descr:'The number of app screens or web pages your users viewed grouped by user device. Repeated views of a single page or screen are counted (screen_view + page_view events).', category:'deviceCategory', type:'pie', summary:'sum', summaryText:'page views', limit:0},
            {id:'pageViewsByPage', name:'Most page views', title:'Most Page Views', descr:'The number of app screens or web pages your users viewed grouped by page name. Repeated views of a single page or screen are counted (screen_view + page_view events).', category:'pagePath', type:'barH', summary:'sum', summaryText:'page views', limit:10},
            {id:'sessionsByDay', name:'Sessions by day', title:'Sessions', descr:'The number of sessions per day that began on your site or app (event triggered: session_start).', category:'day', type:'line', summary:'sum', summaryText:'sessions', limit:0},
            
          ],
          kpis: [
            {kpiCode:'GA-averageSessionDuration', kpiLabel:'Avg. session', kpiIconCls:'fas fa-bolt', kpiDescription:'The average duration (in seconds) of users` sessions.'},
            {kpiCode:'GA-bounceRate', kpiLabel:'Bounce rate', kpiIconCls:'fas fa-person-through-window', kpiDescription:'The percentage of sessions that were not engaged ((Sessions Minus Engaged sessions) divided by Sessions). This metric is returned as a fraction; for example, 0.2761 means 27.61% of sessions were bounces.'},
            {kpiCode:'GA-pageViews', kpiLabel:'Page views', kpiIconCls:'fa fa-eye', kpiDescription:'The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).'},
            {kpiCode:'GA-sessions', kpiLabel:'Sessions', kpiIconCls:'fas fa-eye', kpiDescription:'The number of sessions that began on your site or app (event triggered: session_start).'},
          ],
        },
        {
          code:'FB', name:'Meta Graph - Facebook Insights', img: require('@/assets/img/facebook-analytics.png'), 
          reports: [
            {id:'kpiComparison', name:'Main KPIs compare', title:'Main KPIs compare', descr:'Comparison of main KPIs over time in a line chart', category:'counter', type:'line', summary:'sum', summaryText:'count', limit:0},
          ],
          kpis: [
            {kpiCode:'FB-fanCount', kpiLabel:'Fans', kpiIconCls:'fas fa-user', kpiDescription:'The number of users who like the Page. For Global Pages this is the count for all Pages across the brand. Can be read with Page Public Content Access or Page Public Metadata Access. For New Page Experience Pages, this field will return followers_count.'},
            {kpiCode:'FB-followersCount', kpiLabel:'Followers', kpiIconCls:'fas fa-user-plus', kpiDescription:'Number of page followers.'},
            {kpiCode:'FB-overallStarRating', kpiLabel:'Star rating', kpiIconCls:'fas fa-star-half-stroke', kpiDescription:'Overall page rating based on rating survey from users on a scale of 1-5. This value is normalized and is not guaranteed to be a strict average of user ratings. If there are 0 or a small number of ratings, this field will not be returned.'},
            {kpiCode:'FB-ratingCount', kpiLabel:'Rating count', kpiIconCls:'fas fa-star', kpiDescription:'Number of ratings for the Page (limited to ratings that are publicly accessible). Can be read with Page Public Content Access or Page Public Metadata Access.'},
          ],
        },
        {
          code:'IG', name:'Meta Graph - Instagram Insights', img: require('@/assets/img/instagram-analytics.png'), 
          reports: [],
          kpis: [],
        },
      ],
    },
    mutations: {
      FETCH_ETL_STATUS (state, payload) {
        state.Reporting.etl.dbStatus = payload.dbStatus;
        state.Reporting.etl.dbTimestamp = payload.dbTimestamp;
      },
      SET_CUSTOMER (state, payload) {
        state.Customer.id = payload.id;
        state.Customer.name = payload.name;
        state.Customer.config = payload.config;
      },
    },
    actions: {
      fetchEtlStatus({commit}, payload) { //get etl status info
        _app.$api.get('Dashboard/GetDashboardTable', { params: payload }).then(
          result => {
            const _res = result.data[0],
                  _data = {dbStatus:_res.etl_status, dbTimestamp:_helper.formatUtcDate(_res.etl_last_refresh)};
            commit("FETCH_ETL_STATUS", _data);
          },
          error => {
            _app.handleError(error);
          }
        );
      },
      setAppCustomer({commit}, payload) {
        commit("SET_CUSTOMER", payload);
      },
    },
    getters: {
        getScrollOffset(state) {
          return state.Reporting.scrollOffset;
        },
    },
    modules: {
        auth
    },
});

export default store;