<template>
  <td 
    :data-title="this.col.title"
    :style="getStyle()"
    :class="this.col.class"
    @click="this.$emit('cell-click', this.row, this.col, $event)"
    @contextmenu="this.$emit('cell-contextmenu', this.row, this.col, $event)"
    v-bind="$attrs"
  >
    <slot>
      <template v-if="!col.actions && col.type!=='ColorPicker' && col.type!=='ButtonGroup'">
        <div v-html="this.renderer()"></div>
      </template>      
      <template v-if="col.actions">
        <div class="text-nowrap">
          <BaseButton v-for="(action, index) in col.actions" :key="index" type="button" :text="action.text" @click.stop="onActionClick(action, row)"  class="btn btn-sm btn-outline-secondary ms-2" />
        </div>
      </template>
      <template v-if="col.type==='ColorPicker'">
        <div>
          <input type="color" :value="getValue()" @change="onValueChange" class="form-control-sm color-picker" />
        </div>
      </template>   
      <template v-if="col.type==='ButtonGroup'">
        <div class="btn-group align-items-center" @change="onValueChange">
          <template v-for="(btn, index) in col.buttons" :key="index">
            <input type="radio" class="btn-check" :name="this.id + '-' + col.dataIndex" :id="this.id + '_' + index" :value="btn.value" autocomplete="off" :checked="getValue()==btn.value">
            <label class="btn btn-sm btn-outline-secondary" :style="getItemStyle(btn)" :for="this.id + '_' + index">{{btn.text}}</label>
          </template>
        </div>
      </template>              
    </slot>    
  </td> 
</template>

<script>
  import BaseButton from '@/App/components/Common/BaseButton'
  
  export default {
    name: 'BaseGridCell',
    components: {
      BaseButton
    },    
    inheritAttrs: false,
    props: {
      col: Object,
      row: Object,
      cellAddDescr: String,
    },
    emits: ['cell-click', 'cell-contextmenu', 'value-change'],
    data() {
      return {
        id: this.$app.id()
      }
    },
    methods: {
      renderer() {

        let value  = this.getValue();
            value += ( (this.cellAddDescr) ? this.cellAddDescr : '' );
          
        if (typeof this.col.renderer === 'function')
          return this.col.renderer(value, this.row, this.col);
        else if (this.col.type == 'DateTime' && value) 
          return this.$app.formatDate(value, null, this.col.dateTimeKind);

        return value;
      },
      getValue() {

        let value = this.row[this.col.dataIndex];

        if (this.col.lookup) {
          let lookupValue = this.col.lookup[value];
          if (typeof lookupValue !== 'undefined')
            value = lookupValue;
        }

        return value;
      },
      getStyle() {
        
        let style = "";

        if (this.col.align)
          style += "text-align:" + this.col.align;
        else if (this.col.type == 'DateTime')
          style += "text-align:center;";

        if (this.col.style)
          style += ';' + this.col.style;

        return style;
      },
      getItemStyle(item) {
        let style = "";

        if (item.align)
          style += "text-align:" + item.align + ";";

        if (item.width)
          style += "width:" + item.width + "px;";

        if (item.style)
          style += item.style;

        return style;
      },
      onActionClick(action, row) {
        if (typeof action.handler === 'function')
          action.handler(row);
      },   
      onValueChange(e) {

        let value = e.target.value;

        if (value && this.col.valueType == 'int')
          value = parseInt(value);

        // eslint-disable-next-line vue/no-mutating-props
        this.row[this.col.dataIndex] = value;
        this.$emit('value-change', this.row, this.col, value);
      }  
    }
  }

</script>

<style scoped>

  .color-picker {
    height:10px;
    width: 50px;
    cursor:pointer;
    border-color:#dadada;
    background:transparent;
    vertical-align: bottom;
  }

  html.dark .color-picker {
    border-color:#2e353e;
  }
</style>

