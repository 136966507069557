<template>
  <sidebar-menu
    ref='sideBar'
    v-model:collapsed="collapsed"
    :menu="menu"
    :width="width" 
    :theme="theme"
    @update:collapsed="onToggleCollapse"
  >
    <template v-slot:toggle-icon>
      <i class="fa-solid fa-bars"></i>
    </template>
  </sidebar-menu>
  <div v-if="isOnMobile && !collapsed" class="sidebar-overlay" @click="setCollapsed(true)" />
</template>

<script>
// import { SidebarMenu } from 'vue-sidebar-menu'
import { SidebarMenu } from '@/App/components/SideBar'
import { markRaw } from 'vue'
import _app from '@/App/App'

const separator = {
  template: '<hr style="border-color: rgba(0, 0, 0, 0.1); margin: 20px;">'
}

const DEV_MODE = _app.isDevelopment && _app.debugModeEnabled;

const _user = _app.user;

//const _isLab22 = ( _user && _app.$data.Lab22Users.indexOf(_user.email) == -1 ) ? false : true;

const _isAdmin = _user && _user?.isAdmin();

export default {
  name: 'AppSideBar',
  components: {
    SidebarMenu
  },
  emits: ['setCollapsed', 'setIsOnMobile'],
  data() {    
    return {
      menu: [
        {
          header: 'CONTROL PANEL',
          hiddenOnCollapse: true,
          //hidden: !_isAdmin && !_isAnalyticsUser
        },
        {
          href: '/overview',
          title: 'Overview',
          icon: 'inf-sidebar-overview inf-sidebar-img',
          //icon: 'fa-solid fa-line-chart',
        },  
        {
          href: '/analytics',
          title: 'Analytics',
          icon: 'inf-sidebar-analytics inf-sidebar-img',
          //icon: 'inf-sidebar-overview inf-sidebar-img',
          //icon: 'fa-solid fa-line-chart',
        },
        {
          href: '/assistant',
          title: 'Flamingo assistant',
          icon: 'inf-sidebar-flamingo inf-sidebar-img',
          //icon: 'fa fa-robot',
        }, 
        /*{
          href: '/sentiments',
          title: 'Sentiment analysis',
          icon: 'inf-sidebar-aed inf-sidebar-img',
          //icon: 'fa-solid fa-heart',
          //hidden: !_isAdmin
          //hidden: !DEV_MODE || !_isAdmin
        },*/
        /*{
          href: '/emails',
          title: 'Email agents',
          //icon: 'fa fa-at',
          icon: 'inf-sidebar-envelope inf-sidebar-img',
        },*/
        {
          href: '/settings',
          title: 'Settings',
          icon: 'inf-sidebar-settings inf-sidebar-img',
          //icon: 'fa-solid fa-gears',
        }, 
        /*{
          component: markRaw(separator),
          //hiddenOnCollapse: true,
        },*/
        {
          header: 'Administration',
          hiddenOnCollapse: true,
          //hidden: !_isAdmin
          hidden: _user.isCustomerUser()
        },
        {
          href: '/audit-log',
          title: 'Audit log',
          icon: 'inf-sidebar-auditlog inf-sidebar-img',
          //icon: 'fa fa-list-ul',
          //hidden: !_isAdmin
          hidden: _user.isCustomerUser()
        },
        {
          href: '/users',
          title: 'Users',
          icon: 'inf-sidebar-users inf-sidebar-img',
          //icon: 'fa-solid fa-user-group',
          //hidden: !_isAdmin
          hidden: _user.isCustomerUser()
        },
        {
          href: '/roles',
          title: 'Roles',
          icon: 'inf-sidebar-roles inf-sidebar-img',
          //icon: 'fa-solid fa-user-lock',
          //hidden: !_isAdmin
          hidden: _user.isCustomerUser()
        }, 
        {
          component: markRaw(separator),
          hidden: !DEV_MODE
        },            
        {
          href: '/profile',
          title: 'Profile',
          icon: 'fa fa-solid fa-address-card',
          hidden: !DEV_MODE || !_isAdmin
        },            
        {
          component: markRaw(separator),
          hiddenOnCollapse: true,
        }, 
        {
          href: '/about',
          title: 'About',
          linkClass: 'margin-left:50px;',
          hiddenOnCollapse: true,
        },
        {
          href: '/sources',
          title: 'Data sources',
          linkClass: 'margin-left:50px;',
          hiddenOnCollapse: true,
        },
      ],
      width: '250px',
      collapsed: true,
      isOnMobile: false,
      theme: _app.getTheme(),
      showChild: true
    }
  },
  mounted () {    
    // console.log(this.$refs.sideBar)
      // this.$refs.sideBar.sidebarMenuRef
      // vsm--link_active
      // ul.vsm--menu li.vsm--item a.vsm--link.vsm--link_active
    this.onResize();
    if (!this.collapsed && this.userCollapsed()) {
        this.setCollapsed(true);
    }
    window.addEventListener('resize', this.onResize);
  },
  // watch: {
  //   $route() {
  //     console.log('route change', this.$route.path);
  //     if (this.$route.path.startsWith('/devices'))
  //       console.log('devices')
  //   }
  // },     
  methods: {
    userCollapsed() {
        return JSON.parse(localStorage.getItem('SIDENAV_COLLAPSED')) === true;
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.setIsOnMobile(true);
        this.setCollapsed(true);
      } else {
        this.setIsOnMobile(false);        
        this.setCollapsed(this.userCollapsed());
      }
    },
    onToggleCollapse (collapsed) {
      localStorage.setItem('SIDENAV_COLLAPSED', collapsed);
      this.setCollapsed(collapsed);
    },
    setCollapsed(collapsed) {
      this.collapsed = collapsed;
      this.$emit('setCollapsed', collapsed);
    },
    setIsOnMobile(isOnMobile) {
      this.isOnMobile = isOnMobile;
      this.$emit('setIsOnMobile', isOnMobile);
    }
  }  
}
</script>

<style>

#main-view.sidebar {
  padding-left: 250px;
  transition: 0.3s ease;
}

#main-view.sidebar.collapsed {
  padding-left: 65px;
}

#main-view.sidebar.onmobile {
  padding-left: 65px;
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*background-color: #000;*/
  opacity: 0.5;
  z-index: 900;
}

.v-sidebar-menu {
  margin-top: 60px;
  padding-top: 10px;  
}

.v-sidebar-menu.vsm_white-theme {
  border-right: 1px solid rgb(216, 222, 228);
}

.v-sidebar-menu .vsm--toggle-btn {  
  height:50px !important;
}

.v-sidebar-menu .vsm--mobile-bg {
  z-index:20 !important;
}

.v-sidebar-menu.vsm_white-theme .vsm--icon {
  color: #2e353e;
}
</style>