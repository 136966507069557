<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Custom instructions" v-bind="$attrs" :width="350" @close="cancel">
    <template #body>
      <p>When you enter custom instructions for Flamingo they will be added to every new thread that you initiate.</p> 
      <textarea class="form-control" ref="txtArea" v-model="instructions" name="userInstructions" id="textareaInstructions" rows="8" placeholder="Type instructions"></textarea>
      <div class="form-group col-12 text-1 mb-0 mt-1 ms-2">
        {{ characterCounter }}
      </div>
    </template>
    <template #footer>
      <button @click="cancel" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-secondary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>
import BaseModal from '@/App/components/Modal/BaseModal';
//import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'CustomInstructionsModal',
  props: {
    editRec: Object,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    //FormField,
    Form,
  }, 
  data() {
    return {
      modalName: 'CustomInstructionsModal',
      schema: {
        
      },
      instructions: '',
      data: {isDirty:false, instructions:''},
      textareaEl: null,
      maxInstructionsLen: 1000,
      characterCounter: '',
    };
  },
  mounted() {
    setTimeout(() => {
      this.textareaEl = document.getElementById('textareaInstructions');
      this.textareaEl.addEventListener('input', this.checkInstructionsLength);
      const text = this.textareaEl.value;
      this.characterCounter = text.length.toString()+'/'+this.maxInstructionsLen.toString();
    }, 100);

    if (this.editRec)
      this.instructions = this.editRec.instructions;
  },
  beforeUnmount() {
    this.textareaEl.removeEventListener("input", this.checkInstructionsLength);
  },
  methods: {
    checkInstructionsLength() {
      const text = this.textareaEl.value,
            len = (text.length>this.maxInstructionsLen) ? this.maxInstructionsLen : text.length;

      this.characterCounter = len.toString()+'/'+this.maxInstructionsLen.toString();

      if(text.length >= this.maxInstructionsLen)
        this.instructions = this.textareaEl.value.substring(0, this.maxInstructionsLen);
    },
    onSubmit(values) {
      this.data.isDirty = true;
      this.data.instructions = this.instructions.substring(0, this.maxInstructionsLen); //values.userInstructions;

      this.$emit('ok', this.data);
      this.$vfm.hide(this.modalName);
    },
    cancel() {
      this.data.isDirty = false;
      this.data.instructions = null;

      this.$emit('ok', this.data);
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>