<template>
  <div class="offcanvas offcanvas-end" data-bs-backdrop="true" data-bs-scroll="true" tabindex="-1" id="menuOffcanvas" aria-labelledby="menuOffcanvasLabel" style="width:75%;">
    <div class="offcanvas-header">
      <button type="button" id="bz-offcanvas-close-menu" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <ul class="list-unstyled mb-2" style="font-size:1.5rem !important; color:#2e3d53 !important;">
        <li v-show="!isCustomerUser" class="mb-4">
          <a role="menuitem" @click="selectCustomer" tabindex="-1" style="cursor:pointer !important;">Select client</a>
        </li>
        <li class="mb-2">
          <a role="menuitem" @click="routeMe('overview')" tabindex="-1" style="cursor:pointer !important;">Overview</a>
        </li>
        <li class="mb-2">
          <a role="menuitem" @click="routeMe('analytics')" tabindex="-1" style="cursor:pointer !important;">Analytics</a>
        </li>
        <li class="mb-2">
          <a role="menuitem" @click="routeMe('assistant')" tabindex="-1" style="cursor:pointer !important;">Flamingo assistant</a>
        </li>
        <li class="mb-4">
          <a role="menuitem" @click="routeMe('settings')" tabindex="-1" style="cursor:pointer !important;">Settings</a>
        </li>
        <!--<li class="divider"></li>-->
        <li v-show="!isCustomerUser" class="mb-2">
          <a role="menuitem" @click="routeMe('audit-log')" tabindex="-1" style="cursor:pointer !important;">Audit log</a>
        </li>
        <li v-show="!isCustomerUser" class="mb-2">
          <a role="menuitem" @click="routeMe('users')" tabindex="-1" style="cursor:pointer !important;">Users</a>
        </li>
        <li v-show="!isCustomerUser" class="mb-4">
          <a role="menuitem" @click="routeMe('roles')" tabindex="-1" style="cursor:pointer !important;">Roles</a>
        </li>
        <li class="mb-2">
          <a role="menuitem" @click="routeMe('about')" tabindex="-1" style="cursor:pointer !important;">About</a>
        </li>
        <li class="mb-4">
          <a role="menuitem" @click="routeMe('sources')" tabindex="-1" style="cursor:pointer !important;">Data Sources</a>
        </li>
        <!--<li class="divider"></li>-->
        <li class="mb-2">
          <a role="menuitem" @click="changePassword" tabindex="-1" style="cursor:pointer !important;">Change password</a>
        </li>
        <li class="mb-2">
          <a role="menuitem" @click="logOut" tabindex="-1" style="cursor:pointer !important;">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _app from '@/App/App'
import ChangePasswordModal from '@/Modules/User/ChangePasswordModal'
import ChangeCustomerModal from '@/Modules/Settings/ChangeCustomerModal'

export default {
  name: 'MenuOffcanvas',
  props: {
    
  },
  components: {
    
  },
  emits: ['offcanvas-menu-closed'],
  data() {    
    return {
      isCustomerUser: null
    }
  },
  mounted () {
    this.isCustomerUser = this.checkUser();
  },
  methods: {
    routeMe(routeName) {
      this.closeMenu();
      this.$router.push({ name: routeName });
    },
    closeMenu() {
      let offcanvasCloseBtn = document.getElementById('bz-offcanvas-close-menu');
      offcanvasCloseBtn.click();
      this.$emit('offcanvas-menu-closed');
    },
    logOut() {
      this.$app.logOut();
    },
    changePassword() {
      this.closeMenu();
      this.$vfm.show({
        component: ChangePasswordModal,
        bind: {
          'editRec': this.$store.state.auth.user
        },
        on: {
          ok: (data) => {
            this.$app.$helper.notify('Password was successfully changed.', 'info', 3000);
          }
        }
      });
    },
    checkUser() {
      if(this.$app.user)
        return this.$app.user.isCustomerUser();
      else
        return false;
    },
    selectCustomer() {
      this.closeMenu();
      this.$vfm.show({
        component: ChangeCustomerModal,
        bind: {
          'editRec': this.$store.state.Customer
        },
        on: {
          ok: (data) => {
            if(data.id) {
              let cusConfig = this.$app.$helper.getCustomerConfig(data.id,this.$app.$data.customers);
              this.$store.dispatch('setAppCustomer', {id:data.id, name:data.name, config:cusConfig });
            }
            else
              this.$store.dispatch('setAppCustomer', {id:null, name:null, config:[] });
          }
        }
      });
    },
  }  
}
</script>

<style scoped>

</style>