<template>
  
  <div class="login-background">
    <section :style="'width:' +sectionWidth+';'">
      <div class="container vh-100 py-5 d-flex justify-content-center align-items-center" style="color:#2e3d53;">
        <div class="row">
          <div class="col-12 text-center">
              
            <img src="@/assets/img/Logo-big.png" title="Logo" style="width:266px; height:116px;" />
            
            <h1 class="card-title pt-3 pb-5 d-none d-lg-block" style="font-size: 1.5rem;">The Social Formula to make your life easier</h1>
            <h1 class="card-title pt-3 pb-5 d-block d-lg-none" style="font-size: 1.5rem;">To make your life easier</h1>

            <h2 class="card-title pt-0 pb-3" style="font-size: 1.2rem;">Login</h2>
            
            <Form @submit="handleLogin" :validation-schema="schema" style="max-width:350px; margin:0 auto;">
              <div class="form-group">
                <!--<label for="username">Username</label>-->
                <Field name="username" type="text" label="Username" placeholder="Username" autocomplete="on" class="form-control alter-autocomplete" />
                <ErrorMessage name="username" class="error-feedback" />
              </div>
              <div class="form-group">
                <!--<label for="password">Password</label>-->
                <Field name="password" type="password" label="Password" placeholder="Password" autocomplete="on" class="form-control alter-autocomplete" />              
                <ErrorMessage name="password" class="error-feedback" />
              </div>
              <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
              </div>
              <!--<p class="mt-2 text-center"><a href="#!" style="color:#2e3d53;">Forgot password?</a></p>-->
              <div class="form-group">
                <button class="btn btn-primary btn-block" :disabled="loading">
                  <span>Let's go</span>
                </button>
              </div>
            </Form>

          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "LoginPage",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: "",
      schema: {
        username: 'required',
        password: 'required'
      },
      sectionWidth:300,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  mounted() {
    this.doLayout();
    window.addEventListener('resize', this.doLayout);
  },
  beforeUnmount() {
    window.removeEventListener("resize", function(){});
  },
  methods: {
    doLayout() {
      const ratio = 58.333333/100,
            width = window.innerWidth;
      if(width>768)
        this.sectionWidth = (width*ratio - 5) + 'px';
      else
        this.sectionWidth = '100%';
    },
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.go("/");
        },
        (error) => {
          this.loading = false;
          this.message = error.response?.status == 401 ? 'Login Failed: Your username or password is incorrect' : error.response?.data?.message || error.message || error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.login-background {
  background-image: url('../assets/img/welcome-screen2.png');
  background-position: center;
  background-size: cover;
  height: 100vh; /* Full viewport height */
  width: 100%;
  background-color: #FAFAFA;
}

@media (max-width: 767.98px) { /* bootstrap medium screen */
  .login-background {
    background-size: 171.42857241% auto; /* (100/58.333333 * 100) to cover 58.333333% of the width */
    background-position: left;
  }
}
.card {
  margin-top:20px;
  width:300px;
}

.alert-danger {
  margin-top: 20px;
}

.alter-autocomplete:placeholder {
  background-color: #fff;
}
.alter-autocomplete:-ms-input-placeholder {
  background-color: #fff;
}
.alter-autocomplete:-ms-input-placeholder {
  background-color: #fff;
}
</style>