<template>  
  <div class="filterbar">
    <Form @submit="submit" :initial-values="this.state" class="d-flex">
      <label v-if="!hideFilterByLabel">{{ labelText || 'Filter by' }}</label>
      <slot :state="this.state"></slot>           
      <button v-if="showReload" type="submit" :disabled="loading" class="btn icon-btn ms-1" :class="getReloadBtnClass()">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <i v-show="!loading" class="fa-solid fa-rotate-right"></i>
        <!-- <i v-show="!loading" class="fa-solid fa-magnifying-glass"></i> -->
      </button>
      <button v-if="showReset" type="button" @click="reset()" class="btn d-none d-md-block ms-1" :class="secondaryColors ? 'btn-default': 'btn-outline-secondary'">Reset</button>    
      <div v-if="this.$slots.end" class="flex-grow-1"></div>
      <slot name="end"></slot>      
    </Form>
  </div>
</template>

<script>
  import _app from '@/App/App.js';
  import { Form } from "vee-validate";

  export default {
    name: 'FilterBar',
    props: {
      filterState: Object,
      initialValues: Object, 
      showReload: Boolean,
      showReset: Boolean,
      labelText: String,
      loading: Boolean,
      hideFilterByLabel: Boolean,
      secondaryColors: Boolean,
      dirty: Boolean,
      autoReloadOnReset: {
        type: Boolean,
        default: false       
      }
    },
    emits: ['filterChange', 'reload', 'reset'],
    components: {
      Form    
    },    
    data() {
      return {
        state: Object.assign(this.filterState || {}, this.initialValues)
      }
    },    
    watch: { 
      state: {
        handler() {
          this.$emit('filterChange', this.state);  
        },
        deep: true
      }
    },
    methods: {
      reset(values, silent) {

        _app.$helper.clearObject(this.state);

        if (values || this.initialValues)
          Object.assign(this.state, values || this.initialValues);

        if (silent !== true) {
          this.$emit('reset', this.state);
          if (this.autoReloadOnReset)
            this.submit();
        }
          
      },
      submit() {

        _app.$helper.removeEmptyProps(this.state);

        this.$emit('reload', this.state);
      },
      getReloadBtnClass() {
        
        let cls = 'btn-secondary';

        if (this.dirty)
          cls = 'btn-secondary';
        else if (this.secondaryColors) 
          cls = 'btn-secondary';

        return cls;
      }
    },
  }
</script>

<style scoped>

form > * {
  margin-right: 5px;
}

.filterbar {
  border-top: 1px solid rgb(222, 226, 230);
  border-bottom: 1px solid rgb(222, 226, 230);
  padding: 10px 0 10px 5px;
  margin-bottom: 10px;
}

</style>

<style>
.filterbar label {
  padding-right: 5px;
  font-size:14px;
  color: #212529 !important; 
  line-height:38px;
  white-space: nowrap;
  vertical-align: middle;
}
</style>