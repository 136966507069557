<template>
  <div class="offcanvas offcanvas-end" data-bs-backdrop="false" data-bs-scroll="true" tabindex="-1" id="assistantOffcanvas" aria-labelledby="assistantOffcanvasLabel" style="width:270px;">
    <div class="offcanvas-header">
      <p class="offcanvas-title" id="assistantOffcanvasLabel" style="padding-bottom:0px !important; margin-top: 0px !important; font-weight:500; font-size:16px;">
        Recent threads
        <i @click="filteringThreads=!filteringThreads" class="fa fa-search icon-active ms-5 mt-1" title="Filter threads" style="cursor:pointer;"></i>
      </p>
      <button type="button" id="bz-offcanvas-close" @click="chatEdit=false" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body" style="padding-top:0px !important;">
      <div v-if="recentThreads.length > 0">
        <h5 class="thread-category">Recent (Last 7 days)</h5>
        <li v-for="chat in recentThreads" :key="chat.threadId" style="height:40px;">
          <button type="button" class="btn btn-outline-transparent" @mouseover="showIcons($event, true)" @mouseout="showIcons($event, false)" :style="(chat.threadId==currentChatId) ? 'background:rgb(224,224,224,0.4); border: 1px solid #e0e0e0; border-radius:15px;' : ''">
            <div class="row">
              <div v-show="!chatEdit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="loadThread(chat.threadId)" :title="chat.threadName" style="padding-left:10px !important; padding-right:0px !important; font-size:13px !important;">{{ chat.threadName }}</div>
              <div v-show="!chatEdit" class="col-3 text-end btn-outline-transparent-hover">
                <i @click="chatHistoryEdit(chat)" class="fa fa-edit edit-icons" style="padding-right:3px; display:none;" title="Rename"></i>
                <i @click="chatHistoryDelete(chat)" class="fa fa-trash edit-icons" style="display:none;" title="Delete"></i>
              </div>
              <div v-show="chatEdit" class="col-12 btn-outline-transparent-hover text-start">
                <input class="bzChatHistoryField" :id="removeSpaces(chat.threadName)" :value="chat.threadName" @blur="chatEdit=false" @keyup.enter="updateChatHistoryName($event, chat)" type="text" label="" />
              </div>
            </div>
          </button>
        </li>
      </div>
      <div v-show="filteringThreads" class="flex flex-col gap-2 pt-2 pb-2 text-sm">
        <BaseField v-model="filterString" name="filterString" placeholder="Search by name..." style="max-width:180px; height:20px; font-size:small;" />
        <div @click="filterString=''" style="position:relative; margin-top:-30px; margin-left:157px; font-weight:bold; cursor:pointer; font-size:small">X</div>
      </div>
      <div v-if="olderThreads.length > 0">
        <h5 class="thread-category">Older (8 - 30 days)</h5>
        <li v-for="chat in olderThreads" :key="chat.threadId" style="height:40px;">
          <button type="button" class="btn btn-outline-transparent" @mouseover="showIcons($event, true)" @mouseout="showIcons($event, false)" :style="(chat.threadId==currentChatId) ? 'background:rgb(224,224,224,0.4); border: 1px solid #e0e0e0; border-radius:15px;' : ''">
            <div class="row">
              <div v-show="!chatEdit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="loadThread(chat.threadId)" :title="chat.threadName" style="padding-left:10px !important; padding-right:0px !important; font-size:13px !important;">{{ chat.threadName }}</div>
              <div v-show="!chatEdit" class="col-3 text-end btn-outline-transparent-hover">
                <i @click="chatHistoryEdit(chat)" class="fa fa-edit edit-icons" style="padding-right:3px; display:none;" title="Rename"></i>
                <i @click="chatHistoryDelete(chat)" class="fa fa-trash edit-icons" style="display:none;" title="Delete"></i>
              </div>
              <div v-show="chatEdit" class="col-12 btn-outline-transparent-hover text-start">
                <input class="bzChatHistoryField" :id="removeSpaces(chat.threadName)" :value="chat.threadName" @blur="chatEdit=false" @keyup.enter="updateChatHistoryName($event, chat)" type="text" label="" />
              </div>
            </div>
          </button>
        </li>
      </div>
      <div v-if="ancientThreads.length > 0">
        <h5 class="thread-category">Ancient (More than 30 days)</h5>
        <li v-for="chat in ancientThreads" :key="chat.threadId" style="height:40px;">
          <button type="button" class="btn btn-outline-transparent" @mouseover="showIcons($event, true)" @mouseout="showIcons($event, false)" :style="(chat.threadId==currentChatId) ? 'background:rgb(224,224,224,0.4); border: 1px solid #e0e0e0; border-radius:15px;' : ''">
            <div class="row">
              <div v-show="!chatEdit" class="col-9 btn-outline-transparent-hover text-start chat-full-text" @click="loadThread(chat.threadId)" :title="chat.threadName" style="padding-left:10px !important; padding-right:0px !important; font-size:13px !important;">{{ chat.threadName }}</div>
              <div v-show="!chatEdit" class="col-3 text-end btn-outline-transparent-hover">
                <i @click="chatHistoryEdit(chat)" class="fa fa-edit edit-icons" style="padding-right:3px; display:none;" title="Rename"></i>
                <i @click="chatHistoryDelete(chat)" class="fa fa-trash edit-icons" style="display:none;" title="Delete"></i>
              </div>
              <div v-show="chatEdit" class="col-12 btn-outline-transparent-hover text-start">
                <input class="bzChatHistoryField" :id="removeSpaces(chat.threadName)" :value="chat.threadName" @blur="chatEdit=false" @keyup.enter="updateChatHistoryName($event, chat)" type="text" label="" />
              </div>
            </div>
          </button>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import _app from '@/App/App'
import BaseField from '@/App/components/Form/BaseField'

const closeEvent = 'offcanvas-threads-close',
      loadEvent = 'offcanvas-threads-load',
      setEvent = 'offcanvas-threads-set',
      deleteEvent = 'offcanvas-threads-delete';

export default {
  name: 'AssistantOffcanvas',
  props: {
    threads: {
      type: Array,
      default: () => []
    },
  },
  components: {
    BaseField,
  },
  emits: [closeEvent, loadEvent, setEvent, deleteEvent],
  data() {    
    return {
      chatEdit: false,
      currentChatId: '',
      localThreads: [],
      allThreads: [],
      filteringThreads: false,
      filterString: null,
    }
  },
  mounted () {
    this.localThreads = this.threads;
    this.allThreads = this.threads;
  },
  unmounted () {
    //this.currentChatId = '';
  },
  watch: {
    threads: {
      deep: true,
      handler(newThreads) {
        this.localThreads = newThreads;
        for(let i=0; i<this.localThreads.length; i++) {
          if(this.localThreads[i].isCurrent)
            this.currentChatId = this.localThreads[i].threadId;
        }
      }
    },
    filterString(newVal) {
      if(newVal=='')
        this.localThreads = this.allThreads;
      else
        this.localThreads = this.localThreads.filter(obj => {
                              return obj.threadName.toLowerCase().includes(newVal.toLowerCase());
                            }) || [];
    },
  },
  computed: {
    recentThreads() {
      return this.localThreads.filter(chat => {
        return chat.category == 'Recent';
      });
    },
    olderThreads() {
      return this.localThreads.filter(chat => {
        return chat.category == 'Older';
      });
    },
    ancientThreads() {
      return this.localThreads.filter(chat => {
        return chat.category == 'Ancient';
      });
    },
  },
  methods: {
    chatHistoryDelete(chat) {
      _app.$modal.confirm('Delete conversation', `Are you sure you want to delete "${chat.threadName}"?`, (confirmed) => {
        if (!confirmed) 
          return;

        this.filterString = '';
        this.filteringThreads = false;
        this.localThreads = this.allThreads;
        this.localThreads = this.localThreads.filter(obj => {
                                  return obj.threadId!==chat.threadId;
                              });
        this.allThreads = this.localThreads;

        this.$emit(deleteEvent, chat.threadId);

        if(this.localThreads.length==0)
          this.closeOffcanvas();
      });
    },
    chatHistoryEdit(chat) {
      this.chatEdit = true;

      this.$nextTick(() => {
        let input = document.getElementById(this.removeSpaces(chat.threadName));
        if(input) {
          const textValue = input.value;
          input.focus();
          input.value = '';
          this.$nextTick(() => {
            input.value = textValue;
            input.setSelectionRange(input.value.length, input.value.length);
          });
        }
      });
    },
    closeOffcanvas() {
      let offcanvasCloseBtn = document.getElementById('bz-offcanvas-close');
          offcanvasCloseBtn.click();
    },
    loadThread(threadId) {
      //if(this.currentChatId!==threadId) {
        this.closeOffcanvas();
        this.$emit(loadEvent, threadId);
      //}
    },
    removeSpaces(str) {
      return str.replace(/\s/g, '');
    },
    showIcons(event, toggle) {
      const button = event.target.closest('button'),
            icons = button.querySelectorAll('i.edit-icons');

      const displayStyle = toggle ? 'inline-block' : 'none';
      icons.forEach(icon => {
        icon.style.display = displayStyle;
      });
    },
    updateChatHistoryName(event, chat) {
      let input = document.getElementById(this.removeSpaces(chat.threadName)),
          _newValue = event.target.value;

      if(chat.threadName!==_newValue) {
        for(let i=0; i<this.localThreads.length; i++) {
          if( this.localThreads[i].threadId==chat.threadId ) {
            this.localThreads[i].threadName = _newValue;
            input.id = this.removeSpaces(_newValue);
          }
        }
        
        let payload = {'guid':chat.threadId, 'name':_newValue, 'created':chat.created};
        this.$emit(setEvent, payload);
      }

      this.chatEdit = false;
    },
  }  
}
</script>

<style scoped>
.chat-full-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: '250px';
}
.btn-outline-transparent {
  margin-bottom: 10px;
  color: #2e3d53;
  width: 100%;
}
.btn-outline-transparent-active {
  border: 1px solid rgb(224 224 224);
  border-radius: 15px;
  margin-bottom: 10px;
  color: #2e3d53;
  width: 100%;
}
.btn-outline-transparent:hover {
  color: #2e3d53;
  background: rgba(224, 224, 224, 0.4);
  border-radius: 10px;
}
.btn-outline-transparent-hover:hover {
  color: #2e3d53;
}
.bzChatHistoryField {
  color:#2e3d53;
  width:100%;
  border:none;
}
.bzChatHistoryField:focus {
  outline: none;
  border-color: #2e3d53;
}
li {
  list-style: none !important;
}
</style>

<style>
.vfm--fixed {
  z-index: 2021 !important;
}
.thread-category {
  font-size: 0.8em;
  font-weight: 600;
}
</style>