<template>

  <Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
    <base-modal :name="modalName" :title="getTitle()" v-bind="$attrs" :width="350" @close="close">
      <template #body>  
        <FormField name="name"          label="Name"            v-model="name"          :topLabel="true" />
        <FormField name="date"          label="Date"            v-model="date"          :topLabel="true" type="date" style="width:120px;" />
        <FormField name="category"      label="Category"        v-model="category"      :topLabel="true" as="select" :data="categories" />
        <FormField name="country_code"  label="Country (code)"  v-model="country_code"  :topLabel="true" />
      </template>
      <template #footer>
        <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
        <button type="submit" class="btn btn-secondary">Ok</button>
      </template>
    </base-modal>
  </Form>
  
  </template>
  
  <script>
  
  import BaseModal from '@/App/components/Modal/BaseModal';
  import FormField from '@/App/components/Form/FormField';
  import { Form } from "vee-validate";
  import _app from '@/App/App'
  
  export default {
    name: 'AddDateModal',
    props: {
      editRec: Object,
    },
    inheritAttrs: false,
    emits: ['ok'],
    components: {
      BaseModal,
      FormField,
      Form,
    }, 
    data() {
      return {
        modalName: 'AddDateModal',
        schema: {
          name: 'required',
          date: 'required',
          category: 'required'
        },

        //rec
        id: null,
        name: null,
        category: null,
        date: null,
        country_code: null,
        
        categories: [
          {id:1, name:'Awareness'},
          {id:2, name:'Holiday'},
          {id:3, name:'Sports'},
          {id:4, name:'Other'},
        ],
      };
    },
    mounted() {
  
      if (this.editRec) {
        this.id = this.editRec.id;
        this.name = this.editRec.name;
        this.date = this.editRec.date;
        this.category = this.categories.filter(obj => {
                          return obj.name.toLowerCase()==this.editRec.category.toLowerCase()
                        })[0].id;
        this.country_code = this.editRec.country_code || '';
      }
    },
    methods: {
      getTitle() {
        return this.editRec ? 'Edit date' : 'Add date';
      },
      onSubmit(values) {  
  
        let _data = this.editRec || {};
  
        if (!this.editRec) {
          _data = {
              id: null, 
              name: values.name,
              date: values.date,
              category: this.categories.filter(obj => {
                          return obj.id==values.category
                        })[0].name,
              country_code: values.country_code || ''
            };
        } else {
          _data.name = values.name;
          _data.date = values.date;
          _data.category = this.categories.filter(obj => {
                            return obj.id==values.category
                          })[0].name;
          _data.country_code = values.country_code || '';
        }
  
        this.$emit('ok', _data);
        this.$vfm.hide(this.modalName);
      },
      close() {
        this.$vfm.hide(this.modalName);
      }
    }
  }
  </script>
  
  <style scoped>
  </style>