<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Change password" v-bind="$attrs" :width="350" @close="close">
    <template #body>  
      <FormField name="oldPass" type="password" label="Old password" :topLabel="true" />
      <FormField name="newPass" type="password" label="New password" :topLabel="true" />
      <FormField name="newPass2" type="password" label="Confirm new password" :topLabel="true" />
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-secondary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'ChangePasswordModal',
  props: {
    editRec: Object,
  },
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  }, 
  data() {
    return {
      modalName: 'ChangePasswordModal',
      schema: {
        oldPass: 'required',
        newPass: 'required',
        newPass2: 'required'
      },
      id: null,
      oldPass: null,
      newPass: null,
      newPass2: null,
      state: this.$app.$data.getState('ChangePasswordModal', { id: null }),
    };
  },
  mounted() {
    if (this.editRec)
      this.id = this.editRec.id;
    else {
      _app.notify('Could not read user ID from the list. Please, try again.');
      this.$vfm.hide(this.modalName);
    }
  },
  methods: {
    onSubmit(values) {
      if(values.newPass!==values.newPass2) {
        _app.notify('New passwords do not match.');
        return false;
      } else {
        let loader = this.$loading.show({ container: this.$refs.form.$el }),
            params = {
              oldPass: values.oldPass,
              newPass: values.newPass
            };

        this.$app.$api.get('AppUser/ChangeUserPass', { params: params }).then(
          () => {
            loader.hide();
            this.$emit('ok', this.editRec);
            this.$vfm.hide(this.modalName);
          }, 
          (error) => {
            loader.hide();
            _app.handleError(error);
        });
      }
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>