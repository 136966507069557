<template>
  <vue-final-modal :name="modalName" v-slot="{ close }" v-bind="$attrs" classes="lab22-modal-container" content-class="lab22-modal-content" :z-index-base="1021" :click-to-close="false" :esc-to-close="true">
    <div class="modal-dialog shadow" role="document" :class="dialogClass" >
      <!--<div class="modal-content" :style="{ width: width + 'px', height: (height) ? height + 'px' : '', position: (pos) ? pos : '', left: (posX) ? (posX-width+800) + 'px' : '', top: (posY) ? (posY-height+280) + 'px' : '' }">-->
        <div class="modal-content" :style="{ width: width + 'px' }">
        <div class="modal-header">
          <h5 class="modal-title"><strong>{{ title }}</strong></h5>
          <span @click.prevent="this.$emit('close')" type="button" :class="this.$app.darkTheme ? 'btn-close btn-close-white' : 'btn-close'"></span>
        </div>
        <div class="modal-body" :class="bodyClass">
          <slot name="body">{{ msg }}</slot>
        </div>
        <div class="modal-footer" :class="footerClass">
          <slot name="footer" :close="close"></slot>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>

export default {
  name: 'BaseModal',
  inheritAttrs: false,
  emits: ['close'],
  props: {
    modalName: String,
    title: String,
    msg: String,
    dialogClass: String,
    bodyClass: String,
    footerClass: String,
    width: {
      type: Number,
      default: 300
    },
    /*height:Number,
    pos: String,
    posX: Number,
    posY: Number,*/
  },
  methods: {

  }
}
</script>

<style scoped>

  .modal-dialog {
    min-width: 300px;
  }

  .modal-footer {
    border-top: none 0px;
  }
</style>
