<template>

<Form @submit="onSubmit" ref="form" :validation-schema="schema" class="form-horizontal form-bordered">
  <base-modal :name="modalName" title="Add user" v-bind="$attrs" :width="350" @close="close">
    <template #body>  
      <FormField name="username" label="Email" v-model="username" :onchange="copyToEmail" :topLabel="true" />
      <FormField name="firstName" label="First name" v-model="firstName" :topLabel="true" />
      <FormField name="lastName" label="Last name" v-model="lastName" :topLabel="true" />
      <FormField name="email" label="Email (notifications)" v-model="email" :topLabel="true" />
      <FormField v-if="!this.$app.user.isCustomerUser()" name="customerId" label="Customer" :topLabel="true" as="select" :data="this.$app.$data.customers" />
      <FormField name="roleId" label="User role" :topLabel="true" as="select" :data="this.roleList" />
    </template>
    <template #footer>
      <button @click="close" type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
      <button type="submit" class="btn btn-secondary">Ok</button>
    </template>
  </base-modal>
</Form>

</template>

<script>

import BaseModal from '@/App/components/Modal/BaseModal';
import FormField from '@/App/components/Form/FormField';
import { Form } from "vee-validate";
import _app from '@/App/App'

export default {
  name: 'AddUserModal',
  inheritAttrs: false,
  emits: ['ok'],
  components: {
    BaseModal,
    FormField,
    Form
  }, 
  data() {
    return {
      modalName: 'AddUserModal',
      schema: {
        username: 'required|email',
        roleId: 'required',
        email: 'email',
      },
      id: null,
      username: null,
      firstName: null,
      lastName: null,
      email: null,
      roleId: null,
      state: this.$app.$data.getState('AddUserModal', { id: null }),
      roleList: []
    };
  },
  mounted() {
    this.$app.$data.getCustomers().then(data => {
      this.customers = data;
    });
    this.loadRoles();
  },
  methods: {
    loadRoles() {
      this.roleList = []

      let loader = this.$loading.show({ container: this.$refs.form.$el });

      _app.$api.get('AppRole/GetAppRoleList', { }).then(result => {
        
        this.roleList = result.data || [];
        
        loader.hide();

      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
      
    },
    copyToEmail() {
      if(this.email=='' || this.email==null)
        this.email = this.username;
    },
    onSubmit(values) {
      let loader = this.$loading.show({ container: this.$refs.form.$el }),
          _data = {
            id: null,
            accountId: -1,
            roleId: values.roleId,
            customerId: values.customerId,
            username: values.username,
            password: 'PENDING',
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            isAdmin: false,
            isLab: false,
            isDisabled: false,
            created: new Date(),
            createdBy: -1,
            updated: new Date(),
            updatedBy: -1,
            deleted: null,
            deletedBy: null
          };
      
      this.$app.$api.post('AppUser/SetAppUser', _data).then(result => {
        if( result.data && parseInt(result.data)>0 ) {
          loader.hide();
          _data.id = parseInt(result.data);
          this.$emit('ok', _data);
          this.$vfm.hide(this.modalName);
        } else {
          this.$app.notify('There was no error but User Id was not returned. Please, try again.');
          loader.hide();
        }
      }, (error) => {
        loader.hide();
        _app.handleError(error);
      });
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
</style>