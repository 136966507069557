<template>  
  <nav class="navbar navbar-light module-hd justify-content-start">    
    <div class="flex-grow-1 d-flex align-items-center">            
      <i v-if="showBackButton" @click="goBack()" class="fa-solid fa-circle-left back-icon"></i>
      <a class="navbar-brand" @click.prevent="$emit('titleClick')">
        <slot name="title">
          <i v-if="iconClass" :class="iconClass + ' module-icon'"></i>
          <div class="module-title" :class="{ 'module-title-without-subtitle': !subtitle }">
            <div class="module-title-text">{{title || 'Loading...'}}
              <div v-if="dashboardViewButtons" class="btn-group flex-wrap" style="position:absolute; display:inline-block; margin-left:50px; margin-top:13px;">
                <button type="button" @click="$emit('viewReport')" class="btn btn-outline-secondary" :style="'opacity:'+((currentView=='report') ? '1' : '0.5' )+';'"><i class="fa fa-list"></i></button>
                <button type="button" @click="$emit('viewDashboard')" class="btn btn-outline-secondary" :style="'opacity:'+((currentView=='dashboard') ? '1' : '0.5' )+';'"><i class="fa fa-line-chart"></i></button>
              </div>
            </div>
            <span v-if="subtitle" class="module-subtitle">{{subtitle}}</span>
          </div>
        </slot>
      </a>      
    </div>    
    <slot></slot>    
  </nav>
</template>

<script>
  
  export default {
    name: 'ModuleHeader',
    props: {
      title: String,
      subtitle: String,
      iconClass: String,
      showBackButton: Boolean,
      dashboardViewButtons: Boolean,
      currentView: String
    },
    emits:['titleClick','viewReport','viewDashboard'],
    data() {
      return {
        
      }
    },
    methods: {
      goBack() {
        this.$router.back()
      }
    }
  }
</script>

<style scoped>

.navbar.module-hd {
  height: 56px;
  padding-left: 0px;
  padding-right: 5px;
  margin-bottom: 15px;
}

.module-title {
  height: 46px;  
  font-size: 20px;
  line-height: 18px
}

.module-title-text {
  margin-top: -2px;
}

.module-icon {
  font-size: 46px;
  margin-right: 15px;
  margin-top: -8px;
  float:left;
  color:#2e3d53 !important;
}

.module-title-without-subtitle .module-title-text {
  line-height: 34px;
}

.module-subtitle {
  font-size: 13px;
  color: #2e3d53 !important;
  line-height: 13px !important;
  padding-left:1px;
}

.back-icon {
  font-size: 24px;
  margin-right: 15px;  
  margin-top: -12px;
  color:#2e3d53 !important;
  cursor: pointer;
}

</style>

<style>

.navbar.module-hd button {
  margin-top:-15px;
}

</style>