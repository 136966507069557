<template>

    <div ref="kpiContainer" :key="this.customerId">
    
      <div class="dash-group-items">
        <div v-if="this.placeholderKpiCard" class="dash-item card card-featured-left-bz card-featured-bz" :style="'width:'+dashItemWidth+'px'">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h5 class="card-title placeholder-glow" style="opacity:0.2 !important;">
                  <span class="placeholder col-12">Fetching data...</span>
                </h5>
                <p class="card-text placeholder-glow" style="opacity:0.2 !important;">
                  Calculating results...
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else v-for="item in items" :key="item.id" class="dash-item card card-featured-left-bz card-featured-bz" :style="'width:'+dashItemWidth+'px'">
          <div class="card-body">
            <div class="row" style="height:90px !important;">
              <div class="col-10">
                <div class="row" style="height:25px !important;">
                  <div class="col">
                    <div class="dash-item-name-wrap d-flex">
                      <div class="dash-item-name" :title="item.userDescr" style="cursor:pointer;">{{item.name}}</div>         
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="widget-summary widget-summary-sm">
                      <!--
                      <div class="widget-summary-col widget-summary-col-icon">
                        <div class="summary-icon bg-primary">
                          <i class="fas fa-life-ring"></i>
                        </div>
                      </div>
                      -->
                      <div class="widget-summary-col">
                        <div class="summary">
                          <h4 class="title" style="padding-left:10px; padding-top:5px;">{{ formatWidgetDate(item.from)+' through '+formatWidgetDate(item.to) }}</h4>
                          <div class="info" style="margin-top:5px !important;">
                            <strong style="padding-left:10px; margin-right:20px;" :class="'amount '+( (parseInt(item.targetValue)>parseInt(item.goalValue)) ? 'alertWidget' : 'successWidget' )">goal: {{ item.targetValue }}</strong>
                            <span style="color:#2e3d53; font-weight:bold;">currently: {{ item.goalValue }}</span>
                          </div>
                        </div>
                        <!--
                        <div class="summary-footer">
                          <a class="text-muted text-uppercase">(view all)</a>
                        </div>
                        -->
                      </div>
                      <!--<span class="dash-item-value">{{ item.goalValue+'/'+item.targetValue }}</span>-->
                    </div>
                  </div>
                </div>
              </div>
              <div @click="getCardInfo(item)" class="col-2" style="cursor:pointer; margin-top:10px;">
                <i class="fa fa-ellipsis-vertical" style="font-size:large;"></i>
              </div>
            </div>
            <div class="row" style="height:70px !important; padding: 0px 10px 10px 10px">
              <div class="col dash-item-chart">
                <apexchart v-if="item.series.length > 0"
                  type="area"
                  :ref="'chart'+item.id" 
                  height="80px"
                  :series=item.series
                  :options="itemChartOptions" 
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  
</template>
  
<script>
import _app from '@/App/App'

export default {
  name: 'KpisView',
  components: {
    
  },
  props: {
    customerId: Number,
    customerConfig: String
  },
  data: function() {
    return {
      //data
      fullWidth: null,
      mediumScreenWidth: 991,
      smallScreenWidth: 767.98,
      miniScreenWidth: 575.98,
      dashItemWidth: 260,
      loading: false,
      user: this.$store.state.auth.user,
      customerKpis: null,
      kpiList: null,
      placeholderKpiCard: true,
      items: [],
      config: (this.customerConfig && this.customerConfig!=='') ? JSON.parse(this.customerConfig) : null,
      itemChartOptions: {
        chart: {
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false
          },
          type: 'line',
          toolbar: { show: false },
        },
        stroke: {
          curve: 'smooth',
          width: 2, //[2, 2],
        },
        fill: {
          type: 'solid',
          opacity: [0.35, 1],
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'yyyy-MM-dd',
          },
        },
        yaxis: {
          title: {
            text: 'Values',
          },
        },
        tooltip: {
          //enabled: false,
          shared: true,
          intersect: false,
        },
        colors: ['#A0DCF6', '#e31b54'],
        legend: {
          show: false,
          //position: 'top',
        },
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.doLayout);

    if( this.customerId && this.customerConfig ) {
      if(this.config.pmakpis !== undefined) {
        if(this.config.pmakpis.kpis !== undefined) {
          if(this.config.pmakpis.kpis.length>0)
            this.customerKpis = this.config.pmakpis.kpis;
          else
            this.customerKpis = [];
        }
        else
          this.customerKpis = [];
      }
      else
        this.customerKpis = [];
    }
    else
      this.customerKpis = [];

    if(this.customerKpis.length > 0) {
      let progress = this.$progress.start();

      this.$app.$api.get('Customer/GetKpiList').then(
        result => {
          progress.finish();
          let res = result.data;
          if(res) {
            this.kpiList = res;
            this.renderKpiCards(res, this.customerKpis);
          }
          else
            _app.$helper.notifyInfo('Ups, list of available KPIs not available. Please, try again.');
        },
        error => {
          progress.finish();
          this.$app.handleError(error);          
        }
      );
    }

    setTimeout(() => {
      this.doLayout();
    }, 200);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.doLayout);
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    doLayout() {
      let width = window.innerWidth;
      const element = this.$refs.kpiContainer;
      const rect = element.getBoundingClientRect();
      const xPosition = rect.left;

      if(width>=1200)
        width = 260;
      else {
        //checking for sidebar full opened, collapsed or hidden
        if(xPosition>250) { //sidebar is fully opened
          if(width-xPosition > 540)  
            width = 260;
          else
            width = width-360;
        }
        else {
          if(xPosition>100) { //sidebar is open
            if(width-xPosition > 540)  
              width = 260;
            else
              width = width-160;
          }
          else
            width = width-50;
        }
      }

      this.dashItemWidth = width;
    },
    getCardInfo(kpi) {
      _app.$helper.notifyInfo(`We are working hard on extending Flamingo's assistant skills even further.`);
      //console.log('getCardInfo -> kpi:',kpi);
    },
    formatWidgetDate(dateStr) {
      const date = new Date(dateStr);
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear().toString().slice(-2);
      return `${day}-${month}-${year}`;
    },
    getKpiIsMetric(id, list) {
      let kpi = list.filter(obj => {
                  return parseInt(obj.id)==parseInt(id);
                })[0];
      return kpi.is_metric;
    },
    getKpiName(id, list) {
      let kpi = list.filter(obj => {
                  return parseInt(obj.id)==parseInt(id);
                })[0];
      return kpi.kpi_name;
    },
    renderKpiCards(kpiList, customerKpis) {
      //console.log('renderKpiCards - kpiList',kpiList);
      //console.log('renderKpiCards - customerKpis',customerKpis);

      for(let i=0; i<customerKpis.length; i++) {
        let rec = customerKpis[i],
            isMetric = (this.getKpiIsMetric(rec.kpiId, kpiList)) ? 1 : 0,
            kpiName = this.getKpiName(rec.kpiId, kpiList);

        let progress = this.$progress.start(),
            _params = {"customerId":parseInt(this.customerId), "kpiId":parseInt(rec.kpiId), "isMetric":parseInt(isMetric), "dtFrom":rec.periodFrom.toString(), "dtTo":rec.periodTo.toString(), "kpiFunc":rec.kpiFunction};

        this.$app.$api.get('Customer/GetKpiGoalValues', { params: _params }).then(
          result => {
            progress.finish();
            let res = result.data;
            
            let series = [
              {
                name: 'KPI value',
                type: 'area',
                data: res.map(item => [new Date(item.dt).getTime(), ( (isMetric==1) ? parseInt(item.val) : parseInt(item.val_non_metric) )]),
              },
              {
                name: 'Goal',
                type: 'line',
                data: res.map(item => [new Date(item.dt).getTime(), parseInt(rec.kpiValue)]),
              },
            ];

            let series2 = [
              {
                //name: 'KPI non-metric value',
                //type: 'line',
                data: res.map(item => [new Date(item.dt).getTime(), parseInt(item.val_non_metric)]),
              }
            ];
            
            let goalValue;
            if(res && res.length>0) {
              if(isMetric==1)
                goalValue = series[0].data[(series[0].data.length-1)][1]; //for example, 2670 new users is the current value for some kpi
              else
                goalValue = series2[0].data[(series2[0].data.length-1)][1]; //for example, 60 distinct countries from user visitors' origin
            }

            this.items.push({
              'id': rec.kpiId,
              'name': kpiName,
              'isMetric': isMetric,
              'userDescr': rec.kpiUserDescr,
              'targetValue': rec.kpiValue,
              'from': rec.periodFrom,
              'to': rec.periodTo,
              'kpiFunc': rec.kpiFunction,
              'series': series,
              'goalValue': goalValue,
            });

            this.items.sort((a, b) => a.id - b.id);

            // TODO does not work?! i get different line width all the time?!
            setTimeout(() => {
              //this.itemChartOptions.stroke.width = 2;
              const chartRef = this.$refs['chart' + rec.kpiId][0];
              if (chartRef) {
                chartRef.updateOptions({
                  stroke: {
                    width: [2,2],
                  },
                });
              }
            }, 200);

            this.placeholderKpiCard = false;
          },
          error => {
            progress.finish();
            this.$app.handleError(error);
          }
        );
      }

      if(this.placeholderKpiCard)
        this.placeholderKpiCard = false;
    },
  },
};
</script>

<style scoped>
.alertWidget {
  color: #e31b54 !important;
}
.successWidget {
  color: #079455 !important;
}
.dash-group-items {
  display: inline-block;
}
.dash-item {
  /*width: 260px;*/
  height: 200px;
  float:left;
  margin-right: 30px;
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #2e3d53;
  /*border: 1px solid #777777;*/
  /*border-radius: 3px 3px 3px 3px;*/
  box-shadow: 0px 6px 8px 2px rgba(0,0,0,0.1);
}
.dash-item-name-wrap {
  padding: 10px 10px;
  padding-right: 0px;
}
.dash-item-name {
  color: #2e3d53;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.dash-item-value {
  font-size: 20px;
  padding: 10px 10px 0px 10px;
}
.dash-item-value.alert {
  color: #e31b54;
}
.card-featured-bz {
  border-color: #2e3d53;
}
.card-featured-left-bz {
  /*border-left: 3px solid rgba(227,27,84,0.5);*/
}
.card + .card {
  margin-top: 0px !important;
}
</style>