<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding">
        
      <ModuleHeader title="Who are we and What do we do?" style="font-weight:700 !important;" />

      <p class="p-text mt-4">
        A small team with big ideas.
      </p>

      <p class="p-text">
        This app is a long-time-coming product of our 9-year experience as a creative collective functioning as a <u><strong><a href="https://www.thesocialformula.net/" target="_blank">digital marketing agency</a></strong></u>.
      </p>

      <p class="p-text">
        During that time we worked with over 100 companies/individuals, in branding, social media campaigns, on strategies and plans, reporting, analyzing the market, and communicating all the results to our clients.
      </p>

      <p class="p-text">
        Each time we took on a new project - we tried our best to optimize the workflow, make everything easy for our clients, and offer the best possible service to every single one of them.
      </p>

      <p class="p-text">
        In those years of experience in the industry, we recognized the potential for a new level of optimization and realized that we want to work towards achieving it.<br />
        This was the moment we started working on <i>The Social Formula Software</i> - the evolution of digital marketing reporting powered by AI.
      </p>

      <p class="p-text">
        <u><strong><a href="https://www.thesocialformula.net/" target="_blank">The Social Formula</a></strong></u> agency is based in Belgrade, Serbia, and a proud member of the Science Technology Park.
      </p>

      <p class="p-text">
        <strong>Contact Us: <a href="mailto:hello@thesocialformula.net">hello@thesocialformula.net</a></strong>
      </p>

    </div>
</template>
  
<script>
import ModuleHeader from '@/App/components/Common/ModuleHeader'

export default {
  name: 'AboutView',
  components: {
    ModuleHeader,
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
.p-text {
  color: #2e3d53;
  font-size: 16px;
}
</style>