<template>
  <div class="main-template-div lab22-mv-padding">

    <!--<ModuleHeader title="Roles" iconClass="fa-solid fa-user-lock">-->
    <ModuleHeader title="Roles" iconClass="inf-module-header-roles inf-module-header-img">
      <BaseButton @click="showAddRoleModal()" text="Add role" />
    </ModuleHeader>

    <FilterBar v-slot="{state}" :filterState="getFilterState()" @reload="load(true, true)" showReload showReset :loading="loading">
      <BaseField v-model="state.name" name="name" placeholder="Role name" style="width:200px" />
    </FilterBar>

    <BaseGrid :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" class="table-no-more" />
  </div>
</template>

<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import FilterBar from '@/App/components/Common/FilterBar'
import BaseField from '@/App/components/Form/BaseField'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseButton from '@/App/components/Common/BaseButton'
import AddRoleModal from './AddRoleModal'

export default {
  name: 'RoleList',
  components: {
    ModuleHeader,
    FilterBar,
    BaseField,
    BaseGrid,
    BaseButton
  },
  data() {
    return {
      gridData: {
        columns: [
          {
            title: 'Name',
            dataIndex: 'name'
          },
          {
            title: 'Role functions',
            dataIndex: 'functions'
          },
          {
            title: 'Active users',
            dataIndex: 'activeUsers',
            align: 'center'
          },
          // actions
          {
            width:140,
            align:'right',
            actions: [
              {
                text: 'Edit',
                handler: this.editRole
              },          
              {
                text: 'Remove',
                handler: this.removeRole
              }
            ]
          }
        ],
        rows: []
      },
      data: [],
      loading: false
    };
  },
  mounted() {
    /*setTimeout(() => {
      const hostElement = document.querySelector('flowise-chatbot');
      if (hostElement && hostElement.shadowRoot) {
        const shadowRoot = hostElement.shadowRoot;
        const button = shadowRoot.querySelector('button[part="button"]');
        if (button)
          button.style.display = 'none';
      }
    }, 200);*/

    this.load();
  },
  methods: {
    getFilterState() {
      return this.$app.$data.getState('RoleListFilter');
    },    
    load() {
      this.loading = true;

      this.$app.$data.getRoles(true).then(data => {
        this.data = data;
        this.updateGridView();
        this.loading = false;
      });
    },
    editRole(rec) {
      this.$vfm.show({
        component: AddRoleModal,
        bind: {
          'editRec': rec
        },        
        on: {
          ok: (data) => {
            //console.log(data);
            this.load();
          }
        }
      });
    },
    showAddRoleModal() {
      this.$vfm.show({
        component: AddRoleModal,
        on: {
          ok: (data) => {
            //console.log(data);
            this.load();
          }
        }
      });
    },
    removeRole(rec) {
      _app.$modal.confirm('Remove role', `Are you sure you want to remove "${rec.name}" role?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1);

        this.loading = true;

        this.$app.$api.get('AppRole/DelAppRole', { params: { id: rec.id } }).then(
          () => {
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.load();
          }
        );  
      });
    },
    updateGridView() {

      const filter = this.getFilterState();

      this.gridData.rows = this.data.filter((rec) => { 

        if (filter.name && rec.name.toLowerCase().indexOf(filter.name.toLowerCase()) == -1) return false;

        return true;
      });
    }
  }
}
</script>
