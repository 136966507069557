<template>
  <base-modal :name="modalName" :title="title" @close="cancel" footerClass="justify-content-center" v-bind="$attrs">
    <template #body>{{ msg }}</template>
    <template #footer>
      <slot name="footer">
        <button @click="cancel()" type="button" class="btn btn-outline-secondary" data-dismiss="modal">No</button>
        <button @click="confirm()" type="button" class="btn btn-secondary">Yes</button>      
      </slot>
  </template>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'
export default {
  name: 'ConfirmModal',
  inheritAttrs: false,
  emits: ['callback'],
  props: {
    modalName: {
      type: String,
      default: 'ConfirmModal'
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    msg: String    
  },
  components: {
    BaseModal
  },  
  methods: {
    cancel () {
      this.$emit('callback', false);
      this.close();
    },
    confirm() {
      this.$emit('callback', true);
      this.close();
    },
    close() {
      this.$vfm.hide(this.modalName);
    }
  }
}
</script>

<style scoped>
  button {
    min-width: 100px;
  }
</style>
