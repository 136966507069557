<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">
        
      <ModuleHeader title="Competitors & Research" :subtitle="this.$store.state.Customer.name" :showBackButton="true" />

      <div class="row ps-4">
        <div class="col-12">
          
          <div v-if="cusId">
            
            <p>
              We offer to track up to 5 of your main competitors, at the moment. If you need to track more, drop us a note and let's discuss a custom subscription package upgrade.
            </p>

            <ModuleHeader title=" ">
              <button @click="addRec" :disabled="allowMore" class="btn btn-outline-secondary">Add link</button>
              <button @click="saveScraping" class="btn btn-primary ms-2">Save changes</button>
            </ModuleHeader>
            <BaseGrid v-slot="{row, columns}" :columns="gridData.columns" :rows="gridData.rows" :enableVScroll="true" :hideHeaderCheckbox="true" class="table-no-more">
              <td>
                <input type="checkbox" :disabled="true" :checked="isAdvancedAnalysis(row)" class="form-check-input big-checkbox" />
              </td>
              <BaseGridCell :col="columns[1]" :row="row" />
              <BaseGridCell :col="columns[2]" :row="row" />
              <BaseGridCell :col="columns[3]" :row="row" />
            </BaseGrid>

            <!--
            <div class="row">
              <div class="col-sm-6 col-md-8">
                <label for="url2">Competitor 1:</label>
                <BaseField v-model="url1" name="url1" placeholder="Main website address" />
              </div>
              <div class="col-sm-6 col-md-4 mt-2" style="align-self: flex-end;">
                <BaseCheckbox :checked="url1advanced" label="advanced analysis?*" :title="advancedAnalysisTooltip" :titleLabel="advancedAnalysisTooltip" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" :data-bs-original-title="advancedAnalysisTooltip" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6 col-md-8">
                <label for="url2">Competitor 2:</label>
                <BaseField v-model="url2" name="url2" placeholder="Main website address" />
              </div>
              <div class="col-sm-6 col-md-4 mt-2" style="align-self: flex-end;">
                <BaseCheckbox :checked="url2advanced" label="advanced analysis?*" :title="advancedAnalysisTooltip" :titleLabel="advancedAnalysisTooltip" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" :data-bs-original-title="advancedAnalysisTooltip" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6 col-md-8">
                <label for="url2">Competitor 3:</label>
                <BaseField v-model="url3" name="url3" placeholder="Main website address" />
              </div>
              <div class="col-sm-6 col-md-4 mt-2" style="align-self: flex-end;">
                <BaseCheckbox :checked="url3advanced" label="advanced analysis?*" :title="advancedAnalysisTooltip" :titleLabel="advancedAnalysisTooltip" data-bs-toggle="tooltip" data-bs-animation="false" data-bs-placement="top" :data-bs-original-title="advancedAnalysisTooltip" />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <button @click="saveSettings" class="btn btn-primary mt-2">Save changes</button>
              </div>
            </div>
            -->
          </div>

          <div v-else class="text-center" style="margin-top:96px;">
            <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
            <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;">
              Select a client in order to access Competitors & Research functionality.
            </p>
          </div>

        </div>
      </div>

    </div>
  
</template>
  
<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'
import BaseGrid from '@/App/components/Grid/BaseGrid'
import BaseGridCell from '@/App/components/Grid/BaseGridCell'
import AddScrapingModal from './AddScrapingModal.vue';
//import BaseField from '@/App/components/Form/BaseField'
//import BaseCheckbox from '@/App/components/Form/BaseCheckbox'

export default {
  name: 'CompetitorsView',
  /*props: {
    customerId: {
      type: String,
      required: true
    }
  },*/
  components: {
    ModuleHeader,
    BaseGrid,
    BaseGridCell,
    //BaseField,
    //BaseCheckbox,
  },
  data: function() {
    return {
      customerConfig: null,
      competitors: [],
      //cusId: this.customerId,
      cusId: null,
      user: this.$store.state.auth.user,

      gridData: {
        columns: [
          {
            title: 'Advanced analysis?',
            type: 'checkbox',
            /*changeHandler: (e) => {              
              return false;
            },*/
          },          
          {
            title: 'Competitor',
            dataIndex: 'competitor',
          },
          {
            title: 'Url',
            dataIndex: 'url'
          },
          // actions
          {
            width:90,
            align:'right',
            actions: [
              {
                text: 'Edit',
                handler: this.editRec
              },
              {
                text: 'Remove',
                handler: this.removeRec
              }
            ]
          }
        ],
        rows: []
      },

      /*
      url1: null,
      url2: null,
      url3: null,
      url1advanced: null,
      url2advanced: null,
      url3advanced: null,
      advancedAnalysisTooltip: `If you check this option our Flamings agent will be on steroids! It will use advanced technics such as sentiment analysis, vision based scraping ect. It will also do a deep search on the client's name, not focusing exclusively on the base web address.`,
      */
    }
  },
  mounted() {
    //this.cusId = window.history.state.customerId;
    this.cusId = this.$store.state.Customer.id;

    if(this.cusId)
      this.initData(this.cusId);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    },
    allowMore() {
      return ( this.gridData.rows.length>=5 ) ? true : false;
    },
  },
  watch: {
    customerId(newCustomerId) {
      this.cusId = this.$store.state.Customer.id;
      this.initData(this.cusId);
    },
  },
  methods: {
    clearData() {
      this.customerConfig = null;
      this.competitors = [];
      /*
      this.url1 = null;
      this.url2 = null;
      this.url3 = null;
      this.url1advanced = null;
      this.url2advanced = null;
      this.url3advanced = null;
      */
    },
    initData(id) {
      if(id) {
        this.customerConfig = this.getCustomerConfig(id);

        if (this.customerConfig.scraping !== undefined) { //this should not happen!
          this.gridData.rows = this.customerConfig.scraping;

          /*
          const scraping = this.customerConfig.scraping;
          this.url1 = (scraping[0] !== undefined) ? (scraping[0].url || null) : null;
          this.url2 = (scraping[1] !== undefined) ? (scraping[1].url || null) : null;
          this.url3 = (scraping[2] !== undefined) ? (scraping[2].url || null) : null;
          this.url1advanced = (scraping[0] !== undefined) ? (scraping[0].advancedAnalysis || false) : false;
          this.url2advanced = (scraping[1] !== undefined) ? (scraping[1].advancedAnalysis || false) : false;
          this.url3advanced = (scraping[2] !== undefined) ? (scraping[2].advancedAnalysis || false) : false;
          */
        }
        else
          this.clearData();
      }
      else
        this.clearData();
    },
    getCustomerConfig(id) {
      return JSON.parse(
                this.$app.$data.customers.filter(obj => {
                  return obj.id==id;
                })[0].config
              ) || null;
    },
    isAdvancedAnalysis(row) {
      return row.advancedAnalysis;
    },
    addRec() {
      this.$vfm.show({
        component: AddScrapingModal,
        on: {
          ok: (data) => {
            this.updateScrapingGrid(data, true);
          }
        }
      });
    },
    editRec(rec) {
      this.$vfm.show({
        component: AddScrapingModal,
        bind: {
          'editRec': rec
        },
        on: {
          ok: (data) => {
            this.updateScrapingGrid(data, false);
          }
        }
      });
    },
    removeRec(rec) {
      _app.$modal.confirm('Remove link', `Are you sure you want to remove "${rec.name}" from your list?`, (confirmed) => {
        
        if (!confirmed) 
            return;
      
        const index = this.gridData.rows.indexOf(rec);
        if (index !== -1)
          this.gridData.rows.splice(index, 1);
      });
    },
    saveScraping() {
      let _this = this;

      if (this.customerConfig && this.customerConfig.scraping) {
        this.customerConfig.scraping = this.gridData.rows;
        pushToDb(this.cusId, this.customerConfig, _this);
      }
      else
        _app.$helper.notifyError("'scraping' property is missing in customerConfig");

      function pushToDb(customerId, json, scope) {
        _app.$api.post('Customer/SetCustomerConfig?id='+customerId, json).then(result => {
          if( result.data && parseInt(result.data)>0 ) {
            _app.$helper.notifyInfo('Scraping links successfully updated.');

            _app.$data.getCustomers(true).then(data => {
              scope.customers = data;
              scope.customerConfig = scope.getCustomerConfig(customerId);
              
              let cusName = scope.$app.$helper.getCustomerName(customerId,data);
              scope.$store.dispatch('setAppCustomer', {id:customerId, name:cusName, config:JSON.stringify(json) });
            });
          }
        }, (error) => {
          _app.handleError(error);
        });
      }
    },
    /*saveSettings() {
      _app.$helper.notifyInfo('We are currently working on this amazing feature. Please, check it out in days to come...');
    },*/
    updateScrapingGrid(rec, isNew) {
      if(isNew) {
        this.gridData.rows.unshift({competitor:rec.competitor,url:rec.url,advancedAnalysis:rec.advancedAnalysis,sentimentAnalysis:rec.sentimentAnalysis});
      }
      else {
        const index = this.gridData.rows.findIndex(item => item.url === rec.url);
        if (index !== -1)
          this.gridData.rows[index] = rec;
      }
    },
  },
};
</script>

<style scoped>
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
</style>