<template>

    <div ref="bodyEl" class="main-template-div lab22-mv-padding" :key="this.$store.state.Customer.id">
        
      <ModuleHeader title="Usage" :subtitle="this.$store.state.Customer.name" :showBackButton="true" />

      <div class="row ps-4">
        <div class="col-12">
          
          <div v-if="cusId">
            <h4>Lifetime:</h4>
            Tokens:&nbsp;<strong>{{ formatNumber(usage.quantity) }}</strong><br />
            Cost:&nbsp;<strong>{{ usage.cost }}€</strong><br /><br />
            <h4>Year-to-date:</h4>
            Tokens:&nbsp;<strong>{{ formatNumber(usage.quantity_ytd) }}</strong><br />
            Cost:&nbsp;<strong>{{ usage.cost_ytd }}€</strong><br /><br />
            <h4>Month-to-date:</h4>
            Tokens:&nbsp;<strong>{{ formatNumber(usage.quantity_month) }}</strong><br />
            Cost:&nbsp;<strong>{{ usage.cost_month }}€</strong>
          </div>
          
          <div v-else class="text-center" style="margin-top:96px;">
            <img :src="require('../../assets/img/flamingo-head.png')" title="Flamingo avatar" style="display:block; margin:0 auto;" />
            <p class="bird-comment" style="cursor:default; font-size:16px; width:345px; margin: 0 auto;">
              Select a client in order to access Usage functionality.
            </p>
          </div>

        </div>
      </div>

    </div>
  
</template>
  
<script>
import _app from '@/App/App'
import ModuleHeader from '@/App/components/Common/ModuleHeader'

export default {
  name: 'UsageView',
  /*props: {
    customerId: {
      type: String,
      required: true
    }
  },*/
  components: {
    ModuleHeader,
  },
  data: function() {
    return {
      //data
      //customerConfig: null,
      //cusId: this.customerId,
      cusId: null,
      subscription: ( ( this.$app.user.isCustomerUser() ) ? 'Individual' : 'Company' ) + ' (valid until 31-Dec-2024)',
      usage: [],
      user: this.$store.state.auth.user,
    }
  },
  mounted() {
    //this.cusId = window.history.state.customerId;
    this.cusId = this.$store.state.Customer.id;

    if(this.cusId)
      this.getAiUsage(this.cusId);
  },
  computed: {
    customerId() {
      return this.$store.state.Customer.id;
    }
  },
  watch: {
    customerId(newCustomerId) {
      this.cusId = this.$store.state.Customer.id;
      this.getAiUsage(this.cusId);
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat('de-DE').format(number);
    },
    getAiUsage(customerId) {
      let _this = this,
          progress = _this.$progress.start();
      const _params = { id:customerId };
        
        _this.$app.$api.get('Customer/GetCustomerAiUsage', { params: _params }).then(
        result => {
          progress.finish();
          let res = result.data[0];
          if(res) {
            _this.usage.cost = res.cost;
            _this.usage.cost_month = res.cost_month;
            _this.usage.cost_ytd = res.cost_ytd;
            _this.usage.quantity = res.quantity;
            _this.usage.quantity_month = res.quantity_month;
            _this.usage.quantity_ytd  = res.quantity_ytd ;
          }
          else
            _app.$helper.notifyInfo('Ups, usage information not available. Please, try again.');
        },
        error => {
          progress.finish();
          _this.$app.handleError(error);          
        }
      );
    }
  },
};
</script>

<style scoped>
h4 {
  text-decoration: underline;
}
.bird-comment {
  cursor: pointer;
  background-color: #A0DCF6;
  color: #2e3d53;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 10px 20px;
  line-height: 1.4;
  font-size: 14px;
}
</style>