<template>

    <base-modal :name="modalName" :title="header" v-bind="$attrs" :width="350" @close="close">
        <template #body>  
            {{ body }}
        </template>
        <template #footer>
            <button @click="close" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </template>
    </base-modal>

</template>

<script>
import _app from '@/App/App'
import BaseModal from '@/App/components/Modal/BaseModal'

export default {
    name: 'TooltipModal',
    inheritAttrs: false,
    props: {
        kpiLabel: String,
        kpiDescr: String,
    },
    components: {
        BaseModal,
    }, 
    data() {
    return {
        modalName: 'TooltipModal',
        header: this.kpiLabel,
        body: this.kpiDescr,
    };
    },
    mounted() {
        
    },
    methods: {
        close() {
            this.$vfm.hide(this.modalName);
        }
    }
}
</script>

<style scoped>

</style>